import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { FormattedMessage } from 'react-intl';

export default class SideBar extends Component{


    render(){

        return(
            <React.Fragment>
                <p className="d-none d-md-block">
                    <NavLink to="/profile" activeClassName='text-warning'><FormattedMessage id="profile.profile" defaultMessage="Profile" /></NavLink>
                </p>
                <p className="d-md-none">
                    <NavLink to="/profile/details" activeClassName='text-warning'><FormattedMessage id="profile.profile-details" defaultMessage="Profile Details" /></NavLink>
                </p>
                <p>
                    <NavLink to="/change-password" activeClassName='text-warning'><FormattedMessage id="profile.change-password" defaultMessage="Change Password" /></NavLink>
                </p>   
                <p>
                    <NavLink to="/addresses" activeClassName='text-warning'><FormattedMessage id="profile.saved-addresses" defaultMessage="Saved Addresses" /></NavLink>
                </p>                                                                        
                <p>
                    <NavLink to="/devices" activeClassName='text-warning'><FormattedMessage id="profile.logged-in-devices" defaultMessage="Logged in Devices" /></NavLink>
                </p>
                <p>
                    <NavLink to="/ratings" activeClassName='text-warning'><FormattedMessage id="profile.my-ratings" defaultMessage="My Ratings" /></NavLink>
                </p>
               
            </React.Fragment>
        )
    }

}