import React, { Component } from "react";
import { Link } from "react-router-dom";
import clientX from "../../../utils/clientX";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import vectorImg from "../../../assets/shopping-sites-bill-upload-vector.svg";
import InlineLoader from "../../../components/InlineLoader";
import { FormattedMessage, injectIntl } from "react-intl";

class BillUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shoppingSites: [],
      shoppingSite: -1,
      amount: null,
      product_name: null,
      order_date: null,
      bill: null,
      file_extension: null,
      file_type: null,
      isUploading: false
    };
  }

  componentDidMount() {
    clientX.get(`v1/shopping-sites/bills/available-sites`).then(response => {
      let data = response.data.data;

      this.setState(
        {
          shoppingSites: data
        },
        () => {
          const siteCode = this.props.match.params.id;
          if (siteCode) {
            this.setState({ shoppingSite: siteCode });
          }
        }
      );
    });
  }

  onSelectBill = event => {
    this.setState({
      bill: event.target.files[0]
    });
  };

  onUpload = () => {
    let amount = this.state.amount;
    let productName = this.state.product_name;
    let orderDate = this.state.order_date;

    let file = this.state.bill;
    let file_name = file.name;
    let extension = file.name.split(".").pop();
    let file_type = file.type;
    if (
      extension.toLowerCase() !== "jpg" &&
      extension.toLowerCase() !== "png" &&
      extension.toLowerCase() !== "jpeg" &&
      "png" &&
      extension.toLowerCase() !== "pdf"
    ) {
      toast.error("Only PNG, JPG, JPEG and PDF are supported", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return;
    }

    clientX
      .request({
        url: `/shopping-sites/bills/pre-signed-url`,
        method: "post",
        data: {
          file_name: file_name,
          file_extension: extension,
          mime_type: file_type
        }
      })
      .then(response => {
        let orignalFileName = response.data.file_name;
        let key = response.data.key;

        axios.put(response.data.url, file).then(response => {
          clientX
            .request({
              url: `/shopping-sites/bills/${this.state.shoppingSite}`,
              method: "post",
              data: {
                total_order_amount: amount,
                product_name: productName,
                order_date: orderDate,
                file: key,
                original_file_name: orignalFileName
              }
            })
            .then(response => {
              toast.success("You bill was uploaded successfully.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
              });

              this.setState({
                shoppingSite: -1,
                amount: null,
                product_name: null,
                order_date: null,
                bill: null,
                isUploading: false
              });
            })
            .catch(error => {
              toast.error(error.response.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
              });

              this.setState({
                isUploading: false
              });
            });
        });
      });
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
          <div className="col-12">
            <div className="row align-items-center">
              <div className="col-12">
                <small>
                  <Link to="/">
                    <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    />
                  </Link>{" "}
                  /{" "}
                  <strong>
                    <FormattedMessage
                      id="shopping-sites.upload-bill-for-shopping-sites"
                      defaultMessage="Upload Bill for Shopping Sites"
                    />
                  </strong>
                </small>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-3 mb-md-4">
          <div className="col-12">
            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <div className="form-group">
                      <label>
                        <FormattedMessage
                          id="shopping-sites.choose-shopping-site"
                          defaultMessage="Shopping Site"
                        />
                      </label>
                      <select
                        className="form-control"
                        value={this.state.shoppingSite}
                        onChange={event => {
                          this.setState({ shoppingSite: event.target.value });
                        }}
                      >
                        <option value="-1">
                          {this.props.intl.formatMessage({
                            id: "general.choose"
                          })}
                        </option>
                        {this.state.shoppingSites.map((item, index) => {
                          return <option key={index} value={item.id}>{item.name}</option>;
                        })}
                      </select>
                    </div>
                    <div className="form-group">
                      <label>
                        {" "}
                        <FormattedMessage
                          id="general.amount"
                          defaultMessage="Amount"
                        />
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        value={
                          null == this.state.amount ? "" : this.state.amount
                        }
                        onChange={event => {
                          this.setState({ amount: event.target.value });
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        <FormattedMessage
                          id="shopping-sites.product-name"
                          defaultMessage="Product Name"
                        />
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={
                          null == this.state.product_name
                            ? ""
                            : this.state.product_name
                        }
                        onChange={event => {
                          this.setState({ product_name: event.target.value });
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        <FormattedMessage
                          id="shopping-sites.order-date"
                          defaultMessage="Order Date"
                        />
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        value={
                          null == this.state.order_date
                            ? ""
                            : this.state.order_date
                        }
                        onChange={event => {
                          this.setState({ order_date: event.target.value });
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        <FormattedMessage
                          id="shopping-sites.bill-image"
                          defaultMessage="Bill Image"
                        />
                      </label>
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          onChange={this.onSelectBill}
                          accept=".png, .jpg, .jpeg, application/pdf"
                        />
                        <label className="custom-file-label">
                          <FormattedMessage
                            id="shopping-sites.upload-bill-image"
                            defaultMessage="Upload the Bill Image"
                          />
                        </label>
                      </div>
                    </div>
                    {this.state.isUploading ? (
                      <div className="text-center">
                        <InlineLoader />
                      </div>
                    ) : (
                      <button
                        className="btn btn-primary btn-block mt-3"
                        disabled={
                          null == this.state.shoppingSite ||
                          null == this.state.bill
                        }
                        onClick={this.onUpload}
                      >
                        <FormattedMessage
                          id="general.upload"
                          defaultMessage="Upload"
                        />
                      </button>
                    )}
                    <ToastContainer
                      position="top-center"
                      autoClose={5000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnVisibilityChange
                      draggable
                      pauseOnHover
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 text-center">
                <img
                  className="mt-3 mt-md-0 shopping-sites-bill-upload-vector"
                  src={vectorImg}
                  alt="upload shopping sites bill"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(BillUpload);
