import React, { Component } from 'react';
import '../styles/App.scss';
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
import NavBar from '../components/Navbar';
import Home from './home/pages/Home';
import ShoppingSitesList from './shopping_sites/pages/ShoppingSitesList';
import ShoppingSiteDetail from './shopping_sites/pages/ShoppingSiteDetail';
import CategoryList from './partners/pages/CategoryList';
import NearByPartner from './partners/pages/NearByPartners';
import NearbyPartnerDetail from './partners/pages/PartnerDetail';
import VouchersList from './vouchers/pages/VouchersList';
import VoucherDetail from './vouchers/pages/VoucherDetail';
import BillPayForm from './bill_pay/pages/BillPayForm';
import OffersList from './partners/pages/OffersList';
import Footer from '../components/Footer';
import OfferDetail from './partners/pages/OfferDetail';
import SearchResults from './search/pages/SearchResults';
import Profile from './profile/pages/Profile';
import ProfileDetails from './profile/pages/ProfileDetails';
import Orders from './orders/pages/Orders';
import BookMarks from './bookmarks/pages/Bookmarks';
import Winners from './winners/pages/Winners';
import Wallet from './wallet/pages/Wallet';
import Coupon from './coupons/pages/Coupon';
import Notifications from './notifications/pages/Notifications';
import Referral from './referral/pages/Referral';
import Rewards from './rewards/pages/Rewards';
import VoucherCart from './vouchers/pages/Cart';
import VoucherOrder from './vouchers/pages/OrderDetail';
import BillPayOrder from './bill_pay/pages/OrderDetail';
import PartnerBillUpload from './partners/pages/BillUpload';
import SSBillUpload from './shopping_sites/pages/BillUpload';
import Address from './profile/pages/Address';
import ChangePassword from './profile/pages/changePassword';
import Devices from './profile/pages/Devices';
import Ratings from './profile/pages/Ratings';
import WrpDetails from './wrp_details/pages/WrpDetails';
import WRPSummary from './coupons/pages/WRPSummary';
import VerificationDetails from './profile/pages/VerificationDetails';
import ScrollToTop from '../components/ScrollToTop';
import NotFound from '../components/NotFound';
import ShoppingSiteOrder from './shopping_sites/pages/OrderDetail';
import PartnerOrder from './partners/pages/OrderDetail';
import RewardOfferDetail from '../modules/rewards/pages/offer-details';

class App extends Component {

  render() {
    
    return (
      <Router>
        <ScrollToTop>
        <NavBar/>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/shopping-sites" component={ShoppingSitesList} />
            <Route exact path="/shopping-sites/:id" component={ShoppingSiteDetail} />
            <Route exact path="/shopping-sites/orders/:id" component={ShoppingSiteOrder} /> 
            <Route exact path="/nearby-partners/categories" component={CategoryList} />
            <Route exact path="/partner-profile/:id" component={NearbyPartnerDetail} />
            <Route exact path="/nearby-partners" component={NearByPartner} />
            <Route exact path="/nearby-partners/orders/:id" component={PartnerOrder} />
            <Route exact path="/upload-bill/nearby-partner/:id" component={PartnerBillUpload} />
            <Route exact path="/upload-bill/nearby-partner" component={PartnerBillUpload} />
            <Route exact path="/upload-bill/shopping-site/:id" component={SSBillUpload} />
            <Route exact path="/upload-bill/shopping-site" component={SSBillUpload} />
            <Route exact path="/vouchers" component={VouchersList} />
            <Route exact path="/vouchers/cart" component={VoucherCart} />
            <Route exact path="/vouchers/orders/:id" component={VoucherOrder} /> 
            <Route exact path="/voucher/:id" component={VoucherDetail} />
            <Route exact path="/bill-pay/orders/:id" component={BillPayOrder} /> 
            <Route exact path="/bill-pay/:id" component={BillPayForm} />
            <Route exact path="/nearby-offers" component={OffersList} />
            <Route exact path="/offer/:id" component={OfferDetail} />
            <Route exact path="/search" component={SearchResults} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/profile/details" component={ProfileDetails} />
            <Route exact path="/addresses"  component={Address} />
            <Route exact path="/change-password"  component={ChangePassword} />
            <Route exact path="/devices" component={Devices} />
            <Route exact path="/ratings" component={Ratings} />
            <Route exact path="/verification-details" component={VerificationDetails} />
            <Route exact path="/orders" component={Orders} />
            <Route exact path="/bookmarks" component={BookMarks} />
            <Route exact path="/winners" component={Winners} />
            <Route exact path="/wallet" component={Wallet} />
            <Route exact path="/coupons" component={Coupon} />
            <Route exact path="/wrp-summary" component={WRPSummary} />
            <Route exact path="/wrp-details" component={WrpDetails} />
            <Route exact path="/notifications" component={Notifications} />
            <Route exact path="/refer-and-earn" component={Referral} />
            <Route exact path="/rewards" component={Rewards} />
            <Route exact path="/rewards/offer-detail" component={RewardOfferDetail} />
            <Route component={NotFound} />
          </Switch>
        <Footer />
        </ScrollToTop>
      </Router>
    );
  }


}

export default App;
