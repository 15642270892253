import React, { Component } from "react";
import clientX from "../../../utils/clientX";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

export default class OrderDetail extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      order: null,
      isModelOpen: false
    };
  }

  viewUploadBill = () => {
    this.setState({ isModelOpen: true });
  };

  closeModelHandler = () => {
    this.setState({ isModelOpen: false });
  };


  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    const {
      match: { params }
    } = this.props;
    clientX.get(`v1/shopping-sites/orders/${params.id}`).then(response => {
      let data = response.data.data;

      if (this._isMounted) {
        this.setState({
          order: data
        });
      }
    });
  }

  render() {
    return (
      null !== this.state.order && (
        <div className="container-fluid">
          <div className="row mt-3 mb-3 mt-md-4 mb-md-4 align-items-center">
            <div className="col-12 text-md-center">
              <small>
                <Link to="/">
                  <FormattedMessage
                    id="breadcrumbs.home"
                    defaultMessage="Home"
                  />
                </Link>
              </small>{" "}
              /{" "}
              <small>
                <Link to="/orders">
                  <FormattedMessage id="navbar.orders" />
                </Link>
              </small>{" "}
              /{" "}
              <small>
                <strong>
                  <FormattedMessage id="orders.order" />
                </strong>
              </small>
            </div>
          </div>
          <div className="row mb-3 mb-md-4">
            <div className="col-md-6 offset-md-3">
              <div className="list-group">
                <div className="list-group-item list-group-item-action text-center text-md-left">
                  <img
                    className="mt-2 mt-md-0 p-2 border-0"
                    src={this.state.order.image}
                    style={{
                      background: "#fff",
                      height: "60px",
                      border: "1px solid rgba(0, 0, 0, 0.125)",
                      display: "inline",
                      boxSizing: "content-box",
                      borderRadius: "0.25rem",
                      marginRight: "15px"
                    }}
                    alt={this.state.order.name}
                  />
                  <p className="d-block d-md-inline mt-1 mt-md-0 mb-0">
                    <FormattedMessage id="order.purchased-from" />{" "}
                    {this.state.order.name}{" "}
                    <strong>{this.state.order.voucher_name}</strong>
                  </p>
                </div>

                <div className="list-group-item list-group-item-action">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <OrderStatusLabel order={this.state.order} />
                    </div>
                    <div className="col-md-6 text-md-right">
                      <p className="mb-0">
                        <FormattedMessage id="orders.your-order-number-is" />{" "}
                        <strong>{this.state.order.order_number}</strong>
                      </p>
                      <p className="mb-0">{this.state.order.created_at}</p>
                    </div>
                  </div>
                </div>
                <div className="list-group-item list-group-item-action">
                  <p className="mb-0">
                    <FormattedMessage id="general.amount" /> Rs.{" "}
                    <strong>{this.state.order.amount}</strong>
                  </p>
                </div>
                <div className="list-group-item list-group-item-action">
                  <p className="mb-0">
                    <strong>{this.state.order.wrp}</strong> WRPs{" "}
                    {this.state.order.wrp_credited ? "credited" : "are pending"}
                  </p>
                </div>
              </div>

              {this.state.order.transaction_image && (
                <DownloadBill {...this.state} />
              )}
            </div>
          </div>
        </div>
      )
    );
  }
}

export const DownloadBill = props => {
  const url = props.order.transaction_image;
  return (
    <button
      className="btn btn-outline-primary btn-block mt-3"
      onClick={() => window.open(url)}
    >
      <FormattedMessage
        id="order.download-bill"
        defaultMessage="Download Uploaded bill"
      />
    </button>
  );
};

function OrderStatusLabel(props) {
  switch (props.order.status) {
    case 3:
      return (
        <p className="badge badge-success text-white mb-0">
          {props.order.status_text}
        </p>
      );
    case 4:
      return (
        <p className="badge badge-danger text-white mb-0">
          {props.order.status_text}
        </p>
      );
    default:
      return (
        <p className="badge badge-warning text-white mb-0">
          {props.order.status_text}
        </p>
      );
  }
}
