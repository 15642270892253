import React, { Component } from "react";
import client from "../../../utils/client";
import { Link } from "react-router-dom";
import clientX from "../../../utils/clientX";
import ContentLoader from "react-content-loader";
import { FormattedMessage } from "react-intl";
import bookmarkActive from "../../../assets/bookmark-active.svg";
import bookmarkInactive from "../../../assets/bookmark-inactive.svg";
import affiliateBanner from "../../../assets/affliate-banner-snippet.jpg";
import billUploadIcon from "../../../assets/bill-upload.svg";
import coffeeMugIcon from "../../../assets/coffee-mug.svg";

export default class ShoppingSiteDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shoppingSite: null,
      isLoading: true
    };
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;

    this.setState(
      {
        isLoading: true
      },
      () => {
        client.get(`v1/shopping-sites/${params.id}`).then(response => {
          let data = response.data.data;

          this.setState({
            shoppingSite: data,
            isLoading: false
          });
        });
      }
    );
  }

  toggleBookmark = (event, id, isBookmarked) => {
    event.preventDefault();
    event.stopPropagation();
    this.onToggleBookmark(
      id,
      isBookmarked,
      `/shopping-sites/${id}/bookmark`,
      "shoppingSites"
    );
  };

  onToggleBookmark = (id, isBookmarked, url, type) =>
    !isBookmarked
      ? this.addBookmark(id, url, this.props.shoppingSites)
      : this.deleteBookmark(id, url, this.props.shoppingSites);

  addBookmark = (id, url, shoppingSites) => {
    client
      .post(url, {})
      .then(response => {
        this.setState(prevState => ({
          shoppingSite: {
            ...prevState.shoppingSite,
            bookmarked: !prevState.shoppingSite.bookmarked
          }
        }));
      })
      .catch(error => {});
  };

  deleteBookmark = (id, url, shoppingSites) => {
    client
      .delete(url)
      .then(response => {
        this.setState(prevState => ({
          shoppingSite: {
            ...prevState.shoppingSite,
            bookmarked: !prevState.shoppingSite.bookmarked
          }
        }));
      })
      .catch(error => {});
  };

  onAcceptTerms = () => {
    clientX
      .post(`shopping-sites/${this.state.shoppingSite.id}/visits`)
      .then(response => {
        window.open(response.data.data.link, "_blank");
      });
  };

  render() {
    return this.state.isLoading ? <this.Shimmer /> : <this.ShoppingSite />;
  }

  Shimmer = props => {
    return (
      <div className="container-fluid py-3 py-md-5">
        <div className="row">
          <div className="col-md-6">
            <div className="box shine box-shopping-site-detail-logo"></div>
          </div>
          <div className="col-md-6">
            <div className="box shine" style={{ height: "38px" }}></div>
            <ContentLoader
              height={160}
              width={400}
              speed={2}
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            >
              <rect x="0" y="19" rx="3" ry="3" width="350" height="6" />
              <rect x="0" y="36" rx="3" ry="3" width="380" height="6" />
              <rect x="0" y="53" rx="3" ry="3" width="201" height="6" />
              <rect x="0" y="81" rx="0" ry="0" width="352" height="6" />
              <rect x="0" y="98" rx="0" ry="0" width="384" height="6" />
              <rect x="-1" y="115" rx="0" ry="0" width="194" height="6" />
            </ContentLoader>
          </div>
        </div>
      </div>
    );
  };

  goToUploadShoppingBill = () => {
    this.props.history.push(
      `/upload-bill/shopping-site/${this.state.shoppingSite.id}`
    );
  };

  ShoppingSite = props => {
    return (
      <React.Fragment>
        <div
          className="container-fluid affiliate-banner"
          style={{
            backgroundImage: `url(${affiliateBanner})`
          }}
        >
          <div className="bread-crumbs">
            <small>
              <Link to="/">
                <FormattedMessage id="breadcrumbs.home" defaultMessage="Home" />
              </Link>
            </small>{" "}
            /{" "}
            <small>
              <Link to="/shopping-sites">
                <FormattedMessage
                  id="home.shopping-sites"
                  defaultMessage="Shopping Sites"
                />
              </Link>
            </small>{" "}
            /{" "}
            <small>
              <strong>{this.state.shoppingSite.name}</strong>
            </small>
          </div>
          <div className="logo-container">
            <div className="card mb-md-4">
              {
                <img
                  src={
                    this.state.shoppingSite.bookmarked
                      ? bookmarkActive
                      : bookmarkInactive
                  }
                  className="bookmark"
                  alt=""
                  onClick={event =>
                    this.toggleBookmark(
                      event,
                      this.state.shoppingSite.id,
                      this.state.shoppingSite.bookmarked
                    )
                  }
                />
              }
              <div className="card-body">
                <img
                  className="affliate-logo-img"
                  src={this.state.shoppingSite.web_image_url}
                  alt={this.state.shoppingSite.name}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid mt-5 mt-md-4 font-proxima-nova">
          <div className="row">
            <div className="offset-md-3 offset-lg-2 col-lg-3 col-md-4 col-12">
              <button
                className="btn btn-primary btn-block mb-3 mt-4 mt-sm-0 home-btn"
                onClick={this.onAcceptTerms}
              >
                <FormattedMessage
                  id="shopping-sites.accept-terms"
                  defaultMessage="Accept terms"
                />
              </button>
            </div>
            <div className="col-12 col-md-3">
              {!this.state.shoppingSite.auto_tracking && (
                <button
                  className="w-100 mb-3 mt-1 mt-sm-0 btn btn-outline-primary home-btn"
                  onClick={this.goToUploadShoppingBill}
                >
                  <FormattedMessage
                    id="general.upload-bill"
                    defaultMessage="Upload Bill"
                  />
                </button>
              )}{" "}
            </div>
          </div>
          <div className="row mt-md-5 mt-2">
            {this.state.shoppingSite.auto_tracking ? (
              <div className="col-12">
                <img
                  src={coffeeMugIcon}
                  alt="Bill Upload Icon"
                  className="body-icon mr-2 mb-2"
                />
                <i>
                  All your purchases will be auto-tracked and will reflect in {this.state.shoppingSite.tracking_days}&nbsp;days. Provisional WRP will be confirmed within {this.state.shoppingSite.confirmation_days} days once
                  the return/refund window is over.
                </i>
              </div>
            ) : (
              <div className="col-12">
                <img
                  src={billUploadIcon}
                  alt="Bill Upload Icon"
                  className="body-icon mr-2"
                />
                <i>
                  Uploading a bill is mandatory for Amazon. Provisional WRP will
                  be awarded within {this.state.shoppingSite.tracking_days}&nbsp;days after bill upload. Provisional WRP
                  will be confirmed within {this.state.shoppingSite.confirmation_days} days after the return/refund
                  window ends.
                </i>
              </div>
            )}
          </div>
          <div
            className="shopping-site-terms text-left mt-md-5 mt-3"
            dangerouslySetInnerHTML={{
              __html: this.state.shoppingSite.terms_and_conditions
            }}
          />
        </div>
      </React.Fragment>
    );
  };
}
