import axios from 'axios';

const clientX =  axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true
  });
  
  
  //Request Interceptor
  clientX.interceptors.request.use(function (config) {
  
      // Do something before request is sent
      return config;
  
  }, function (error) {
  
      // Do something with request error
      return Promise.reject(error);
  
  });
  
  //Response Interceptor
  clientX.interceptors.response.use(function (response) {
      
      // Do something with response data
      return response;
  
    }, function (error) {

        if(401 === parseInt(error.response.status)){
            window.location.href=`${process.env.REACT_APP_SSO_URL}/login?continue=${window.location.href}`
        }
      
        // Do something with response error
        return Promise.reject(error);

  });
  
  export default clientX;