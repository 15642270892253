import React from "react";
import RandomBillAmoutAndWrpEarned from './RandomBillAmtAndWrpEarned';
import Modal from "react-bootstrap/Modal";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
const WrpBenefitModel = props => {
    const { wrp_ratio } = props;
    const percentage = wrp_ratio * 100;
    return (
      <React.Fragment>
        <Modal
          show={props.isWrpBenefitModelOpen}
          onHide={props.closeWrpModelHandler}
          className="font-proxima-nova"
        >
          <Modal.Header closeButton className="border-0">
            <Modal.Title> <FormattedMessage
                id="general.wrp_benefits"
                defaultMessage="WRP Benefits"
              /></Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="row">
              <div className="col-12 text-left">
                <p>
                  Any purchase from this store will earn you WRP equal to{" "}
                  {wrp_ratio * 100}% of your bill amount. Please upload the bill
                  amount to get it approved by the store to receive WRP.
                </p>
                <p>WRP Earning: &nbsp; Rs 100 Spent = 125.00 WRP</p>
                <p className="font-proxima-nova-bold">Example:</p>
                <div className="mb-5" style={{ lineHeight: "1em" }}>
                  <RandomBillAmoutAndWrpEarned
                    min={100}
                    max={1000}
                    percentage={percentage}
                  />
                </div>
                <div style={{ lineHeight: "1em" }}>
                  <RandomBillAmoutAndWrpEarned
                    min={1000}
                    max={10000}
                    percentage={percentage}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer className="border-0">
          <Link to="/wrp-details">
            <button className="btn btn-primary" >
              <FormattedMessage
                id="home.know-more"
                defaultMessage="Know More"
              />
            </button>
            </Link>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  };

  export default WrpBenefitModel;