import React, { Component } from 'react';
import client from '../../../utils/client';
import { Link } from "react-router-dom";
import winnerVector from '../../../assets/empty-state-winner.svg';
import {FormattedMessage} from 'react-intl';

export default class Winners extends Component{

    constructor(props){
        super(props);

        this.state = {
            winners:[]
        }
    }

    componentDidMount(){

        client.get('v1/winners')
        .then((response)=>{
        
            this.setState({
                winners: response.data.data
            });

        });

    }

    render(){

        return(
            <div className="container-fluid">
                <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
                    <div className="col-12">
                        <small><Link to="/"><FormattedMessage id="breadcrumbs.home" defaultMessage="Home" /></Link> / <strong><FormattedMessage id="winners.winners" defaultMessage="Winners" /></strong></small>
                    </div>
                </div>
                {
                    (0 === this.state.winners.length)
                    ?
                    (
                        <div className="row">
                            <div className="col-12 text-center mt-3 mb-3 mt-md-4 mb-md-4">
                                <img className="winner-vector" src={winnerVector} alt="winner" />
                                <p className="mt-3"><strong><FormattedMessage id="winners.start-shopping-and-prepare" defaultMessage="Start shopping and prepare to get your name up here!" /></strong></p>
                            </div>
                        </div>
                    )
                    :
                    (
                        this.state.winners.map((item, index) => {

                            return(
                                <div className="row align-items-center">
                                    <div className="col-12 mb-5">
                                        <div className="card mb-3" style={{boxShadow: '0 4px 14px 0 rgba(0,0,0,0.08)',borderRadius:'8px', border:'none'}}>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <div style={{backgroundImage:`url(${item.image_url})`,height:'100px',width:'100px',borderRadius:'50%',backgroundSize:'cover'}}></div>
                                                    </div>
                                                    <div className="col-4">
                                                        <p><strong>₹ {item.prize_amount}</strong></p>
                                                        <p>{item.name}, {item.city}</p>
                                                    </div>
                                                    <div className="col-4">
                                                        <p>{item.draw_date}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    )
                }
            </div>
        );
    }
}