export function fetchingPartners(){
    return {
        type: 'FETCHING_PARTNERS'
    }
}

export function fetchingPartnerCategories(){
    return {
        type: 'FETCHING_PARTNER_CATEGORIES'
    }
}

export function fetchingNextPage(){
    return {
        type: 'FETCHING_NEXT_PARTNER_PAGE'
    }
}

export function setPartners(partners,nextPage){
    return {
        type: 'SET_PARTNERS',
        partners,
        nextPage
    }
}

export function addPartners(partners, nextPage){
    return {
        type: 'ADD_PARTNERS',
        partners,
        nextPage
    }
}

export function setDistanceState(distanceValue) {
    return {
        type:'SET_DISTANCE_STATE',
        distanceValue
    }
}

export function setRatingState(rating) {
    return {
        type:'SET_RATING_STATE',
        rating
    }
}



export function setPartnerCategories(categories){
    return {
        type: 'SET_PARTNER_CATEGORIES',
        categories
    }
}

export function selectCategoryForNearbyPartner(id){
    return {
        type: 'SELECT_CATEGORY_FOR_NEARBY_PARTNER',
        id
    }
}

export function resetCategory(id){
    return {
        type: 'RESET_CATEGORY',
        id
    }
}


export function onSelectRating(id){
    return {
        type: 'SELECT_RATING_FOR_NEARBY_PARTNER',
        id
    }
}

export function onUnselectRating(id){
    return {
        type: 'UNSELECT_RATING_FOR_NEARBY_PARTNER',
        id
    }
}

export function onPartnerFilterClick() {
    return {
        type: 'PARTNER_FILTER_CLICK'
    }

}

export function onPartnerModelTransitionDone() {
    return {
        type: 'PARTNER_MODEL_TRANSITION_DONE'
    }

}
export function resetPartnerFilter() {
    return {
        type: 'RESET_PARTNER_FILTER'
    }
}

export function onSortChange(value) {
    return {
        type: 'NEARBY_PARTNER_SORT_CHANGE',
        value
    }
}

export function unselectCategoryForNearbyPartner(id){
    return {
        type: 'UNSELECT_CATEGORY_FOR_NEARBY_PARTNER',
        id
    }
}

export function setPartnerListAfterBookmarkToggle(id,partners){
    return {
        type:'SET_PARTNER_LIST_AFTER_BOOKMARK_TOGGLE',
        id,partners
    }
}