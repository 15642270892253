import React, { Component } from "react";
import { Link } from "react-router-dom";
import clientX from "../../../utils/clientX";
import SideBar from "../components/SideBar";
import Profile from "../components/Profile";
import editIcon from "../../../assets/edit.svg";
import Modal from "react-bootstrap/Modal";
import classNames from "classnames";
import { ToastContainer, toast } from "react-toastify";
import { FormattedMessage, injectIntl } from "react-intl";
import update from "immutability-helper";
import InlineLoader from "../../../components/InlineLoader";
class VerificationDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verificationDetails: null,
      isModelOpen: false,
      loading: false,
      editVerificationData: {
        name: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false
        },
        pan: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false
        },
        gender: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false,
          options: [
            { id: "M", name: "Male" },
            { id: "F", name: "Female" }
          ]
        },
        dob: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false
        },
        isFormValid: false
      }
    };
  }

  editVerificationDetail = () => {
    const { name, gender, dob } = { ...this.state.profile };
    const newData = update(this.state, {
      isModelOpen: { $set: true },
      editVerificationData: {
        isFormValid: { $set: true },
        name: {
          value: { $set: name || "" },
          isValid: { $set: name ? true : false }
        },
        gender: {
          value: { $set: gender || "" },
          isValid: { $set: gender ? true : false }
        },
        dob: {
          value: { $set: dob || "" },
          isValid: { $set: dob ? true : false }
        }
      }
    });
    this.setState(newData);
  };

  handleClose = () => {
    this.setState({ ...this.state, isModelOpen: false });
  };

  componentDidMount() {
    clientX.get("v1/verification-details").then(response => {
      response.data.success &&
        this.setState({
          verificationDetails: response.data.data
        });
    });
  }
  submitHandler = () => {
    this.setState({ loading: true });
    const { name, gender, pan, dob } = this.state.editVerificationData;
    const formData = {
      name: name.value,
      gender: gender.value,
      dob: dob.value
        .split("-")
        .reverse()
        .join("-")
    };
    pan.value.match(/^\d{4}\d{4}\d{4}$/)
      ? (formData.aadhaar = pan.value)
      : (formData.pan = pan.value);

    clientX
      .post(`verify-profile`, formData)
      .then(response => {
        this.setState({ loading: false });
      })
      .catch(error => {
        this.setState({ loading: false });
        toast.error(error.response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      });
  };

  checkValidity(fieldName, value) {
    const { editVerificationData } = { ...this.state };

    switch (fieldName) {
      case "name":
        editVerificationData.name.isValid = value.trim().length > 0;
        editVerificationData.name.error = editVerificationData.name.isValid
          ? ""
          : "Name is required";
        break;

      case "pan":
        editVerificationData.pan.isValid =
          null !== value.match(/^\d{4}\d{4}\d{4}$/) ||
          value.match(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/);
        editVerificationData.pan.error = editVerificationData.pan.isValid
          ? ""
          : "PAN/Aadhaar not valid";
        break;

      case "gender":
        editVerificationData.gender.isValid = value.trim().length > 0;
        editVerificationData.gender.error = editVerificationData.gender.isValid
          ? ""
          : "Gender is invalid";
        break;
      case "dob":
        editVerificationData.dob.isValid =
          null !== value.match(/^\d{4}-\d{2}-\d{2}$/);
        editVerificationData.dob.error = editVerificationData.dob.isValid
          ? ""
          : "dob is invalid";
     break;
      default:
        break;
    }

    const newData = update(this.state, {
      editVerificationData: {
        isFormValid: { $set: this.areAllFieldsValid() }
      }
    });

    this.setState(newData);
  }

  areAllFieldsValid() {
    const { name, pan, gender, dob } = this.state.editVerificationData;
    return [name, pan, gender, dob].every(x => x.value && x.isValid);
  }

  inputChangeHandler = e => {
    const { name, value } = e.target;
    const newData = update(this.state, {
      editVerificationData: {
        [name]: {
          value: { $set: value },
          isPristine: { $set: true }
        }
      }
    });

    this.setState(newData, () => {
      this.checkValidity(name, value);
    });
  };

  EditVerificationFormDialog = props => {
    const { name, pan, gender, dob, isFormValid } = {
      ...props.editVerificationData
    };
    return (
      <React.Fragment>
        <Modal show={props.isModelOpen} onHide={props.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Verification form</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <form noValidate>
              <div className="form-group">
                <label>
                  <FormattedMessage
                    id="profile.full-name"
                    defaultMessage="Full Name"
                  />
                </label>
                <input
                  type="text"
                  name="name"
                  autoComplete="off"
                  onChange={props.inputChangeHandler}
                  value={name.value}
                  className={classNames("form-control", {
                    "is-valid": name.isValid,
                    "is-invalid": name.isPristine && !name.isValid
                  })}
                />
              </div>
              <div className="form-group">
                <label>
                  <FormattedMessage
                    id="profile.pan.aadhaar"
                    defaultMessage="Pan / Aadhaar"
                  />
                </label>
                <input
                  type="text"
                  name="pan"
                  autoComplete="off"
                  onChange={props.inputChangeHandler}
                  value={pan.value}
                  className={classNames("form-control", {
                    "is-valid": pan.isValid,
                    "is-invalid": pan.isPristine && !pan.isValid
                  })}
                />
              </div>

              <div className="form-group">
                <label>
                  <FormattedMessage
                    id="profile.gender"
                    defaultMessage="Select Gender"
                  />
                </label>
                <select
                  className="form-control"
                  name="gender"
                  autoComplete="off"
                  onChange={props.inputChangeHandler}
                  value={gender.value}
                >
                  <option value="">
                    {props.intl.formatMessage({ id: "general.choose" })}
                  </option>

                  {gender.options.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="form-group">
                <label>
                  <FormattedMessage id="profile.dob" defaultMessage="dob" />
                </label>
                <input
                  type="date"
                  name="dob"
                  autoComplete="off"
                  onChange={props.inputChangeHandler}
                  value={dob.value}
                  className={classNames("form-control", {
                    "is-valid": dob.isValid,
                    "is-invalid": dob.isPristine && !dob.isValid
                  })}
                />
              </div>
            </form>
          </Modal.Body>

          <Modal.Footer>
            {props.loading && <InlineLoader />}

            <button
              className="btn btn-primary"
              onClick={props.submitHandler}
              disabled={!isFormValid}
            >
              <FormattedMessage
                id="profile.save-verify"
                defaultMessage="Save Verification Data"
              />
            </button>
          </Modal.Footer>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
        </Modal>
      </React.Fragment>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="container-fluid pt-3 pb-3 pt-md-4 pb-md-4"
          style={{ backgroundColor: "#F2F6FA" }}
        >
          <div className="row mb-3">
            <div className="col-12">
              <small>
                <Link to="/">
                  <FormattedMessage
                    id="breadcrumbs.home"
                    defaultMessage="Home"
                  />
                </Link>
              </small>{" "}
              /{" "}
              <small>
                <Link to="/profile">
                  <FormattedMessage
                    id="profile.profile"
                    defaultMessage="Profile"
                  />
                </Link>
              </small>{" "}
              /{" "}
              <small>
                <strong>
                  <FormattedMessage
                    id="profile.verification-details"
                    defaultMessage="Verification Details"
                  />
                </strong>
              </small>
            </div>
          </div>

          <div className="d-none d-md-block">
            <Profile />
          </div>
        </div>

        <div className="container-fluid mt-3 mb-3">
          <div className="row">
            <div className="d-none col-md-3 d-md-block">
              <SideBar />
            </div>
            <div className="col-12 col-md-9">
              <div className="row d-none d-md-flex">
                <div className="col-9">
                  <h3>
                    <FormattedMessage
                      id="profile.verification-details"
                      defaultMessage="Verification Details"
                    />
                  </h3>
                  <this.EditVerificationFormDialog
                    {...this.state}
                    {...this.props}
                    inputChangeHandler={this.inputChangeHandler}
                    handleClose={this.handleClose}
                    submitHandler={this.submitHandler}
                  />
                </div>
                <div className="col-3">
                  <img
                    src={editIcon}
                    alt="edit"
                    style={{ cursor: "pointer" }}
                    onClick={this.editVerificationDetail}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div
                    className="card"
                    style={{
                      backgroundColor: "#F8F9FA",
                      boxShadow: "0 0 11px 0 rgba(0,0,0,0.07)",
                      borderRadius: "10px",
                      border: "none"
                    }}
                  >
                    <div className="card-body">
                      {null !== this.state.verificationDetails && (
                        <ul className="list-group list-group-flush">
                          <li
                            className="list-group-item pl-0"
                            style={{ backgroundColor: "#F8F9FA" }}
                          >
                            <p className="mb-0">
                              <FormattedMessage
                                id="profile.full-name"
                                defaultMessage="Full Name"
                              />
                            </p>
                            <p className="mb-0">
                              <strong>
                                {this.state.verificationDetails.name}
                              </strong>
                            </p>
                          </li>
                          <li
                            className="list-group-item pl-0"
                            style={{ backgroundColor: "#F8F9FA" }}
                          >
                            <p className="mb-0">
                              <FormattedMessage
                                id="profile.gender"
                                defaultMessage="Gender"
                              />
                            </p>
                            <p className="mb-0">
                              <strong>
                                {this.state.verificationDetails.gender}
                              </strong>
                            </p>
                          </li>
                          <li
                            className="list-group-item pl-0"
                            style={{ backgroundColor: "#F8F9FA" }}
                          >
                            <p className="mb-0">
                              <FormattedMessage
                                id="profile.dob"
                                defaultMessage="Date Of Birth"
                              />
                            </p>
                            <p className="mb-0">
                              <strong>
                                {this.state.verificationDetails.dob}
                              </strong>
                            </p>
                          </li>
                          <li
                            className="list-group-item pl-0"
                            style={{ backgroundColor: "#F8F9FA" }}
                          >
                            <p className="mb-0">
                              <FormattedMessage
                                id="profile.pan"
                                defaultMessage="PAN"
                              />
                            </p>
                            <p className="mb-0">
                              <strong>
                                {this.state.verificationDetails.pan}
                              </strong>
                            </p>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default injectIntl(VerificationDetails);
