import React, { Component } from "react";
import clientX from "../../../utils/clientX";
import { Link } from "react-router-dom";
import referralImg from "../../../assets/referral.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormattedMessage } from "react-intl";

export default class Referral extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: null,
      points: null
    };
  }

  componentDidMount() {
    clientX.get("v1/profile").then(response => {
      this.setState({
        profile: response.data.data
      });
    });

    clientX.get("v1/referral-points").then(response => {
      this.setState({
        points: response.data.data.total_points
      });
    });
  }

  onCopy = e => {
    this.code.select();

    document.execCommand("copy");

    toast.success("Code copied successfully.", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });

    e.target.focus();
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
          <div className="col-12">
            <small>
              <Link to="/">
                <FormattedMessage id="breadcrumbs.home" defaultMessage="Home" />
              </Link>{" "}
              /{" "}
              <strong>
                <FormattedMessage
                  id="navbar.refer-and-earn"
                  defaultMessage="Refer & Earn"
                />
              </strong>
            </small>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12">
            <h2 className="font-proxima-nova-bold">
              <FormattedMessage
                id="home.we-value-your-friendship"
                defaultMessage="We value your friendship!"
              />
            </h2>
          </div>
        </div>
        <div className="row mb-3 mb-md-4">
          <div className="col-12 col-md-6">
            <img src={referralImg} className="referral-vector" alt="referral" />
          </div>
          <div className="col-12 col-md-6 mt-3 mt-md-0">
            <div
              className="card"
              style={{ backgroundColor: "#F7F7F7", border: "none" }}
            >
              <div className="card-body">
                <h2 className="font-proxima-nova-bold">
                  <FormattedMessage
                    id="general.text_reward_point"
                    defaultMessage="Refer your friend and get 100 WINDS Reward Points."
                  />
                </h2>
                <p className="mt-3">
                  <strong>
                    <FormattedMessage
                      id="rewards.total-reward-points"
                      defaultMessage="Total Reward Points"
                    />
                    :{" "}
                    <span className="text-success">₹ {this.state.points}</span>
                  </strong>
                </p>
                {this.state.profile && (
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      ref={code => (this.code = code)}
                      className="form-control"
                      value={this.state.profile.referral_code}
                      readOnly
                    />
                    <div className="input-group-append">
                      <span
                        className="input-group-text"
                        style={{ cursor: "pointer" }}
                        onClick={this.onCopy}
                      >
                        <FormattedMessage
                          id="general.copy"
                          defaultMessage="Copy"
                        />
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
          </div>
        </div>
      </div>
    );
  }
}
