import React from "react";
import {getRandomNumber} from '../utils/helper';
const RandomBillAmoutAndWrpEarned = props => {
    const { min, max, percentage } = props;
    const billAmount = getRandomNumber(min, max);
    const wrpEarned = (billAmount * percentage) / 100;
    return (
      <React.Fragment>
        <p>Bill Amount: &nbsp; Rs &nbsp;{billAmount}</p>
        <p>WRP Earned: &nbsp; {wrpEarned}&nbsp; WRP</p>
      </React.Fragment>
    );
  };
  export default RandomBillAmoutAndWrpEarned;