import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { withRouter } from "react-router-dom";

class BannerCarousel extends Component{

    constructor(props){

        super(props);

        this.state = {
            index: 0,
            direction: null,
        }
        

    }

    handleSelect = (selectedIndex, e) => {

        this.setState({
          index: selectedIndex,
          direction: e.direction,
        });
      }

    onBannerClicked = (banner) => {

        if(null == banner.link){
            return;
        }

        if(banner.link.startsWith("http")){
            window.open(banner.link);
        }

    }

    render(){

        const { index, direction } = this.state;

        return(
            <Carousel
            activeIndex={index}
            direction={direction}
            onSelect={this.handleSelect}
            >
                {
                    this.props.banners.map((item,index)=>{

                        return(
                            <Carousel.Item key={index}>
                                <img
                                className="d-block w-100"
                                onClick={()=>{this.onBannerClicked(item)}}
                                src={item.image_url}
                                alt={item.title}
                                />
                            </Carousel.Item>
                        );

                    })
                }
            </Carousel>
        );
    }

}

export default withRouter(BannerCarousel);