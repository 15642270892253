
import axios from 'axios';
import client from './client';

export function getGeoLocation(){

    return new Promise((resolve,reject)=>{
      if ("geolocation" in navigator) {

        navigator.geolocation.getCurrentPosition(
          function success(position) {
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            });
          },
          function error(error_message) {
            reject('An error has occured while retrieving location', error_message);
          }  
        );
  
      } else {
       reject('geolocation is not enabled on this browser');
      }
    });

}

export function getAddress(latitude, longitude){

  return axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_MAP_API_KEY}`);

}

export function getPlace(keyword){

  return client.get(`v1/places-autocomplete?input=${keyword}&key=${process.env.REACT_APP_MAP_API_KEY}`);

}

export function getPlaceById(id){

  return client.get(`v1/places?placeid=${id}&key=${process.env.REACT_APP_MAP_API_KEY}`);

}