import React, { Component } from 'react';
import ShoppingSitesCarousel from '../components/ShoppingSitesCarousel';
import NearbyPartnerCarousel from '../components/NearbyPartnerCarousel';
import VoucherCarousel from '../components/VoucherCarousel';
import OffersCarousel from '../components/OffersCarousel';
import { connect } from 'react-redux';
import emptyStateimg from '../../../assets/search-results-empty-state.svg';
import client from '../../../utils/client';
import {search, setSearchQuery, isSearching} from '../actions';
import { injectIntl, FormattedMessage } from 'react-intl';
import lodash from 'lodash';
import BillPayCarousel from '../components/BillPayCarousel';

class SearchResults extends Component{

    handleKeyDown = (e) => {

        this.props.onQuery(e.target.value)

    }

    handleSearch = () => {

       if(this.props.query.length > 1){
            this.props.onSearch(this.props.query, this.props.latitude, this.props.longitude);
       }
        
    }

    render(){

        if(this.props.isSearching){

            return(
                <this.Shimmer />
            )

        }

        if(0===this.props.count){

            return(
                <this.EmptyState />
            )

        }

       if(
        0 === this.props.results.affiliate.meta.total 
        && 0 === this.props.results.partner.meta.total 
        && 0 === this.props.results.voucher.meta.total 
        && 0 === this.props.results.offers.meta.total 
        && 0 === this.props.results.billpay.meta.total 
        ){
           return(
                <this.NoResults />
           )
        }

       return(
           <this.Results />
       )

    }

    Shimmer = (props) => {

        return(
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row mt-3 mb-3 d-md-none">
                        <div className="col-12">
                            <div className="input-group mb-3">
                                <input type="text" 
                                className="form-control"
                                onKeyDown={this.handleKeyDown} 
                                placeholder={this.props.intl.formatMessage({id: 'navbar.seach-box-placeholder'})} 
                                />
                                <div className="input-group-append">
                                    <span className="input-group-text" onClick={this.handleSearch} >Search</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    lodash.range(4).map(()=>{
                        return(
                            <div className="container-fluid container-fluid--home-section">
                                <div className="row row--home-section-title">
                                    <div className="col-md-12">
                                        <div className="heading shine"></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4 col-md-3">
                                        <div className="box shine"></div>
                                    </div>
                                    <div className="col-4 col-md-3">
                                        <div className="box shine"></div>
                                    </div>
                                    <div className="col-4 col-md-3">
                                        <div className="box shine"></div>
                                    </div>
                                    <div className="col-4 col-md-3 d-none d-md-block">
                                        <div className="box shine"></div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </React.Fragment>
        )

    }

    EmptyState = (props) => {

        return(
            <div className="container-fluid">
                <div className="row mt-3 mb-3 d-md-none">
                    <div className="col-12">
                        <div className="input-group mb-3">
                            <input type="text" 
                            className="form-control"
                            onKeyDown={this.handleKeyDown} 
                            placeholder={this.props.intl.formatMessage({id: 'navbar.seach-box-placeholder'})} 
                            />
                            <div className="input-group-append">
                                <span className="input-group-text" onClick={this.handleSearch} ><FormattedMessage id="general.search" /></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-md-4 mb-md-4">
                    <div className="col-12">
                        <div className="row align-items-center">
                            <div className="col-12 text-center">
                                <img className="search-vector" src={emptyStateimg} alt="No Results" />
                                <p className="mt-3 mb-3 font-weight-bold"><FormattedMessage id="search.you-have-not-searched" /></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }

    NoResults = (props) => {

        return(
            <div className="container-fluid">
                <div className="row mt-3 mb-3 d-md-none">
                    <div className="col-12">
                        <div className="input-group mb-3">
                            <input type="text" 
                            className="form-control"
                            onKeyDown={this.handleKeyDown} 
                            placeholder={this.props.intl.formatMessage({id: 'navbar.seach-box-placeholder'})} 
                            />
                            <div className="input-group-append">
                                <span className="input-group-text" onClick={this.handleSearch} ><FormattedMessage id="general.search" /></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-md-4 mb-md-4">
                    <div className="col-12">
                        <div className="row align-items-center">
                            <div className="col-12 text-center">
                                <img src={emptyStateimg} alt="No Results" style={{width:'50%',margin:'0 auto'}} />
                                <p className="mt-3 mb-3"><FormattedMessage id="general.no-results-found" /></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }

    Results = (props) => {

        return(
            <React.Fragment>
                <div className="container-fluid d-md-none">
                    <div className="row mt-3 mb-2">
                        <div className="col-12">
                            <div className="input-group mb-3">
                                <input type="text" 
                                className="form-control"
                                onKeyDown={this.handleKeyDown} 
                                placeholder={this.props.intl.formatMessage({id: 'navbar.seach-box-placeholder'})}  />
                                <div className="input-group-append">
                                    <span className="input-group-text" onClick={this.handleSearch} ><FormattedMessage id="general.search" /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                { this.props.results.affiliate.meta.total > 0 && 
                    <div className="container-fluid">
                        <div className="row mt-3 mb-2 mt-md-4">
                            <div className="col-12">
                                <div className="row align-items-center">
                                    <div className="col-12">
                                        <h4 className="font-weight-bold">{this.props.results.affiliate.meta.total} <FormattedMessage id="search.results-in-shopping-sites" /></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <ShoppingSitesCarousel affiliate={this.props.results.affiliate.data}/>
                            </div>
                        </div>
                    </div>
                }
                { this.props.results.partner.meta.total > 0 && 
                    <div className="container-fluid">
                        <div className="row mt-3 mb-2 mt-md-4">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <h4 className="font-weight-bold">{this.props.results.partner.meta.total} <FormattedMessage id="search.results-in-nearby-partners" /></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <NearbyPartnerCarousel partners={this.props.results.partner.data} />
                            </div>
                        </div>
                    </div>
                }
                { this.props.results.voucher.meta.total > 0 && 
                    <div className="container-fluid">
                        <div className="row mt-3 mb-2 mt-md-4">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <h4 className="font-weight-bold">{this.props.results.voucher.meta.total} <FormattedMessage id="search.results-in-vouchers" /></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <VoucherCarousel vouchers={this.props.results.voucher.data} />
                            </div>
                        </div>
                    </div>
                }
                { this.props.results.offers.meta.total > 0 && 
                    <div className="container-fluid">
                        <div className="row mt-3 mb-2 mt-md-4">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <h4 className="font-weight-bold">{this.props.results.offers.meta.total} <FormattedMessage id="search.results-in-offers-near-you" /></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <OffersCarousel offers={this.props.results.offers.data} />
                            </div>
                        </div>
                    </div>
                }
                { this.props.results.billpay.meta.total > 0 && 
                    <div className="container-fluid">
                        <div className="row mt-3 mb-2 mt-md-4">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <h4 className="font-weight-bold">{this.props.results.billpay.meta.total} Results in Bill Pay</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <BillPayCarousel services={this.props.results.billpay.data} />
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        )

    }

}

function mapStateToProps(state) {
    return {
      results: state.search.results,
      query: state.search.query,
      count: state.search.count,
      isSearching: state.search.isSearching,
      latitude: state.location.latitude,
      longitude: state.location.longitude
    };
}

function mapDispatchToProps(dispatch) {

    return {
        onSearch: (query, latitude, longitude) => {

            dispatch(isSearching(true))

            client
            .get(`/search?q=${query}&radius=25&latitude=${latitude}&longitude=${longitude}`)
            .then((response)=>{
    
                let data = response.data.data;

                dispatch(search(query, data))

            });

        },
        onQuery: (query)=>{
            
            dispatch(setSearchQuery(query))

        }
    }

}

export default connect(mapStateToProps,mapDispatchToProps)(injectIntl(SearchResults));