import React, { Component } from "react";
import clientX from "../../../utils/clientX";
import { Link } from "react-router-dom";
import couponImg from "../../../assets/coupon.svg";
import { FormattedMessage } from "react-intl";

export default class Coupon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active_coupons: null,
      total_points: null,
      pending_points: null,
      coupon_progression_percent: null,
      total_points_per_coupon: null,
      history: []
    };
  }

  componentDidMount() {
    clientX.get("v1/coupons").then(response => {
      let data = response.data.data;

      this.setState({
        active_coupons: data.active_coupons,
        total_points: data.total_points,
        pending_points: data.pending_points,
        coupon_progression_percent: data.coupon_progression_percent,
        total_points_per_coupon: data.total_points_per_coupon
      });
    });

    clientX.get("v1/coupons/history").then(response => {
      this.setState({
        history: response.data.data
      });
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
          <div className="col-12">
            <div className="row align-items-center">
              <div className="col-12">
                <small>
                  <Link to="/">
                    <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    />
                  </Link>{" "}
                  /{" "}
                  <strong>
                    <FormattedMessage
                      id="coupons.coupons"
                      defaultMessage="Coupons"
                    />
                  </strong>
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-md-4">
          <div className="col-12 col-md-6 coupon-wrapper">
            <div>
              <img src={couponImg} alt="coupon" />
              <h3>{this.state.active_coupons}</h3>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <h3 className="mt-4 mt-md-0">
              <strong>
                <FormattedMessage
                  id="coupons.coupons-for-next-draw"
                  defaultMessage="Coupons for Next Draw"
                />
              </strong>
            </h3>
            <div className="card">
              <div className="card-body">
                <h1>
                  <strong>₹ {this.state.total_points} WRPs</strong>
                </h1>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: `${this.state.coupon_progression_percent}%`
                    }}
                  ></div>
                </div>
                <p className="mt-2">
                  {this.state.pending_points}{" "}
                  <FormattedMessage
                    id="coupons.provisional-wrps-is-in-process"
                    defaultMessage="Provisional WRPs is in process"
                  />
                </p>
                <Link to="/wrp-summary">
                  <span style={{ color: "#1A94E8" }}>
                    <strong>
                      <FormattedMessage
                        id="coupons.view_summary"
                        defaultMessage="View Summary"
                      />
                    </strong>
                  </span>
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Link to="/wrp-details">
                  <button className="btn btn-primary pl-4 pr-4 float-right mt-3 home-btn">
                    <FormattedMessage id="wrp.info" defaultMessage="WRP INFO" />
                  </button>
                </Link>
              </div>
            </div>

            <h3 className="mt-4 mt-md-5">
              <strong>
                <FormattedMessage
                  id="coupons.coupon-history"
                  defaultMessage="Coupon History"
                />
              </strong>
            </h3>
            <div className="card mb-4">
              <div className="card-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col" style={{ borderTop: "none" }}>
                        <FormattedMessage
                          id="coupons.month"
                          defaultMessage="Month"
                        />
                      </th>
                      <th scope="col" style={{ borderTop: "none" }}>
                      <FormattedMessage
                          id="coupons.coupon"
                          defaultMessage="Coupon"
                        />
                        
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.history.map((item, index) => {
                      return (
                        <tr key={`history${index}`}>
                          <td>{item.month}</td>
                          <th scope="row">{item.coupon_count}</th>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
