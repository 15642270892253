import React, { Component } from 'react';
import {FormattedMessage} from 'react-intl';

class Footer extends Component{

    render(){

        return(
            <React.Fragment>
                <div className="container-fluid container-fluid--footer">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-md-3">
                                    <h4 className="font-proxima-nova-bold color-accent mb-3">
                                        <FormattedMessage 
                                            id="footer.principal-office"
                                            defaultMessage="Principal Office"
                                            description="Principal Office"
                                            values={{ what: 'react-intl' }}
                                        />
                                    </h4>
                                    <p style={{width:'200px'}}>
                                        <FormattedMessage 
                                            id="footer.principal-office-address"
                                            defaultMessage="Sahara India Sadan, 2A, Shakespeare Sarani, Kolkata – 700 071"
                                            description="Sahara India Sadan, 2A, Shakespeare Sarani, Kolkata – 700 071"
                                            values={{ what: 'react-intl' }} />
                                    </p>
                                </div>
                                <div className="col-md-3">
                                    <h4 className="font-proxima-nova-bold color-accent mb-3">
                                        <FormattedMessage 
                                            id="footer.operating-office"
                                            defaultMessage="Operating Office"
                                            description="Operating Office"
                                            values={{ what: 'react-intl' }} />
                                    </h4>
                                    <p style={{width:'200px'}}>
                                        <FormattedMessage 
                                            id="footer.operating-office-address"
                                            defaultMessage="Sahara India Bhawan, 1, Kapoorthala Complex, Aliganj, Lucknow – 226 024"
                                            description="Sahara India Bhawan, 1, Kapoorthala Complex, Aliganj, Lucknow – 226 024"
                                            values={{ what: 'react-intl' }} />
                                    </p>
                                </div>
                                <div className="col-md-3">
                                    <h4 className="font-proxima-nova-bold color-accent mb-3">
                                        <FormattedMessage 
                                            id="footer.quick-link"
                                            defaultMessage="Quick Links"
                                            description="Quick Links"
                                            values={{ what: 'react-intl' }} />
                                    </h4>
                                    <p>
                                        <a href="https://info.winds.co.in/terms-conditions/" rel="noopener noreferrer" target="_blank">
                                            <FormattedMessage 
                                            id="footer.terms-and-conditions"
                                            defaultMessage="Terms & Conditions"
                                            description="Terms & Conditions"
                                            values={{ what: 'react-intl' }} />
                                        </a>
                                    </p>
                                    <p>
                                        <a href="https://info.winds.co.in/privacy-policy/" rel="noopener noreferrer" target="_blank">
                                            <FormattedMessage 
                                            id="footer.privacy-policy"
                                            defaultMessage="Privacy Policy"
                                            description="Privacy Policy"
                                            values={{ what: 'react-intl' }} />
                                        </a>
                                    </p>
                                    <p>
                                        <a href="https://info.winds.co.in/credits/" rel="noopener noreferrer" target="_blank">
                                            <FormattedMessage 
                                            id="footer.credits"
                                            defaultMessage="Credits"
                                            description="Credits"
                                            values={{ what: 'react-intl' }} />
                                        </a>
                                    </p>
                                    <p>
                                        <a href="https://www.windseworld.com/faq.html" rel="noopener noreferrer" target="_blank">
                                            <FormattedMessage 
                                            id="footer.faq"
                                            defaultMessage="FAQ"
                                            description="FAQ"
                                            values={{ what: 'react-intl' }} />
                                        </a>
                                    </p>
                                </div>
                                <div className="col-md-3">
                                    <h4 className="font-proxima-nova-bold color-accent mb-3">
                                        <FormattedMessage 
                                            id="footer.subscribe"
                                            defaultMessage="Subscribe"
                                            description="Subscribe"
                                            values={{ what: 'react-intl' }} />
                                    </h4>
                                    <p>
                                        <span>
                                            <FormattedMessage 
                                                    id="footer.email"
                                                    defaultMessage="Email:"
                                                    description="Email:"
                                                    values={{ what: 'react-intl' }} />
                                        </span> 
                                        <span> support@winds.co.in</span>
                                    </p>
                                    <p className="mb-0">
                                        <span>
                                            <FormattedMessage 
                                                    id="footer.toll-free-number"
                                                    defaultMessage="Toll Free Number:"
                                                    description="Toll Free Number:"
                                                    values={{ what: 'react-intl' }} />
                                        </span>
                                        <span> 1800 103 1032</span>
                                    </p>
                                    <p>
                                        <FormattedMessage 
                                        id="footer.from-9am-to-9pm"
                                        defaultMessage="From 9am to 9pm"
                                        description="From 9am to 9pm"
                                        values={{ what: 'react-intl' }} />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid container-fluid--sub-footer">
                    <div className="row">
                        <div className="col-12 text-center">
                            <small>
                                <FormattedMessage 
                                            id="footer.copyright"
                                            defaultMessage="Copyright 2018 WINDS E WORLD"
                                            description="Copyright 2018 WINDS E WORLD"
                                            values={{ what: 'react-intl' }} />
                            </small>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );

    }

}

export default Footer;