import React, { Component } from 'react';
import uploadIcon from '../assets/menu_icons/bill-upload.svg';
import { Link } from "react-router-dom";
import downCaret from '../assets/down-caret-blue.svg';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';


export default class Upload extends Component{

    constructor(props){

        super(props);

        this.state= {
            showDropdown:false
        }

    }

    handleClick = () => {

        this.setState({
            showDropdown: !this.state.showDropdown
        });

    }

    render(){

        // return(
        //     <li className="nav-item text-center" onClick={this.handleClick}>
        //         <img src={uploadIcon} alt="bill upload" />
        //         <span className="nav-text d-none d-md-block"><span><FormattedMessage id="navbar.bill-upload" defaultMessage="Bill Upload" /> </span></span>
        //     </li>
        // );
        return(
            <li className="nav-item text-center" onClick={this.handleClick}>
                <img src={uploadIcon} alt="Login" />
                <span className="nav-text d-none d-md-block"><span><FormattedMessage id="navbar.bill-upload" defaultMessage="Bill Upload" /> </span><img src={downCaret} style={{display:'inline', height: 'auto'}} alt="down caret" /></span>
                <div className={classNames('dropdown-menu', 'dropdown-menu-right', {'show':this.state.showDropdown})}  onMouseLeave={()=>{this.setState({showDropdown: false})}}>
                    <Link className="dropdown-item" to="/upload-bill/shopping-site">
                        <FormattedMessage
                            id="home.shopping-sites"
                            defaultMessage="Shopping Site"
                        />
                    </Link>
                    <Link className="dropdown-item" to="/upload-bill/nearby-partner">
                        <FormattedMessage 
                            id="home.nearby-partners"
                            defaultMessage="Nearby Partner"
                        />
                    </Link>
                </div>
            </li>
        );
    }
}