const INITIAL_STATE = {
    latitude:null,
    longitude:null,
    formatted_address:null
};

function locationReducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        case 'SET_LOCATION':
            return {
                ...state,
                latitude:action.latitude,
                longitude:action.longitude     
            };
        case 'SET_FORMATTED_ADDRESS':
            return {
                ...state,
                formatted_address:action.formatted_address            
            };
        default : 
            return state;
    }
}
  
export default locationReducer;