import { toast } from "react-toastify";

export const getRandomNumber = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min + 1;
};

export const getQueryParam = (searchedQuery, key) => {
  const query = new URLSearchParams(searchedQuery);
  return query.get(key);
};

export const showToastMessage = (type, message) => {
  toast[type](message, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  });
};

export const reverseDateFormat = value => {
  if (value) {
  return value
  .split("-")
  .reverse()
  .join("-"); 
  }
};

export const replaceAt = (array, index, value) => {
  const ret = array.slice(0);
  ret[index] = value;
  return ret;
}
export const createCookie = (name,value,days) => {
  var expires;
  if (days) {
    var date = new Date();
    date.setTime(date.getTime()+(days*24*60*60*1000));
     expires = "; expires="+date.toGMTString();
  }
  else  expires = "";
  document.cookie = name+"="+value+expires;
  // document.cookie = name+"="+value+expires+"; path=/";
}
export const readCookie = (name) => {
	var nameEQ = name + "=";
	var ca = document.cookie.split(';');
	for(var i=0;i < ca.length;i++) {
		var c = ca[i];
		while (c.charAt(0) === ' ') c = c.substring(1,c.length);
		if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
	}
	return null;
}

export const currentlySelectedLanguage = () => {
const language = readCookie('windsEWorldAppLanguageCode') || "en";
return language;
}

export const shuffle = (o) => {
  for (var j, x, i = o.length; i; j = parseInt(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x);
  return o;
};