import React, { Component } from 'react';
import { Link } from "react-router-dom";

export default class BillPayCarousel extends Component{
    
    render(){
       return(
            this.props.services.map((item,index)=>{
                return(
                    <div className="bill-pay-service-icon mb-3" key={"service-"+index}>
                        <Link to={`/bill-pay/${item.id}`}>
                            <div style={{textAlign:"center"}}>
                                <img src={item.app_image_url} className="bill-pay-service-icon-img mb-1 mb-md-2" alt={item.name}/>
                                <p className="bill-pay-service-icon-label mb-0" style={{textAlign:'center',color:'#666'}}>
                                    {item.name}
                                </p>
                            </div>
                        </Link>
                    </div>
                )
            })
       );
    }
}