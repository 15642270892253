import React, { Component } from 'react';
import MultiItemCarousel from '../../../components/MultiItemCarousel';
import { Link } from "react-router-dom";

export default class BillPayCarousel extends Component{

    render(){
       return(
            <MultiItemCarousel>
                {
                this.props.services.map((item,index)=>{
                    return(
                    <div key={"billpay-"+index}>
                        <div style={{padding:'15px'}}>
                        <Link to={`/bill-pay/${item.id}`}>
                            <div className="card mb-4 card--carousel-item card--carousel-item--billpay">
                                <div className="card-body">
                                    <img src={item.web_image_url} alt={item.name} />
                                    <p className="text-center mt-4 mb-0 font-weight-bold">{item.name}</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    </div>
                    )
                })
                }
            </MultiItemCarousel>
       );
    }
    
}