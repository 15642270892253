import React, { Component } from "react";
import { connect } from "react-redux";
import client from "../utils/client";
import { search, isSearching,resetSearchResult } from "../modules/search/actions";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";

class SearchBox extends Component {
  handleKeyDown = e => {
    if (e.key === "Enter" && e.target.value.length > 2) {
      this.props.onSearch(
        e.target.value,
        this.props.latitude,
        this.props.longitude
      );
    }
    if (
      (e.key === "Backspace" ||
      e.key === "Delete") &&
      e.target.value.length === 0
    ) {
     this.props.resetSearhResult();
    }
  };

  render() {
    if ("search" !== window.location.pathname) {
      return (
        <input
          type="text"
          className="form-control"
          onClick={() => {
            this.props.history.push("/search");
          }}
          onKeyUp={this.handleKeyDown}
          style={{ border: "1px solid #efeff4", borderRadius: "0 8px 8px 0" }}
          placeholder={this.props.intl.formatMessage({
            id: "navbar.seach-box-placeholder"
          })}
        />
      );
    }

    return (
      <input
        type="text"
        className="form-control"
        onKeyDown={this.handleKeyDown}
        placeholder={this.props.intl.formatMessage({
          id: "navbar.seach-box-placeholder"
        })}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    query: state.search.query,
    latitude: state.location.latitude,
    longitude: state.location.longitude
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSearch: (query, latitude, longitude) => {
      dispatch(isSearching(true));

      client
        .get(
          `/search?q=${query}&radius=25&latitude=${latitude}&longitude=${longitude}`
        )
        .then(response => {
          let data = response.data.data;

          dispatch(search(query, data));
        });
    },
    resetSearhResult: () => {
        dispatch(resetSearchResult());
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withRouter(SearchBox)));
