
const INITIAL_STATE = {
  banner: [],
  affiliate: [],
  partner: [],
  voucher: [],
  billpay: [],
  offers: [],
  tags: [],
  isLoading: true
};



function homeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_HOME":
      return {
        ...state,
        banner: action.banner,
        affiliate: action.affiliate,
        partner: action.partner,
        voucher: action.voucher,
        billpay: action.billpay,
        offers: action.offers,
        tags: action.tags
      };
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.isLoading
      };
    case "SET_HOME_AFTER_TOGGLE_BOOKMARK":
      switch (action.listTypeToUpdate) {
        case "voucher":
          return {
            ...state,
            voucher: state.voucher.map(v =>
              v.id === action.id ? { ...v, bookmarked: !v.bookmarked } : v
            )
          };
        case "shoppingSites":
          return {
            ...state,
            affiliate: state.affiliate.map(a =>
              a.id === action.id ? { ...a, bookmarked: !a.bookmarked } : a
            )
          };
          case "offers":
            return {
              ...state,
              offers: state.offers.map(o =>
                o.id === action.id ? { ...o, bookmarked: !o.bookmarked } : o
              )
            };
        default:
          break;
      }
      return {
        ...state
      };
    default:
      return state;
  }
}

export default homeReducer;
