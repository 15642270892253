import React, { Component } from "react";
import ShoppingSitesCarousel from "../components/ShoppingSitesCarousel";
import NearbyPartnerCarousel from "../components/NearbyPartnerCarousel";
import VoucherCarousel from "../components/VoucherCarousel";
import OffersCarousel from "../components/OffersCarousel";
import clientX from "../../../utils/clientX";
import emptyStateimg from "../../../assets/search-results-empty-state.svg";
import { Link } from "react-router-dom";
import lodash from "lodash";
import { FormattedMessage } from "react-intl";

export default class Bookmarks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bookmarks: null,
      isLoading: false
    };
  }

  deleteBookmark = (id, url, itemToDeleteFromTheList) => {
    clientX
      .delete(url)
      .then(response => {
        this.setState(prevState => ({
          bookmarks: {
            ...prevState.bookmarks,
            [itemToDeleteFromTheList]: prevState.bookmarks[
              itemToDeleteFromTheList
            ].filter(bookmark => bookmark.id !== id)
          }
        }));
      })
      .catch(error => {});
  };

  componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      () => {
        clientX.get("v1/bookmarks").then(response => {
          this.setState({
            bookmarks: response.data.data,
            isLoading: false
          });
        });
      }
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row mt-3 mb-3">
            <div className="col-12">
              <small>
                <Link to="/">
                  <FormattedMessage
                    id="breadcrumbs.home"
                    defaultMessage="Home"
                  />
                </Link>{" "}
                /{" "}
                <strong>
                  <FormattedMessage
                    id="navbar.bookmarks"
                    defaultMessage="Bookmarks"
                  />
                </strong>
              </small>
            </div>
          </div>
        </div>

        {this.state.isLoading ? (
          <this.Shimmer />
        ) : null == this.state.bookmarks ||
          (this.state.bookmarks.affiliates.length === 0 &&
            this.state.bookmarks.offers.length === 0 &&
            this.state.bookmarks.partners.length === 0 &&
            this.state.bookmarks.vouchers.length === 0) ? (
          <this.EmptyState />
        ) : (
          <this.Bookmarks />
        )}
      </React.Fragment>
    );
  }

  Shimmer = props => {
    return (
      <React.Fragment>
        {lodash.range(4).map((index) => {
          return (
            <div className="container-fluid container-fluid--home-section" key={index}>
              <div className="row row--home-section-title">
                <div className="col-md-12">
                  <div className="heading shine"></div>
                </div>
              </div>
              <div className="row">
                <div className="col-4 col-md-3">
                  <div className="box shine"></div>
                </div>
                <div className="col-4 col-md-3">
                  <div className="box shine"></div>
                </div>
                <div className="col-4 col-md-3">
                  <div className="box shine"></div>
                </div>
                <div className="col-4 col-md-3 d-none d-md-block">
                  <div className="box shine"></div>
                </div>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  EmptyState = props => {
    return (
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-12 text-center">
            <img
              className="bookmarks-vector"
              src={emptyStateimg}
              alt="No Results"
            />
            <p className="mt-3">
              <strong>You don't have any bookmarks.</strong>
            </p>
          </div>
        </div>
      </div>
    );
  };

  Bookmarks = props => {
    return (
      <React.Fragment>
        {this.state.bookmarks.affiliates !== undefined &&
          this.state.bookmarks.affiliates.length > 0 && (
            <div className="container-fluid mt-3 mt-md-4">
              <div className="row align-items-center">
                <div className="col-12">
                  <h4 className="font-weight-bold">
                    <FormattedMessage
                      id="home.shopping-sites"
                      defaultMessage="Shopping Sites"
                    />
                  </h4>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <ShoppingSitesCarousel
                    affiliate={this.state.bookmarks.affiliates}
                    deleteBookmark={(id, url, itemToDeleteFromTheList) => {
                      this.deleteBookmark(id, url, itemToDeleteFromTheList);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        {this.state.bookmarks.partners !== undefined &&
          this.state.bookmarks.partners.length > 0 && (
            <div className="container-fluid mt-3 mt-md-4">
              <div className="row">
                <div className="col-12">
                  <h4 className="font-weight-bold">
                    <FormattedMessage
                      id="home.partners"
                      defaultMessage="Partners"
                    />
                  </h4>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <NearbyPartnerCarousel
                    partners={this.state.bookmarks.partners}
                    deleteBookmark={(id, url, itemToDeleteFromTheList) => {
                      this.deleteBookmark(id, url, itemToDeleteFromTheList);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        {this.state.bookmarks.vouchers !== undefined &&
          this.state.bookmarks.vouchers.length > 0 && (
            <div className="container-fluid mt-3 mt-md-4">
              <div className="row">
                <div className="col-12">
                  <h4 className="font-weight-bold">
                    <FormattedMessage
                      id="home.vouchers"
                      defaultMessage="Vouchers"
                    />
                  </h4>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <VoucherCarousel
                    vouchers={this.state.bookmarks.vouchers}
                    deleteBookmark={(id, url, itemToDeleteFromTheList) => {
                      this.deleteBookmark(id, url, itemToDeleteFromTheList);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        {this.state.bookmarks.offers !== undefined &&
          this.state.bookmarks.offers.length > 0 && (
            <div className="container-fluid mt-3 mt-md-4">
              <div className="row">
                <div className="col-12">
                  <h4 className="font-weight-bold">
                    <FormattedMessage
                      id="home.offers-near-you"
                      defaultMessage="Offers Near You"
                    />
                  </h4>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <OffersCarousel offers={this.state.bookmarks.offers} 
                  deleteBookmark={(id, url, itemToDeleteFromTheList) => {
                    this.deleteBookmark(id, url, itemToDeleteFromTheList);
                  }}/>
                </div>
              </div>
            </div>
          )}
      </React.Fragment>
    );
  };
}
