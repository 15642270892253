import React, { Component } from "react";
import { Link } from "react-router-dom";
import noRewardsImg from "../../../assets/rewards-home-img.svg";
import { FormattedMessage } from "react-intl";
import aboutBlue from "../../../assets/about-blue.svg";
import rewardCardImg from "../../../assets/rewards-card.svg";
import spinWheelCardImg from "../../../assets/spin-wheel.svg";
import rewardAmazon from "../../../assets/voucher-reveal.svg";
import RewardModel from "../components/Reward-model";
import Wheel from "../components/wheel";
const rewardsData = {
  success: true,
  data: [
    {
      id: 100,
      type: "scratch-card",
      image:
        "https://winds-staging.s3.ap-south-1.amazonaws.com/reward/ic_scratch_card_big_2%403x.png",
      used: true,
      unlocked: true,
      unlock_text: "Do first transaction and win Scratch card worth 100 WRP",
      won: true,
      description: "Win upto 10,000 WRP or upto 5,000 in vouchers",
      source: "Earned by referring users",
      reward: {
        winning_date: "1st Jan, 2020",
        type: "wrp",
        value: 1000,
        name: "WRP",
        display_name: "You have won WRP",
        image:
          "https://winds-staging.s3.ap-south-1.amazonaws.com/reward/ic_scratch_card_big_2%403x.png",
        terms: "some html text",
        processed: true,
        processed_at: "1st Jan, 2020 12:00 PM"
      },
      spin_wheel_winning_id: null,
      spin_wheel_options: []
    },
    {
      id: 101,
      type: "spin-wheel",
      image:
        "https://winds-staging.s3.ap-south-1.amazonaws.com/reward/ic_scratch_card_big_2%403x.png",
      used: true,
      unlocked: true,
      unlock_text: "Do first transaction and win Scratch card worth 100 WRP",
      won: true,
      description: "Win upto 10,000 WRP or upto 5,000 in vouchers",
      source: "Earned by referring users",
      reward: {
        winning_date: "1st Jan, 2020",
        type: "wrp",
        value: 1000,
        name: "WRP",
        display_name: "You have won WRP",
        image:
          "https://winds-staging.s3.ap-south-1.amazonaws.com/reward/ic_scratch_card_big_2%403x.png",
        terms: "some html text",
        processed: true,
        processed_at: "1st Jan, 2020 12:00 PM"
      },
      spin_wheel_winning_id: 2,
      spin_wheel_options: [
        {
          id: 1,
          name: "Better Luck Next Time",
          reward_item: false
        },
        {
          id: 2,
          name: "100 WRP",
          reward_item: true
        },
        {
          id: 3,
          name: "300 WRP",
          reward_item: true
        },
        {
          id: 4,
          name: "Better Luck Next Time",
          reward_item: false
        },
        {
          id: 5,
          name: "Amazon 100Rs Voucher",
          reward_item: true
        },
        {
          id: 6,
          name: "Try again next time",
          reward_item: false
        },
        {
          id: 7,
          name: "Flipkart 100Rs Voucher",
          reward_item: true
        },
        {
          id: 8,
          name: "Cash Reward Rs. 500",
          reward_item: true
        }
      ]
    }
  ],
  links: {
    first: "https://customer-reward-api.windsinc.in/v1/customer/cards?page=1",
    last: "https://customer-reward-api.windsinc.in/v1/customer/cards?page=1",
    prev: null,
    next: "https://customer-reward-api.windsinc.in/v1/customer/cards?page=1"
  },
  meta: {
    current_page: 1,
    from: 1,
    last_page: 1,
    path: "https://customer-reward-api.windsinc.in/v1/customer/cards",
    per_page: 10,
    to: 2,
    total: 2
  }
};

export default class Rewards extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rewards: [],
      isRewardModelOpen: false,
      isModelTransitionDone: false,
      isWheelModelOpen: false
    };
  }

  componentDidMount() {
    this.getUserRewards();
  }

  getUserRewards = () => {
    this.setState({ rewards: rewardsData.data }, () => {
    });
    // clientX.get("/customer/cards").then(response => {
    //     console.log(response);

    //   });
  };

  openRewardModel = () => {
    this.setState({
      isRewardModelOpen: true
    });
  };

  closeRewardModelHandler = () => {
    this.setState({
      isRewardModelOpen: false,
      isModelTransitionDone: false
    });
  };
  openWheelModel = () => {
    this.setState({
      isWheelModelOpen: true
    });
  };

  closeWheelModelHandler = () => {
    this.setState(
      {
        isWheelModelOpen: false
      }
    );
  };
  finished = () => {};

  modelEntered = () => {
    this.setState({ isModelTransitionDone: true });
  };

  render() {
    return (
      <div className="container-fluid font-proxima-nova">
        <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
          <div className="col-12">
            <div className="row align-items-center">
              <div className="col-12">
                <small>
                  <Link to="/">
                    <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    />
                  </Link>{" "}
                  /{" "}
                  <strong>
                    <FormattedMessage
                      id="rewards.rewards"
                      defaultMessage="Rewards"
                    />
                  </strong>
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12">
            <h2 className="font-proxima-nova-bold d-inline-block">
              <FormattedMessage
                id="home.rewards"
                defaultMessage="Your Rewards"
              />
            </h2>
            <button
              className="btn btn-outline d-inline-block"
              style={{ color: "#087CD8", marginTop: "-0.5rem" }}
              onClick={this.openWrpBenefitModel}
            >
              <img src={aboutBlue} alt="" className="mr-1" />{" "}
              <FormattedMessage id="general.info" defaultMessage="Info" />
            </button>
          </div>
        </div>
        {this.state.rewards.length === 0 ? (
          <React.Fragment>
            <div className="row">
              <div className="col-md-6 col-12">
                <h4 className="text-secondary align-middle">
                  No rewards yet. It is only a matter of time!
                </h4>
              </div>
            </div>
            <div className="row mt-3 mb-5 font-proxima-nova">
              <div className="col-12">
                <img
                  src={noRewardsImg}
                  alt="Your rewards Illustration."
                  className="w-50 d-block m-auto"
                />
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="row mt-3 mb-5 font-proxima-nova">
              {this.state.rewards.map((item, index) => {
                return (
                  <div className="col-md-3 col-sm-4 col-12" key={index}>
                    <div className="card-red rewards-card">
                      {item.type === "scratch-card" ? (
                        <React.Fragment>
                          <div
                            className="reward-icon"
                            onClick={this.openRewardModel}
                          >
                            <img
                              src={item.image}
                              alt="rewards icon."
                              className="d-block"
                            />
                            <h4 className="text-white position-absolute abs-center">
                              Scratch Here
                            </h4>
                          </div>
                          {this.state.isRewardModelOpen && (
                            <RewardModel
                              finished={this.finished}
                              reward={item}
                              modelEntered={this.modelEntered}
                              closeRewardModelHandler={
                                this.closeRewardModelHandler
                              }
                            >
                              <div className="rewards-card">
                                <div className="reward-icon">
                                  <h4>
                                    {" "}
                                    {item.reward.display_name}
                                    <br />
                                    {item.reward.value}
                                  </h4>
                                  <p className="text-secondary">
                                    {item.reward.winning_date}
                                  </p>
                                  <small className="text-primary">
                                    Claim the prize
                                  </small>
                                </div>
                              </div>
                            </RewardModel>
                          )}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <div
                            className="reward-icon"
                            onClick={this.openWheelModel}
                          >
                            <img
                              src={spinWheelCardImg}
                              alt="rewards icon."
                              className="d-block"
                            />
                            <h4 className="text-white position-absolute abs-center">
                              wheel game
                            </h4>
                          </div>

                          {this.state.isWheelModelOpen && (
                            <Wheel
                              item={item}
                              closeWheelModelHandler={
                                this.closeWheelModelHandler
                              }
                            />
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                );
              })}
              <div className="col-md-3 col-sm-4 col-12">
                <div className="rewards-card">
                  <div className="reveal-icon">
                    <img
                      src={rewardAmazon}
                      alt="rewards icon."
                      className="d-block"
                    />
                    <h4>You won Amazon voucher worth &#8377; 1000</h4>
                    <p className="text-secondary">13th Mar, 2020</p>
                    <small className="text-primary">Claim the prize</small>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}

        <div className="row mb-3">
          <div className="col-12">
            <h2 className="font-proxima-nova-bold d-inline-block">
              <FormattedMessage id="home.offers" defaultMessage="Offers" />
            </h2>
          </div>
        </div>

        <div className="row mt-3 mb-5 font-proxima-nova">
          <div className="col-md-3 col-sm-4 col-12">
            <div className="offer-card d-flex">
              <div className="card-red rewards-card">
                <div className="reward-icon">
                  <img
                    src={rewardCardImg}
                    alt="rewards icon."
                    className="d-block"
                  />
                </div>
              </div>
              <h4>Get a scratch card worth upto &#8377; 5000!</h4>
              <p className="text-secondary">
                Pay your utility bills for 3 different services on WINDS &amp;
                earn upto <br />
                &#8377; 5000 worth vouchers!
              </p>
              <small className="text-primary">
                {" "}
                <Link to="/rewards/offer-detail">View offer details</Link>
              </small>
            </div>
          </div>
          <div className="col-md-3 col-sm-4 col-12">
            <div className="offer-card d-flex">
              <div className="card-brown rewards-card">
                <div className="reward-icon">
                  <img
                    src={rewardCardImg}
                    alt="rewards icon."
                    className="d-block"
                  />
                </div>
              </div>
              <h4>Get a scratch card worth upto &#8377; 5000!</h4>
              <p className="text-secondary">
                Pay your utility bills for 3 different services on WINDS &amp;
                earn upto <br />
                &#8377; 5000 worth vouchers!
              </p>
              <small className="text-primary">View offer details</small>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
