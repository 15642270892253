import { combineReducers } from 'redux';
import searchReducer from '../modules/search/reducers';
import locationReducer from '../reducers/location';
import homeReducer from '../modules/home/reducers';
import shoppingSitesReducer from '../modules/shopping_sites/reducers';
import voucherReducers from '../modules/vouchers/reducers';
import partnerReducer from '../modules/partners/reducers/partner';
import offerReducer from '../modules/partners/reducers/offer';

const rootReducer = combineReducers({
    home: homeReducer,
    location: locationReducer,
    search: searchReducer,
    shoppingSite: shoppingSitesReducer,
    voucher: voucherReducers,
    partner: partnerReducer,
    offer: offerReducer
});

export default rootReducer;