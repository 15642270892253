import React, { Component } from 'react';
import ticketIcon from '../assets/menu_icons/wrp-line-icon.svg';
import notificationIcon from '../assets/menu_icons/notifications.svg';
import orderIcon from '../assets/menu_icons/orders.svg';
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import SearchBox from '../components/SearchBox';
import LocationAddon from '../components/LocationAddOn';
import LanguageSwitcher from './LanguageSwitcher';
import {FormattedMessage} from 'react-intl';
import Avatar from './Avatar';
import Upload from './Upload';
import aboutIcon from '../assets/about.svg';
//import rewardsIcon from '../assets/menu_icons/rewards-icon.svg';
import logoIcon from '../assets/logo-icon.png';
import downloadAppIcon from '../assets/ic_download.svg';
import searchIcon from '../assets/ic_search.svg';

class Navbar extends Component{

    render(){
        return(
            <React.Fragment>
                <div className="container-fluid" style={{backgroundColor:'#222A73',boxShadow: '0 0 20px 0 rgba(0,0,0,0.25)'}}>
                    <div className="row font-proxima-nova align-items-center">
                        <div className="col-9 text-white d-md-none">
                            <LocationAddon style={{lineHeight:'30px'}}/>
                        </div>
                        <div className="col-3 offset-md-4 col-md-8 text-right text-white d-flex justify-content-end align-items-center">
                            <a className="nav-link d-none d-md-block" target="_blank" rel="noopener noreferrer" href="https://www.windseworld.com">
                                <img src={aboutIcon} alt="about" className="mr-1" style={{height:'12px', color: '#fff'}} />
                                <span className="mr-3" style={{fontSize:'13px'}}>
                                    <FormattedMessage 
                                        id="navbar.about"
                                        defaultMessage="About"
                                    />
                                </span>
                            </a>
                            <a className="nav-link" href="https://qrco.de/winds-app" target="_blank" rel="noopener noreferrer">
                                <img src={downloadAppIcon} className="mr-1" style={{height:'12px'}} alt="download apps" />
                                <span className="mr-4 d-none d-md-inline" style={{fontSize:'13px',lineHeight:'30px'}}>
                                    <FormattedMessage 
                                        id="navbar.download-app"
                                        defaultMessage="Download App"
                                        description="Download App"
                                        values={{ what: 'react-intl' }}
                                    />
                                </span>
                            </a>
                            <LanguageSwitcher />
                        </div>
                    </div>
                </div>
                <div className="container-fluid font-proxima-nova ">
                    <nav className="navbar navbar-expand-lg row">

                        <div className="col-md-1 col-2">
                            <Link className="navbar-brand" to="/">
                                <img src={logoIcon} alt="Winds" />
                            </Link>
                        </div>

                        <div className="col-md-4 d-none d-md-block">
                            <div className="input-group">
                                <div className="input-group-prepend" style={{cursor:'pointer', backgroundColor: '#EFEFF4',borderRadius: '8px 0 0 8px'}}>
                                    <span className="input-group-text" style={{border:'1px solid #efeff4',borderRadius: '8px'}}>
                                        <LocationAddon />  
                                    </span>
                                </div>
                                <SearchBox />
                            </div>
                        </div>

                        <div className="col-md-7 col-8">
                            <ul className="navbar-nav ml-auto flex-row justify-content-between">
                                <li className="nav-item text-center d-md-none">
                                    <NavLink className="nav-link" to="/search" activeClassName='is-active'>
                                        <img src={searchIcon} alt="Coupon"/>
                                    </NavLink>
                                </li>
                                <li className="nav-item text-center">
                                    <NavLink className="nav-link" to="/coupons" activeClassName='is-active'>
                                        <img src={ticketIcon} alt="Coupon" className="wrp-icon"/>
                                        <span className="d-none d-md-block">
                                            <FormattedMessage 
                                                id="navbar.coupons"
                                                defaultMessage="Coupons"
                                                description="Coupons"
                                                values={{ what: 'react-intl' }} />
                                        </span>
                                    </NavLink>
                                </li>
                                <li className="nav-item text-center">
                                    <NavLink className="nav-link" to="/orders" activeClassName='is-active'>
                                        <img src={orderIcon} alt="Orders"/>
                                        <span className="d-none d-md-block">
                                            <FormattedMessage 
                                                        id="navbar.orders"
                                                        defaultMessage="Orders"
                                                        description="Orders"
                                                        values={{ what: 'react-intl' }} />
                                        </span>
                                    </NavLink>
                                </li>
                                <Upload />
                                <li className="nav-item text-center">
                                    <NavLink className="nav-link" to="/notifications" activeClassName='is-active'>
                                        <img src={notificationIcon} alt="Notifications"/>
                                        <span className="d-none d-md-block">
                                            <FormattedMessage 
                                                        id="navbar.notifications"
                                                        defaultMessage="Notifications"
                                                        description="Notifications"
                                                        values={{ what: 'react-intl' }} />
                                        </span>
                                    </NavLink>
                                </li>
                                <Avatar />
                            </ul>
                        </div>

                        </nav>
                </div>
            </React.Fragment>
        );
    }
}

export default Navbar;