import React, { Component } from "react";
import MultiItemCarousel from "../../../components/MultiItemCarousel";
import { resetCategory } from "../../partners/actions/partner";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

class NearbyPartnerCarousel extends Component {
 
  render() {
    return (
      <MultiItemCarousel>
        {this.props.partners.map((item, index) => {
          return (
            <div key={"partner-" + index}>
              <div style={{ padding: "15px" }}>
                <div
                  className="card card--carousel-item card--carousel-item--nearby-partner"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.props.onSelectCategory(item.id);
                    this.props.history.push("/nearby-partners");
                  }}
                >
                  <div className="card-body">
                   
                    <img
                      style={{ width: "100%" }}
                      src={item.web_image_url}
                      alt={item.name}
                    />
                    <div className="category-label">
                      <span>{item.name}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </MultiItemCarousel>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    onSelectCategory: id => {
      dispatch(resetCategory(id));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NearbyPartnerCarousel));
