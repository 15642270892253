import React, { Component } from "react";
import { Link } from "react-router-dom";
import clientX from "../../../utils/clientX";
import { FormattedMessage, injectIntl } from "react-intl";
import editIcon from "../../../assets/edit-icon.svg";
import Modal from "react-bootstrap/Modal";
import classNames from "classnames";
import update from "immutability-helper";
import InlineLoader from "../../../components/InlineLoader";
import { ToastContainer, toast } from "react-toastify";

const otpRegex = RegExp("^[1-9][0-9]{5}$");
 class ProfileDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: null,
      isModelOpen: false,
      isEmailModelOpen: false,
      isMobileModelOpen: false,
      emailModel: {
        email: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false
        },
        otp: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false
        },
        isFormValid: false
      },
      mobileModel: {
        mobile: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false
        },
        otp: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false
        },
        isFormValid: false
      },
      isLoading: false,
      showOtpField: false,
      showEmailOtpField: false,
      editedProfileData: {
        name: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false
        },
        gender: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false,
          options: [
            { id: "m", name: "Male" },
            { id: "f", name: "Female" }
          ]
        },
        dob: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false
        },
        isFormValid: false
      }
    };
  }
  verifyOtpForEmail = (email, otp) => {
    this.setState({ isLoading: true });
    clientX
      .post(`/email/change`, { email: email, otp: otp })
      .then(response => {
        if (response.data.success) {
          toast.success("OTP Verified", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
          this.setState({
            profile: response.data.data,
            isLoading: false,
            isEmailModelOpen: false
          });
        }
      })
      .catch(error => {
        this.setState({ isLoading: false });
        toast.error(error.response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      });
  };
  verifyOtp = (mobileNumber, otp) => {
    this.setState({ isLoading: true });
    clientX
      .post(`/mobile/change`, { mobile: mobileNumber, otp: otp })
      .then(response => {
        if (response.data.success) {
          this.setState({ isLoading: false });
          toast.success("Mobile Verified", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
          this.setState({
            profile: response.data.data,
            isLoading: false,
            isMobileModelOpen: false
          });
        }
      })
      .catch(error => {
        this.setState({ isLoading: false });
        toast.error(error.response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      });
  };

  verifyMobile = mobileNumber => {
    this.setState({ isLoading: true });
    clientX
      .post(`/mobile/verification-otp`, { mobile: mobileNumber })
      .then(response => {
        if (response.data.success) {
          toast.success("OTP Sent", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        }
        this.setState({ showOtpField: true, isLoading: false });
      })
      .catch(error => {
        this.setState({ isLoading: false });
        toast.error(error.response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      });
  };

  verifyEmail = email => {
    this.setState({ isLoading: true });
    clientX
      .post(`/email/verification-otp`, { email: email })
      .then(response => {
        if (response.data.success) {
          toast.success("OTP Sent", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        }
        this.setState({ showEmailOtpField: true, isLoading: false });
      })
      .catch(error => {
        this.setState({ isLoading: false });
        toast.error(error.response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      });
  };
  componentDidMount() {
    clientX.get("v1/profile").then(response => {
      this.setState({
        profile: response.data.data
      });
    });
  }

  editEmail = () => {
    const { email } = { ...this.state.profile };
    const newData = update(this.state, {
      isEmailModelOpen: { $set: true },
      showOtpField: { $set: false },
      emailModel: {
        isFormValid: { $set: false },
        email: {
          value: { $set: email || "" },
          isValid: { $set: false }
        }
      }
    });
    this.setState(newData);
  };

  editMobile = () => {
    const { mobile } = { ...this.state.profile };
    const newData = update(this.state, {
      isMobileModelOpen: { $set: true },
      showOtpField: { $set: false },
      mobileModel: {
        isFormValid: { $set: false },
        mobile: {
          value: { $set: mobile || "" },
          isValid: { $set: mobile ? true : false },
          isPristine: { $set: false }
        }
      }
    });
    this.setState(newData);
  };

  editProfile = () => {
    const { name, gender, dob } = { ...this.state.profile };
    const newData = update(this.state, {
      isModelOpen: { $set: true },
      showOtpField: { $set: false },
      editedProfileData: {
        isFormValid: { $set: true },
        name: {
          value: { $set: name || "" },
          isValid: { $set: name ? true : false }
        },
        gender: {
          value: { $set: gender ? gender.charAt(0).toLowerCase() : "" },
          isValid: { $set: gender ? true : false }
        },
        dob: {
          value: { $set: dob || "" },
          isValid: { $set: dob ? true : false }
        }
      }
    });
    this.setState(newData);
  };
  handleClose = () => {
    this.setState({ ...this.state, isModelOpen: false });
  };
  closeMobileModelHandler = () => {
    this.setState({ ...this.state, isMobileModelOpen: false });
  };

  closeEmailModelHandler = () => {
    this.setState({ ...this.state, isEmailModelOpen: false });
  };

  checkMobileDialogValidity(fieldName, value) {
    const { mobileModel } = { ...this.state };
    switch (fieldName) {
      case "mobile":
        mobileModel.mobile.isValid = null !== value.match(/^[6-9]\d{9}$/);

        mobileModel.mobile.error = mobileModel.mobile.isValid
          ? ""
          : "Mobile is invalid";
        break;
      case "otp":
        mobileModel.otp.isValid = null !== value.match(otpRegex);
        mobileModel.otp.error = mobileModel.otp.isValid ? "" : "OTP is invalid";
        break;
      default:
        break;
    }

    const newData = update(this.state, {
      mobileModel: {
        isFormValid: { $set: this.areMobileDilogFieldsValid() }
      }
    });

    this.setState(newData);
  }

  checkEmailDialogValidity(fieldName, value) {
    const { emailModel } = { ...this.state };
    switch (fieldName) {
      case "email":
        emailModel.email.isValid =
          null !== value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        emailModel.email.error = emailModel.email.isValid
          ? ""
          : "email is invalid";
        break;
      case "otp":
        emailModel.otp.isValid = null !== value.match(otpRegex);
        emailModel.otp.error = emailModel.otp.isValid ? "" : "OTP is invalid";
        break;
      default:
        break;
    }

    const newData = update(this.state, {
      emailModel: {
        isFormValid: { $set: this.areEmailDilogFieldsValid() }
      }
    });

    this.setState(newData);
  }

  EditProfileFormDialog = props => {
    const { name, gender, dob, isFormValid } = {
      ...props.editedProfileData
    };
    return (
      <React.Fragment>
        <Modal show={props.isModelOpen} onHide={props.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Profile Details</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <form noValidate>
              <div className="form-group">
                <label>
                  <FormattedMessage
                    id="profile.full-name"
                    defaultMessage="Full Name"
                  />
                </label>
                <input
                  type="text"
                  name="name"
                  autoComplete="off"
                  onChange={props.inputChangeHandler}
                  value={name.value}
                  className={classNames("form-control", {
                    "is-valid": name.isValid,
                    "is-invalid": name.isPristine && !name.isValid
                  })}
                />
              </div>

              <div className="form-group">
                <label>
                  <FormattedMessage
                    id="profile.gender"
                    defaultMessage="Select Gender"
                  />
                </label>
                <select
                  className="form-control"
                  name="gender"
                  autoComplete="off"
                  onChange={props.inputChangeHandler}
                  value={gender.value}
                >
                 {
                   !gender.value && <option value="">
                   {props.intl.formatMessage({ id: "general.choose" })}
                 </option>
                 } 

                  {gender.options.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="form-group">
                <label>
                  <FormattedMessage id="profile.dob" defaultMessage="dob" />
                </label>
                <input
                  type="date"
                  name="dob"
                  autoComplete="off"
                  onChange={props.inputChangeHandler}
                  value={dob.value}
                  className={classNames("form-control", {
                    "is-valid": dob.isValid,
                    "is-invalid": dob.isPristine && !dob.isValid
                  })}
                />
              </div>
            </form>
          </Modal.Body>

          <Modal.Footer>
            {props.isLoading && props.isModelOpen && <InlineLoader />}
            <button
              className="btn btn-primary"
              onClick={props.submitHandler}
              disabled={!isFormValid}
            >
              <FormattedMessage
                id="profile.save-profile"
                defaultMessage="Save Profile"
              />
            </button>
          </Modal.Footer>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
        </Modal>
      </React.Fragment>
    );
  };

  areMobileDilogFieldsValid() {
    const { mobile, otp } = this.state.mobileModel;
    return mobile.value && mobile.isValid && otp.value && otp.isValid;
  }
  areEmailDilogFieldsValid() {
    const { email, otp } = this.state.emailModel;
    return email.value && email.isValid && otp.value && otp.isValid;
  }
  checkValidity(fieldName, value) {
    const { editedProfileData } = { ...this.state };

    switch (fieldName) {
      case "name":
        editedProfileData.name.isValid = value.trim().length > 0;
        editedProfileData.name.error = editedProfileData.name.isValid
          ? ""
          : "Name is required";
        break;

      case "gender":
        editedProfileData.gender.isValid = value.trim().length > 0;
        editedProfileData.gender.error = editedProfileData.gender.isValid
          ? ""
          : "Gender is invalid";
        break;
      case "dob":
        editedProfileData.dob.isValid =
          null !== value.match(/^\d{4}-\d{2}-\d{2}$/);
        editedProfileData.dob.error = editedProfileData.dob.isValid
          ? ""
          : "dob is invalid";
          break;

      default:
        break;
    }

    const newData = update(this.state, {
      editedProfileData: {
        isFormValid: { $set: this.areAllFieldsValid() }
      }
    });

    this.setState(newData);
  }

  areAllFieldsValid() {
    const { name, gender, dob } = this.state.editedProfileData;
    return [name, gender, dob].every(x => x.value && x.isValid);
  }

  inputChangeHandler = e => {
    const { name, value } = e.target;
    const newData = update(this.state, {
      editedProfileData: {
        [name]: {
          value: { $set: value },
          isPristine: { $set: true }
        }
      }
    });

    this.setState(newData, () => {
      this.checkValidity(name, value);
    });
  };

  mobileDialoginputChangeHandler = e => {
    const { name, value } = e.target;
    const newData = update(this.state, {
      mobileModel: {
        [name]: {
          value: { $set: value },
          isPristine: { $set: true }
        }
      }
    });

    this.setState(newData, () => {
      this.checkMobileDialogValidity(name, value);
    });
  };

  emailDialoginputChangeHandler = e => {
    const { name, value } = e.target;
    const newData = update(this.state, {
      emailModel: {
        [name]: {
          value: { $set: value },
          isPristine: { $set: true }
        }
      }
    });

    this.setState(newData, () => {
      this.checkEmailDialogValidity(name, value);
    });
  };

  submitHandler = () => {
    this.setState({ isLoading: true });
    const { name, gender, dob } = this.state.editedProfileData;
    const formData = {
      name: name.value,
      gender: gender.value,
      dob: dob.value
        .split("-")
        .reverse()
        .join("-")
    };

    clientX
      .put(`profile`, formData)
      .then(response => {
        this.setState({
          isLoading: false,
          profile: response.data.data,
          isModelOpen: false
        });
      })
      .catch(error => {
        this.setState({ isLoading: false });
        toast.error(error.response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      });
  };

  EditMobileFormDialog = props => {
    const { otp, mobile, isFormValid } = {
      ...props.mobileModel
    };

    return (
      <React.Fragment>
        <Modal
          show={props.isMobileModelOpen}
          onHide={props.closeMobileModelHandler}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Profile Details</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <form noValidate>
              <div className="form-group">
                <label>
                  <FormattedMessage
                    id="profile.mobile"
                    defaultMessage="Mobile Number"
                  />
                </label>
                <input
                  type="text"
                  name="mobile"
                  autoComplete="off"
                  onChange={props.mobileDialoginputChangeHandler}
                  value={mobile.value}
                  className={classNames("form-control", {
                    "is-valid": mobile.isValid,
                    "is-invalid": mobile.isPristine && !mobile.isValid
                  })}
                />
                {mobile.isPristine && mobile.isValid && (
                  <span
                    style={{
                      height: "15px",
                      width: "82px",
                      color: "#0078D7",
                      fontSize: "15px",
                      fontWeight: "600",
                      lineHeight: "15px",
                      cursor: "pointer",
                      right: "30px",
                      top: "61px",
                      position: "absolute"
                    }}
                    onClick={() => props.verifyMobile(mobile.value)}
                  >
                    Send OTP
                  </span>
                )}
              </div>
              {props.showOtpField && (
                <div className="form-group">
                  <label>
                    <FormattedMessage id="profile.otp" defaultMessage="OTP" />
                  </label>
                  <input
                    type="text"
                    name="otp"
                    autoComplete="off"
                    onChange={props.mobileDialoginputChangeHandler}
                    value={otp.value}
                    className={classNames("form-control", {
                      "is-valid": otp.isValid,
                      "is-invalid": otp.isPristine && !otp.isValid
                    })}
                  />
                </div>
              )}
            </form>
          </Modal.Body>

          <Modal.Footer>
            {props.isLoading && props.isMobileModelOpen && <InlineLoader />}
            <button
              className="btn btn-primary"
              onClick={() => props.verifyOtp(mobile.value, otp.value)}
              disabled={!isFormValid}
            >
              <FormattedMessage
                id="profile.save-profile"
                defaultMessage="Save Profile"
              />
            </button>
          </Modal.Footer>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
        </Modal>
      </React.Fragment>
    );
  };

  EditEmailFormDialog = props => {
    const { otp, email, isFormValid } = {
      ...props.emailModel
    };
    return (
      <React.Fragment>
        <Modal
          show={props.isEmailModelOpen}
          onHide={props.closeEmailModelHandler}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Profile Details</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <form noValidate>
              <div className="form-group">
                <label>
                  <FormattedMessage id="profile.email" defaultMessage="Email" />
                </label>
                <input
                  type="text"
                  name="email"
                  autoComplete="off"
                  onChange={props.emailDialoginputChangeHandler}
                  value={email.value}
                  className={classNames("form-control", {
                    "is-valid": email.isValid,
                    "is-invalid": email.isPristine && !email.isValid
                  })}
                />
                {email.isPristine && email.isValid && (
                  <span
                    style={{
                      height: "15px",
                      width: "82px",
                      color: "#0078D7",
                      fontSize: "15px",
                      fontWeight: "600",
                      lineHeight: "15px",
                      cursor: "pointer",
                      right: "30px",
                      top: "61px",
                      position: "absolute"
                    }}
                    onClick={() => props.verifyEmail(email.value)}
                  >
                    Send OTP
                  </span>
                )}
              </div>
              {props.showEmailOtpField && (
                <div className="form-group">
                  <label>
                    <FormattedMessage id="profile.otp" defaultMessage="OTP" />
                  </label>
                  <input
                    type="text"
                    name="otp"
                    autoComplete="off"
                    onChange={props.emailDialoginputChangeHandler}
                    value={otp.value}
                    className={classNames("form-control", {
                      "is-valid": otp.isValid,
                      "is-invalid": otp.isPristine && !otp.isValid
                    })}
                  />
                </div>
              )}
            </form>
          </Modal.Body>

          <Modal.Footer>
            {props.isLoading && props.isEmailModelOpen && <InlineLoader />}
            <button
              className="btn btn-primary"
              onClick={() => props.verifyOtpForEmail(email.value, otp.value)}
              disabled={!isFormValid}
            >
              <FormattedMessage
                id="profile.save-profile"
                defaultMessage="Save Profile"
              />
            </button>
          </Modal.Footer>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
        </Modal>
      </React.Fragment>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid mt-4 mt-md-5 mb-4 mb-md-5">
          <div className="row mb-3 mt-md-4 mb-md-4">
            <div className="col-12">
              <small>
                <Link to="/"><FormattedMessage
                    id="breadcrumbs.home"
                    defaultMessage="Home"
                  /></Link>
              </small>{" "}
              /{" "}
              <small>
                <Link to="/profile"><FormattedMessage
                    id="profile.profile"
                    defaultMessage="Profile"
                  /></Link>
              </small>{" "}
              /{" "}
              <small>
                <strong>Profile Details</strong>
              </small>
            </div>
          </div>

          <div className="row">
            <div className="col-12 mt-2 mb-3 font-proxima-nova">
              <div
                className="card"
                style={{
                  backgroundColor: "#F8F9FA",
                  borderRadius: "10px",
                  border: "none"
                }}
              >
                <div className="card-body">
                  {null !== this.state.profile && (
                          
                    <ul className="list-group list-group-flush profile-list">
                      <li
                        className="list-group-item pl-0 mt-3"
                        style={{ backgroundColor: "#F8F9FA" }}
                      >
                        <img
                          src={editIcon}
                          alt="edit"
                          style={{
                            cursor: "pointer",
                            float: "right",
                            marginTop: "-1.5rem",
                            width: "1.2rem"
                          }}
                          onClick={this.editProfile}
                        />

                         <this.EditProfileFormDialog
                          {...this.state}
                          {...this.props}
                          inputChangeHandler={this.inputChangeHandler}
                          handleClose={this.handleClose}
                          submitHandler={this.submitHandler}
                        />
                    
                        <p
                          className="mb-1 text-secondary"
                          style={{ fontSize: ".9rem" }}
                        >
                          <FormattedMessage
                            id="profile.full-name"
                            defaultMessage="Full Name"
                          />
                        </p>
                        <p className="mb-0">
                          <strong>{this.state.profile.name}</strong>
                        </p>
                      </li>
                      <li
                        className="list-group-item pl-0 mt-3"
                        style={{ backgroundColor: "#F8F9FA" }}
                      >
                        <p
                          className="mb-1 text-secondary"
                          style={{ fontSize: ".9rem" }}
                        >
                          <FormattedMessage
                            id="profile.gender"
                            defaultMessage="Gender"
                          />
                        </p>
                        <p className="mb-0">
                          <strong>{this.state.profile.gender}</strong>
                        </p>
                      </li>
                      <li
                        className="list-group-item pl-0 mt-3"
                        style={{ backgroundColor: "#F8F9FA" }}
                      >
                        <p
                          className="mb-1 text-secondary"
                          style={{ fontSize: ".9rem" }}
                        >
                          <FormattedMessage
                            id="profile.dob"
                            defaultMessage="Date Of Birth"
                          />
                        </p>
                        <p className="mb-0">
                          <strong>{this.state.profile.dob}</strong>
                        </p>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
              <div
                className="card mt-4"
                style={{
                  backgroundColor: "#F8F9FA",
                  borderRadius: "10px",
                  border: "none"
                }}
              >
                <div className="card-body">
                  {null !== this.state.profile && (
                    <ul className="list-group list-group-flush">
                      <li
                        className="list-group-item pl-0 mt-3"
                        style={{ backgroundColor: "#F8F9FA" }}
                      >
                        <img
                          src={editIcon}
                          alt="edit"
                          style={{
                            cursor: "pointer",
                            float: "right",
                            marginTop: "-1.5rem",
                            width: "1.2rem"
                          }}
                          onClick={this.editMobile}
                        />
                        <this.EditMobileFormDialog
                          {...this.state}
                          {...this.props}
                          mobileDialoginputChangeHandler={
                            this.mobileDialoginputChangeHandler
                          }
                          verifyMobile={mobileNumber =>
                            this.verifyMobile(mobileNumber)
                          }
                          verifyOtp={(mobileNumber, otp) =>
                            this.verifyOtp(mobileNumber, otp)
                          }
                          closeMobileModelHandler={this.closeMobileModelHandler}
                        />

                        <p
                          className="mb-1 text-secondary"
                          style={{ fontSize: ".9rem" }}
                        >
                          <FormattedMessage
                            id="profile.mobile"
                            defaultMessage="Mobile"
                          />
                        </p>
                        <p className="mb-0">
                          <strong>{this.state.profile.mobile}</strong>
                        </p>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
              <div
                className="card mt-4"
                style={{
                  backgroundColor: "#F8F9FA",
                  borderRadius: "10px",
                  border: "none"
                }}
              >
                <div className="card-body">
                  {null !== this.state.profile && (
                    <ul className="list-group list-group-flush">
                      <li
                        className="list-group-item pl-0 mt-3"
                        style={{ backgroundColor: "#F8F9FA" }}
                      >
                        <img
                          src={editIcon}
                          alt="edit"
                          style={{
                            cursor: "pointer",
                            float: "right",
                            marginTop: "-1.5rem",
                            width: "2rem"
                          }}
                          onClick={this.editEmail}
                        />
                        <this.EditEmailFormDialog
                          {...this.state}
                          {...this.props}
                          emailDialoginputChangeHandler={
                            this.emailDialoginputChangeHandler
                          }
                          verifyEmail={email => this.verifyEmail(email)}
                          verifyOtpForEmail={(email, otp) =>
                            this.verifyOtpForEmail(email, otp)
                          }
                          closeEmailModelHandler={this.closeEmailModelHandler}
                        />
                        <p
                          className="mb-1 text-secondary"
                          style={{ fontSize: ".9rem" }}
                        >
                          <FormattedMessage
                            id="profile.email"
                            defaultMessage="Email"
                          />
                        </p>
                        <p className="mb-0">
                          <strong>{this.state.profile.email}</strong>
                        </p>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default injectIntl(ProfileDetails);