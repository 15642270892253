import React, { Component } from "react";
import clientX from "../../../utils/clientX";
import { Link } from "react-router-dom";
import InlineLoader from "../../../components/InlineLoader";
import lodash from "lodash";
import ordersVector from "../../../assets/empty-state-orders.svg";
import { FormattedMessage } from "react-intl";
import { reverseDateFormat } from "../../../utils/helper";
import filterIcon from "../../../assets/filter-icon.svg";
import Modal from "react-bootstrap/Modal";
export default class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      isFetchingOrders: false,
      isFetchingNextPage: false,
      nextPage: null,
      segmentFilters: [],
      selectedSegments: [],
      statusFilters: [],
      selectedStatus: [],
      from_dateFilter: "",
      to_dateFilter: "",
      isFilterApplied: false,
      isOrderFilterModelOpen: false
    };
    this.getFilters();
  }

  onScroll = () => {
    if (this.state.isFetchingNextPage) {
      return;
    }

    if (!this.state.nextPage) {
      return;
    }

    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 500
    ) {
      this.fetchPage(this.state.nextPage);
    }
  };

  componentDidMount() {
    this.setState(
      {
        isFetchingOrders: true
      },
      () => {
        this.initOrders();
      }
    );

    window.addEventListener("scroll", this.onScroll, false);
  }

  initOrders = () => {
    clientX.get("v1/orders").then(response => {
      let data = response.data.data;

      let nextPageURL =
        null !== response.data.links.next && new URL(response.data.links.next);

      let nextPage =
        null !== response.data.links.next &&
        nextPageURL.searchParams.get("page");

      this.setState({
        orders: this.state.orders.concat(data),
        nextPage: nextPage,
        isFetchingOrders: false
      });
    });
  };

  getFilters = () => {
    clientX.get("v1/orders/filters").then(res => {
      const segments = res.data.data.segment;
      const statuses = res.data.data.status;
      const modifiedSegments = segments.map(obj => ({
        ...obj,
        isChecked: false
      }));
      const modifiedStatuses = statuses.map(obj => ({
        ...obj,
        isChecked: false
      }));
      this.setState({
        segmentFilters: modifiedSegments,
        statusFilters: modifiedStatuses
      });
    });
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll, false);
  }

  fetchPage = page => {
    const {
      selectedStatus,
      selectedSegments,
      from_dateFilter,
      to_dateFilter
    } = this.state;
    this.setState(
      {
        isFetchingNextPage: true
      },
      () => {
        clientX
          .get("/orders", {
            params: {
              page: page,
              ...(selectedStatus.length > 0
                ? { status: selectedStatus.join() }
                : {}),
              ...(selectedSegments.length > 0
                ? { segment: selectedSegments.join() }
                : {}),
              from_date: reverseDateFormat(from_dateFilter),
              to_date: reverseDateFormat(to_dateFilter)
            }
          })
          .then(response => {
            let data = response.data.data;

            let nextPageURL =
              null !== response.data.links.next &&
              new URL(response.data.links.next);

            let nextPage =
              null !== response.data.links.next &&
              nextPageURL.searchParams.get("page");

            this.setState({
              orders: this.state.orders.concat(data),
              nextPage: nextPage,
              isFetchingNextPage: false
            });
          });
      }
    );
  };

  getOrderDetailLink = order => {
    switch (order.service) {
      case "bill-pay":
        return `/bill-pay/orders/${order.item_order_id}`;
      case "voucher":
        return `/vouchers/orders/${order.item_order_id}`;
      case "shopping-site":
        return `/shopping-sites/orders/${order.item_order_id}`;
      case "partner-purchase":
        return `/nearby-partners/orders/${order.item_order_id}`;
      default:
        return ``;
    }
  };

  resetFilter = () => {
    this.setState(state => ({
      from_dateFilter: "",
      to_dateFilter: "",
      segmentFilters: state.segmentFilters.map(status => ({
        ...status,
        checked: false
      })),
      statusFilters: state.statusFilters.map(status => ({
        ...status,
        checked: false
      }))
    }));

    this.initOrders();
  };

  onChange = (event, key, value, filterType, index) => {
    event.persist();
    this.setState({ isFilterApplied: true }, () => {
      switch (filterType) {
        case "segment":
          this.setState(
            state => ({
              ...state,
              segmentFilters: state.segmentFilters.map(status =>
                key === status.key
                  ? { ...status, checked: !status.checked }
                  : status
              )
            }),
            () => {
              let uniqueSegment = new Set();
              for (
                let index = 0;
                index < this.state.segmentFilters.length;
                index++
              ) {
                const element = this.state.segmentFilters[index];
                if (element.checked) {
                  uniqueSegment.add(element.key);
                }
              }
              this.setState(
                {
                  selectedSegments: [...Array.from(uniqueSegment)]
                },
                () => {
                  this.getOrdersPerFilter();
                }
              );
            }
          );
          break;
        case "status":
          this.setState(
            state => ({
              ...state,
              statusFilters: state.statusFilters.map(status =>
                key === status.key
                  ? { ...status, checked: !status.checked }
                  : status
              )
            }),
            () => {
              const result = this.state.statusFilters.filter(
                item => item.checked
              );
              let mySet = new Set();
              for (let index = 0; index < result.length; index++) {
                const element = result[index];
                mySet.add(element.key);
              }
              this.setState(
                {
                  selectedStatus: [...Array.from(mySet)]
                },
                () => {
                  this.getOrdersPerFilter();
                }
              );
            }
          );
          break;
        case "from_date":
          this.setState(
            {
              ...this.state,
              from_dateFilter: key
            },
            () => {
              this.getOrdersPerFilter();
            }
          );
          break;
        case "to_date":
          this.setState(
            {
              ...this.state,
              to_dateFilter: key
            },
            () => {
              this.getOrdersPerFilter();
            }
          );
          break;
        default:
          break;
      }
    });
  };

  onOrderFilterClick = () => {
    this.setState(prevState => ({
      isOrderFilterModelOpen: !prevState.isOrderFilterModelOpen
    }));
  };

  getOrdersPerFilter = () => {
    const {
      selectedStatus,
      selectedSegments,
      from_dateFilter,
      to_dateFilter
    } = this.state;
    clientX
      .get("/orders", {
        params: {
          ...(selectedStatus.length > 0
            ? { status: selectedStatus.join() }
            : {}),
          ...(selectedSegments.length > 0
            ? { segment: selectedSegments.join() }
            : {}),
          from_date: reverseDateFormat(from_dateFilter),
          to_date: reverseDateFormat(to_dateFilter)
        }
      })
      .then(response => {
        let data = response.data.data;

        let nextPageURL =
          null !== response.data.links.next &&
          new URL(response.data.links.next);

        let nextPage =
          null !== response.data.links.next &&
          nextPageURL.searchParams.get("page");

        this.setState({
          orders: data,
          nextPage: nextPage,
          isFetchingOrders: false
        });
      });
  };

  render() {
    return (
      <div className="container-fluid font-proxima-nova">
        <div className="row mt-3 mb-3">
          <div className="col-12">
            <small>
              <Link to="/">
                <FormattedMessage id="breadcrumbs.home" defaultMessage="Home" />
              </Link>{" "}
              /{" "}
              <strong>
                <FormattedMessage id="navbar.orders" defaultMessage="Orders" />
              </strong>
            </small>
          </div>
        </div>
        <div className="col-12 text-right d-block d-md-none pr-0">
          <img src={filterIcon} alt="" className="filter-icon" />
          <button
            className="btn text-primary"
            onClick={this.onOrderFilterClick}
          >
            <FormattedMessage id="general.filters" defaultMessage="Filters" />
          </button>

          <React.Fragment>
            <Modal
              show={this.state.isOrderFilterModelOpen}
              onHide={this.onOrderFilterClick}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <FormattedMessage
                    id="general.filters"
                    defaultMessage="Filters"
                  />
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Filters
                  {...this.state}
                  onChange={(event, key, value, filterType, index) =>
                    this.onChange(event, key, value, filterType, index)
                  }
                  resetFilter={this.resetFilter}
                />
              </Modal.Body>
              <Modal.Footer>
                <div className="row w-100">
                  <div className="col-6">
                    <button
                      onClick={this.onOrderFilterClick}
                      className="w-100 btn btn-outline-primary"
                    >
                      <FormattedMessage
                        id="general.apply"
                        defaultMessage="Apply"
                      />
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      onClick={() => {
                        this.resetFilter();
                        this.onOrderFilterClick();
                      }}
                      className="btn w-100 btn-outline-primary"
                    >
                      <FormattedMessage
                        id="general.reset"
                        defaultMessage="Reset"
                      />
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </Modal>
          </React.Fragment>
        </div>
        <div className="row">
          <FilterBlock
            {...this.state}
            onChange={(event, key, value, filterType, index) =>
              this.onChange(event, key, value, filterType, index)
            }
            resetFilter={this.resetFilter}
          />

          <div className="col-md-9 col-12 main-content">
            {this.state.isFetchingOrders ? (
              <React.Fragment>
                {lodash.range(4).map(index => {
                  return (
                    <div className="row" key={index}>
                      <div className="col-12">
                        <div className="box shine mb-3 mb-md-4"></div>
                      </div>
                    </div>
                  );
                })}
              </React.Fragment>
            ) : this.state.orders.length === 0 ? (
              <NoOrderFoundBlock {...this.state} />
            ) : (
              <React.Fragment>
                {this.state.orders.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Link to={this.getOrderDetailLink(item)}>
                        <div
                          className="card mb-3"
                          style={{
                            boxShadow: "0 4px 14px 0 rgba(0,0,0,0.08)",
                            borderRadius: "8px",
                            border: "none"
                          }}
                        >
                          <div className="card-body">
                            <div className="row">
                              <div className="col-12 col-md-4">
                                <p className="mb-1">
                                  <strong>{item.item_name}</strong>
                                </p>
                                <p className="mb-0">
                                  {item.service_text} {item.order_number}
                                </p>
                                <p className="mb-0">
                                  {item.wrp}
                                  {item.wrp_credited
                                    ? " WRPs credited"
                                    : " WRP Credit is pending"}
                                </p>
                                <p className="mb-0 d-md-none mt-2 text-secondary">
                                  STATUS
                                </p>
                                <OrderStatusLabel order={item} />
                              </div>
                              <div className="col-12 col-md-4">
                                <p className="mb-0 mt-md-4 text-secondary">
                                  ORDER PLACED
                                </p>
                                <p>{item.created_at}</p>
                              </div>
                              <div className="col-12 col-md-4">
                                <p className="mb-0 mt-md-4 text-secondary">
                                  TOTAL
                                </p>
                                <p>₹ {item.amount}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            )}
          </div>
        </div>

        <div className="row mt-3 mb-3">
          <div className="col-12 text-center">
            {this.state.isFetchingNextPage && <InlineLoader />}
            {!this.state.nextPage && 0 !== this.state.orders.length && (
              <p className="font-weight-bold">
                <FormattedMessage
                  id="order.no-more"
                  defaultMessage="No more orders"
                />
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export const NoOrderFoundBlock = props => {
  return (
    <div className="row">
      <div className="col-12 text-center">
        <img className="orders-vector" src={ordersVector} alt="orders" />
        <p className="mt-3">
          <strong>
            {props.isFilterApplied
              ? "No order for this filter."
              : "You don't have any orders."}
          </strong>
        </p>
      </div>
    </div>
  );
};

export const FilterBlock = props => {
  return (
    <div className="col-3 filter-wrapper border-right d-none d-md-block">
      <div className="row border-bottom align-items-center status">
        <div className="col-md-6 pl-0">
          <h5>
            <strong>
              <FormattedMessage id="general.filters" defaultMessage="Filters" />
            </strong>
          </h5>
        </div>
        <div className="col-md-6">
          <button
            className="btn btn-outline float-right winds-link-color"
            onClick={props.resetFilter}
          >
            <span>
              <FormattedMessage id="general.reset" defaultMessage="Reset" />
            </span>
          </button>
        </div>
      </div>
      <Filters
        {...props}
        resetFilter={props.resetFilter}
        onChange={(event, key, value, filterType, index) =>
          props.onChange(event, key, value, filterType, index)
        }
      />
    </div>
  );
};
export const Filters = props => {
  return (
    <React.Fragment>
      <div className="row border-bottom align-items-center mt-4 pb-5 status">
        <div className="col-md-12 pl-0">
          <p className="text-uppercase">
            <strong>
              <FormattedMessage id="order.status" defaultMessage="STATUS" />
            </strong>
          </p>
          {props.statusFilters.map((item, index) => (
            <div className="row mt-3" key={index}>
              <div className="col-9 winds-grey-color">{item.value}</div>
              <div className="col-3 mb-1 text-center">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={item.key}
                    checked={item.checked || false}
                    onChange={event => {
                      props.onChange(
                        event,
                        item.key,
                        item.value,
                        "status",
                        index
                      );
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={item.key}
                  ></label>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="row border-bottom align-items-center mt-4 pb-5 status">
        <div className="col-md-12 pl-0">
          <p className="text-uppercase">
            <strong>
              <FormattedMessage id="order.segment" defaultMessage="SEGMENT" />
            </strong>
          </p>
          {props.segmentFilters.map((item, index) => (
            <div className="row mt-3" key={index}>
              <div className="col-9 winds-grey-color">{item.value}</div>
              <div className="col-3 mb-1 text-center">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={item.key}
                    checked={item.checked || false}
                    onChange={event => {
                      props.onChange(
                        event,
                        item.key,
                        item.value,
                        "segment",
                        index
                      );
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={item.key}
                  ></label>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="row align-items-center mt-4 pb-3">
        <div className="col-md-12">
          <p className="text-uppercase">
            <strong>
              <FormattedMessage id="order.date" defaultMessage="DATE" />
            </strong>
          </p>
          <div className="row mt-3">
            <div className="col-md-12 winds-grey-color">
              {" "}
              <FormattedMessage id="order.from" defaultMessage="From" />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12 winds-grey-color">
              <input
                type="date"
                name="from-date"
                className="date-input"
                value={props.from_dateFilter}
                onChange={event => {
                  props.onChange(
                    event,
                    event.target.value,
                    event.target.value,
                    "from_date",
                    null
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row align-items-center pb-5">
        <div className="col-md-12">
          <div className="row mt-3">
            <div className="col-md-12 winds-grey-color">
              <FormattedMessage id="order.to" defaultMessage="To" />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12 winds-grey-color">
              <input
                type="date"
                name="from-date"
                className="date-input"
                value={props.to_dateFilter}
                onChange={event => {
                  props.onChange(
                    event,
                    event.target.value,
                    event.target.value,
                    "to_date",
                    null
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row status align-items-center mt-4 pb-5 d-none d-md-block">
        <div className="col-md-12">
          <button
            className="btn btn-primary home-btn"
            onClick={props.resetFilter}
          >
            <FormattedMessage id="general.reset" defaultMessage="Reset" />
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

function OrderStatusLabel(props) {
  if (props.order.fulfilled) {
    return (
      <p className="badge badge-success text-white">{props.order.status}</p>
    );
  }

  if (!props.order.fulfilled && props.order.payment_failed) {
    return (
      <p className="badge badge-danger text-white">{props.order.status}</p>
    );
  }

  return <p className="badge badge-warning text-white">{props.order.status}</p>;
}
