import React, { Component } from "react";
import { Link } from "react-router-dom";
import clientX from "../../../utils/clientX";
import SideBar from "../components/SideBar";
import Profile from "../components/Profile";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { ToastContainer, toast } from "react-toastify";
import update from "immutability-helper";
import InlineLoader from "../../../components/InlineLoader";
export default class ChangePassword extends Component {
  state = {
    isLoading: false,
    old_password: {
      value: "",
      isValid: false,
      error: "",
      isPristine: false
    },
    password: {
      value: "",
      isValid: false,
      error: "",
      isPristine: false
    },
    password_confirmation: {
      value: "",
      isValid: false,
      error: "",
      isPristine: false
    },
    formValid: false
  };

  inputChangeHandler = e => {
    const { name, value } = e.target;
    const newData = update(this.state, {
      [name]: {
        value: { $set: value },
        isPristine: { $set: true }
      }
    });

    this.setState(newData, () => {
      this.checkValidity(name, value);
    });
  };

  checkValidity(fieldName, value) {
    const { old_password, password, password_confirmation } = { ...this.state };

    switch (fieldName) {
      case "old_password":
        old_password.isValid = value.trim().length > 5;
        old_password.error = old_password.isValid
          ? ""
          : "Old Password is invalid";
        break;

      case "password":
        password.isValid = value.trim().length > 5;
        password.error = password.isValid ? "" : "Password is invalid";
        break;
      case "password_confirmation":
        password_confirmation.isValid =
          value.trim().length > 5 && value === password.value;
        password_confirmation.error = password_confirmation.isValid
          ? ""
          : "New Password is invalid";
          break;
      default:
        break;
    }

    const newData = update(this.state, {
      isFormValid: { $set: this.areAllFieldsValid() }
    });

    this.setState(newData);
  }
  areAllFieldsValid() {
    const { old_password, password, password_confirmation } = this.state;
    return [old_password, password, password_confirmation].every(
      x => x.value && x.isValid
    );
  }
  submitHandler = event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    const formData = {};
    for (let formField in this.state) {
      if (this.state[formField].value) {
        formData[formField] = this.state[formField].value;
      }
    }
    clientX
      .post(`/change-password`, formData)
      .then(response => {
        this.setState({ isLoading: false });
        if (response.data.success) {
          toast.success("Password Changed", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        }
      })
      .catch(error => {
        this.setState({ isLoading: false });
        toast.error(error.response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      });
  };
  changePasswordForm = props => {
    const {
      old_password,
      password,
      password_confirmation,
      isFormValid
    } = props;
    return (
      <form noValidate onSubmit={props.submitHandler}>
        <div className="form-group">
          <label>
            <FormattedMessage
              id="profile.old-password"
              defaultMessage="Old Password"
            />
          </label>
          <input
            type="password"
            name="old_password"
            autoComplete="off"
            onChange={props.inputChangeHandler}
            value={old_password.value}
            className={classNames("form-control", {
              "is-valid": old_password.isValid,
              "is-invalid": old_password.isPristine && !old_password.isValid
            })}
          />
        </div>
        <div className="form-group">
          <label>
            <FormattedMessage
              id="profile.new-password"
              defaultMessage="New Password"
            />
          </label>
          <input
            type="password"
            name="password"
            autoComplete="off"
            onChange={props.inputChangeHandler}
            value={password.value}
            className={classNames("form-control", {
              "is-valid": password.isValid,
              "is-invalid": password.isPristine && !password.isValid
            })}
          />
        </div>

        <div className="form-group">
          <label>
            <FormattedMessage
              id="profile.confirm-password"
              defaultMessage="Confirm Password"
            />
          </label>
          <input
            type="password"
            name="password_confirmation"
            autoComplete="off"
            onChange={props.inputChangeHandler}
            value={password_confirmation.value}
            className={classNames("form-control", {
              "is-valid": password_confirmation.isValid,
              "is-invalid":
                password_confirmation.isPristine &&
                !password_confirmation.isValid
            })}
          />
        </div>

        {props.isLoading ? (
          <div className="float-right">
            <InlineLoader />
          </div>
        ) : (
          <button
            className="btn btn-primary float-right"
            disabled={!isFormValid}
          >
            <FormattedMessage
              id="profile.change-password"
              defaultMessage="Change Password"
            />
          </button>
        )}
      </form>
    );
  };
  render() {
    return (
      <React.Fragment>
        <div className="container-fluid pt-3 pb-3 pt-md-4 pb-md-4 breadCrumbs">
          <div className="row mb-md-3">
            <div className="col-12 m-auto font-proxima-nova">
              <small>
                <Link to="/">
                  <FormattedMessage
                    id="breadcrumbs.home"
                    defaultMessage="Home"
                  />
                </Link>
              </small>{" "}
              /{" "}
              <small>
                <Link to="/profile">
                  <FormattedMessage
                    id="profile.profile"
                    defaultMessage="Profile"
                  />
                </Link>
              </small>{" "}
              /{" "}
              <small>
                <strong>
                  <FormattedMessage
                    id="profile.change-password"
                    defaultMessage="Change Password"
                  />
                </strong>
              </small>
            </div>
          </div>

          <div className="d-none d-md-block">
            <Profile />
          </div>
        </div>

        <div className="container-fluid mt-md-5 mb-4 mb-md-5">
          <div className="row mb-3 mt-md-4 mb-md-4">
            <div className="d-none col-md-2 d-md-block font-proxima-nova border-right">
              <SideBar />
            </div>
            <div className="col-12 col-md-9 d-md-block address-wrapper">
              <div className="row d-md-flex">
                <div className="col-7 col-md-8 font-proxima-nova-bold">
                  <h5 className="d-none d-md-block">
                    <strong>
                    <FormattedMessage
                        id="profile.change-password"
                        defaultMessage="Change Password"
                      />
                    </strong>
                  </h5>
                </div>
              </div>

              <div className="row d-md-flex font-proxima-nova">
                <div className="col-md-10 col-12">
                  <div
                    className="card mt-4"
                    style={{
                      backgroundColor: "#F8F9FA",
                      borderRadius: "10px",
                      border: "none"
                    }}
                  >
                    <div className="card-body">
                      <this.changePasswordForm
                        {...this.state}
                        {...this.props}
                        inputChangeHandler={this.inputChangeHandler}
                        submitHandler={this.submitHandler}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </React.Fragment>
    );
  }
}
