import React, { Component } from 'react';
import Slider from "react-slick";

export default class MultiItemCarousel extends Component{

      componentDidMount(){
        window.addEventListener('touchstart', this.touchStart);
        window.addEventListener('touchmove', this.preventTouch, {passive: false});
    }

    componentWillUnmount(){
        window.removeEventListener('touchstart', this.touchStart);
        window.removeEventListener('touchmove', this.preventTouch, {passive: false});
    }

    touchStart(e){
        this.firstClientX = e.touches[0].clientX;
        this.firstClientY = e.touches[0].clientY;
    }

    preventTouch(e){
        const minValue = 5; // threshold

        this.clientX = e.touches[0].clientX - this.firstClientX;
        this.clientY = e.touches[0].clientY - this.firstClientY;

        if(Math.abs(this.clientX) > minValue){ 
            e.preventDefault();
            e.returnValue = false;
            return false;
        }
    }

    render(){

        const settings = {
            arrows:true,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            responsive: [
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  arrows:false
                }
              }
            ]
          };
      

        return(
            <Slider {...settings}>
                {this.props.children}
            </Slider>
        )
    }

}

function NextArrow(props){
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, zIndex:2, right:'15px', background:'#fff',display: "block",borderRadius: "6px",boxShadow: "0 0 15px 0 rgba(0,0,0,0.13)",padding:"5px",height:"48px",width:"38px"}}
        onClick={onClick}
      >
      </div>
    );
  }
  
  function PrevArrow(props){
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, zIndex:2, left:'15px', background:'#fff',display: "block",borderRadius: "6px",boxShadow: "0 0 15px 0 rgba(0,0,0,0.13)",padding:"5px",height:"48px",width:"38px"}}
        onClick={onClick}
      />
    );
  }