import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

export default class AllBrands extends Component {
  render() {
    return (
      this.props.tags.length > 0 && (
        <div className="container-fluid container-fluid--home-section">
          <div className="row row--home-section-title">
            <div className="col-12">
              <h2 className="font-proxima-nova-bold">
                <FormattedMessage id="home.brands" defaultMessage="Brands" />
              </h2>
            </div>
          </div>
          {this.props.tags.map((item, index) => {
            return (
              <React.Fragment key={index+item.name}>
                <div className="row">
                  <div className="col-12">
                    <h6 className="font-proxima-nova-bold mt-3">{item.name}</h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      {item.items.map((item, index) => {
                        return (
                          <div className="col-4 col-md-3 mb-4" key={item.name+item.id}>
                            <Link
                              to={
                                "voucher" === item.item_type
                                  ? `/voucher/${item.id}`
                                  : `/shopping-sites/${item.id}`
                              }
                            >
                              <div className="card card--all-brands" style={{}}>
                                <div className="card-body text-center">
                                  <div className="all-brand-image-wrapper" style={{backgroundImage:`url(${item.image_url})`}}>
                                   
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      )
    );
  }
}
