export function setHome(
  banner,
  affiliate,
  partner,
  voucher,
  billpay,
  offers,
  tags
) {
  return {
    type: "SET_HOME",
    banner,
    affiliate,
    partner,
    voucher,
    billpay,
    offers,
    tags
  };
}

export function setHomeAfterBookmarkToggle(
  id,
  listTypeToUpdate,
  banner,
  affiliate,
  partner,
  voucher,
  billpay,
  offers,
  tags
) {
  return {
    type: "SET_HOME_AFTER_TOGGLE_BOOKMARK",
    id,
    listTypeToUpdate,
    banner,
    affiliate,
    partner,
    voucher,
    billpay,
    offers,
    tags
  };
}

export function setLoading(isLoading) {
  return {
    type: "SET_LOADING",
    isLoading: isLoading
  };
}
