import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import downCaret from "../assets/down-caret-white.svg";
import { FormattedMessage, injectIntl } from "react-intl";
import changeLanguageIcon from "../assets/ic_language.svg";
import { createCookie,currentlySelectedLanguage } from "../utils/helper";
import classNames from "classnames";

class LanguageSwitcher extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
      predictions: [],
      languages: [
        {
          code: "en",
          english_name: "English",
          translated_name: "English"
        },
        {
          code: "hi",
          english_name: "Hindi",
          translated_name: "हिंदी"
        }
        // {
        //   code:'gu',
        //   english_name:'Gujarati',
        //   translated_name:'ગુજરાતી'
        // },
        // {
        //   code:'as',
        //   english_name:'Assamese',
        //   translated_name:'অসমীয়া'
        // },
        // {
        //   code:'bn',
        //   english_name:'Bengali',
        //   translated_name:'বাংলা'
        // },
        // {
        //   code:'kn',
        //   english_name:'Kannada',
        //   translated_name:'ಕನ್ನಡ'
        // },
        // {
        //   code:'ml',
        //   english_name:'Malayalam',
        //   translated_name:'മലയാളം'
        // },
        // {
        //   code:'mr',
        //   english_name:'Marathi',
        //   translated_name:'मराठी'
        // },
        // {
        //   code:'or',
        //   english_name:'Oriya',
        //   translated_name:'ଓଡ଼ିଆ'
        // },
        // {
        //   code:'ta',
        //   english_name:'Tamil',
        //   translated_name:'தமிழ்'
        // },
        // {
        //   code:'te',
        //   english_name:'Telugu',
        //   translated_name:'తెలుగు'
        // }
      ],
      filtered_languages: [
        {
          code: "en",
          english_name: "English",
          translated_name: "English"
        },
        {
          code: "hi",
          english_name: "Hindi",
          translated_name: "हिंदी"
        }
        // {
        //   code:'gu',
        //   english_name:'Gujarati',
        //   translated_name:'ગુજરાતી'
        // },
        // {
        //   code:'as',
        //   english_name:'Assamese',
        //   translated_name:'অসমীয়া'
        // },
        // {
        //   code:'bn',
        //   english_name:'Bengali',
        //   translated_name:'বাংলা'
        // },
        // {
        //   code:'kn',
        //   english_name:'Kannada',
        //   translated_name:'ಕನ್ನಡ'
        // },
        // {
        //   code:'ml',
        //   english_name:'Malayalam',
        //   translated_name:'മലയാളം'
        // },
        // {
        //   code:'mr',
        //   english_name:'Marathi',
        //   translated_name:'मराठी'
        // },
        // {
        //   code:'or',
        //   english_name:'Oriya',
        //   translated_name:'ଓଡ଼ିଆ'
        // },
        // {
        //   code:'ta',
        //   english_name:'Tamil',
        //   translated_name:'தமிழ்'
        // },
        // {
        //   code:'te',
        //   english_name:'Telugu',
        //   translated_name:'తెలుగు'
        // }
      ]
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  onSearch = event => {
    if (event.target.value.length > 0) {
      let filtered_languages = this.state.languages.filter(item => {
        return (
          item.english_name
            .toLowerCase()
            .indexOf(event.target.value.toLowerCase()) > -1
        );
      });

      this.setState({
        filtered_languages: filtered_languages
      });

      return;
    }

    this.setState({
      filtered_languages: this.state.languages
    });
  };

  setCookie = code => {
    createCookie("windsEWorldAppLanguageCode", code, "60");
    window.location.reload();
  };

  render() {
    return (
      <React.Fragment>
        <div className="d-inline-block" onClick={this.handleShow}>
          <img
            src={changeLanguageIcon}
            className="mr-1"
            style={{ height: "12px" }}
            alt="download apps"
          />
          <span
            className="d-none d-md-inline-block mr-1"
            style={{ fontSize: "13px", lineHeight: "30px", cursor: "pointer" }}
          >
            <FormattedMessage
              id="navbar.language"
              defaultMessage="Language"
              description="Language"
              values={{ what: "react-intl" }}
            />
            <img
              src={downCaret}
              style={{ display: "inline", marginLeft: "0.5rem" }}
              alt="down caret"
            />
          </span>
        </div>
        <Modal size="lg" show={this.state.show} onHide={this.handleClose}>
          <Modal.Header>
            <Modal.Title>
              <FormattedMessage
                id="navbar.choose-a-language"
                defaultMessage="Choose a Language"
              />
            </Modal.Title>
            <button type="button" className="close" onClick={this.handleHide}>
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder={this.props.intl.formatMessage({
                  id: "general.search"
                })}
                onKeyUp={this.onSearch}
              />
            </div>
            <ul className="list-group list-group-flush">
              {this.state.filtered_languages.map((item, index) => {
                return (
                  <li
                    className="list-group-item pl-0 pr-0 border-top-0"
                    key={"lang-" + item.code}
                    style={{cursor:'pointer'}}
                  >
                    <div onClick={() => this.setCookie(item.code)}>
                      <p className="mb-0">
                        <strong>{item.translated_name}</strong>
                      </p>
                      <p
                        className={classNames('mb-0',{ 'highlight': item.code === currentlySelectedLanguage() })}
                      >
                        {item.english_name}
                      </p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={this.handleHide}>
              <FormattedMessage id="general.close" defaultMessage="Close" />
            </button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default injectIntl(LanguageSwitcher);
