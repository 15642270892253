import React from 'react';
import ReactDOM from 'react-dom';
import App from './modules/App';
import * as serviceWorker from './serviceWorker';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import store from './store';
import {currentlySelectedLanguage} from './utils/helper';
import { Provider } from 'react-redux';
import FallBack from './components/FallBack';
import {addLocaleData, IntlProvider} from "react-intl";
import hi from 'react-intl/locale-data/hi';
import en from 'react-intl/locale-data/en';
import gu from 'react-intl/locale-data/gu';
import as from 'react-intl/locale-data/as';
import bn from 'react-intl/locale-data/bn';
import kn from 'react-intl/locale-data/kn';
import ml from 'react-intl/locale-data/ml';
import mr from 'react-intl/locale-data/mr';
import or from 'react-intl/locale-data/or';
import ta from 'react-intl/locale-data/ta';
import te from 'react-intl/locale-data/te';

import messages_en from "./translations/en/en.json";
import messages_hi from "./translations/hi_IN/hi_IN.json";
import messages_gu from "./translations/gu_IN/gu_IN.json";
import messages_as from "./translations/as/as.json";
import messages_bn from "./translations/bn/bn.json";
import messages_kn from "./translations/kn_IN/kn_IN.json";
import messages_ml from "./translations/ml_IN/ml_IN.json";
import messages_mr from "./translations/mr/mr.json";
import messages_or from "./translations/or_IN/or_IN.json";
import messages_ta from "./translations/ta/ta.json";
import messages_te from "./translations/te_IN/te_IN.json";

addLocaleData([...en, ...hi, ...gu, ...as, ...bn, ...kn, ...ml, ...mr, ...or, ...ta, ...te]);

const messages = {
    'hi': messages_hi,
    'en': messages_en,
    'gu': messages_gu,
    'as': messages_as,
    'bn': messages_bn,
    'kn': messages_kn,
    'ml': messages_ml,
    'mr': messages_mr,
    'or': messages_or,
    'ta': messages_ta,
    'te': messages_te
};

const language = currentlySelectedLanguage();
const bugsnagClient = bugsnag(process.env.REACT_APP_BUGS_NAG_API_KEY || 'somerandomkey');
bugsnagClient.use(bugsnagReact, React)
const ErrorBoundary = bugsnagClient.getPlugin('react')

ReactDOM.render(
<ErrorBoundary FallbackComponent={FallBack}>
    <IntlProvider locale={language} messages={messages[language]}>
        <Provider store={store}>
            <App />
        </Provider>
    </IntlProvider>
</ErrorBoundary>,
document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
