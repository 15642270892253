import React, { Component } from 'react';
import MultiItemCarousel from '../../../components/MultiItemCarousel';
import starIcon from '../../../assets/wrp-icon-gold.svg';
import lodash from 'lodash';
import perforatedBorder from '../../../assets/perforated-border.svg';
import { Link } from "react-router-dom";

export default class VoucherCarousel extends Component{

    render(){
       return(
            <MultiItemCarousel>
                {
                this.props.vouchers.map((item,index)=>{
                    return(
                    <div key={"voucher-"+index}>
                        <div style={{padding:'15px'}}>
                        <Link to={`/voucher/${item.id}`}>
                            <div className="card mb-4 card--carousel-item card--carousel-item--vouchers">
                                <div className="card-body">
                                    <img src={item.web_image_url} alt={item.name} />
                                    <span className="delivery-type-label">{item.delivery_type}</span>
                                </div>
                                <div className="card-footer" style={{backgroundImage:`url(${perforatedBorder})`,backgroundRepeat:'repeat-x',backgroundPositionX:'-2px'}}>
                                    <div className="row">
                                        <div className="col-12">
                                            <p className="brand-name-label mb-0">{item.name}</p>
                                            <p className="mb-0">
                                            {
                                                lodash.range(item.winds_rating).map((item, index)=>{

                                                    return (
                                                        <img className="star" src={starIcon} alt="star" key={"voucher-rating-"+index}/>
                                                    )

                                                })
                                            }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    </div>
                    )
                })
                }
            </MultiItemCarousel>
       );
    }
    
}