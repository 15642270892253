import React, { Component } from "react";
import MultiItemCarousel from "../../../components/MultiItemCarousel";
import { Link } from "react-router-dom";
import bookmarkActive from "../../../assets/bookmark-active.svg";
import bookmarkInactive from "../../../assets/bookmark-inactive.svg";

export default class ShoppingSitesCarousel extends Component {
  toggleBookmark = (event, id, isBookmarked) => {
    event.preventDefault();
    this.props.onToggleBookmarkShoppingSites(
      id,
      isBookmarked,
      `/shopping-sites/${id}/bookmark`,
      "shoppingSites"
    );
  };

  render() {
    return (
      <MultiItemCarousel>
        {this.props.affiliate.map((item, index) => {
          return (
            <div key={"shopping-site-" + index}>
              <div style={{ padding: "15px" }}>
                <Link to={`/shopping-sites/${item.id}`}>
                  <div
                    className="card card--carousel-item card--carousel-item--shopping-sites"
                    style={{
                      borderRadius: "8px",
                      backgroundImage: `url(${item.cover_image_url})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "100% auto"
                    }}
                  >
                    {
                      <img
                        src={
                          item.bookmarked ? bookmarkActive : bookmarkInactive
                        }
                        className="bookmark"
                        alt=""
                        onClick={event =>
                          this.toggleBookmark(event, item.id, item.bookmarked)
                        }
                      />
                    }

                    <div className="card-body">
                      <div
                        className="logo"
                        style={{
                          borderRadius: "8px",
                          background:
                            "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.79) 66.41%, #FFFFFF 100%)"
                        }}
                      >
                        <img src={item.web_image_url} alt={item.name} />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          );
        })}
      </MultiItemCarousel>
    );
  }
}
