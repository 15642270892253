import React, { Component } from "react";
import { Link } from "react-router-dom";
import clientX from "../../../utils/clientX";
import lodash from "lodash";
import ContentLoader from "react-content-loader";
import walletVector from "../../../assets/empty-state-wallet.svg";
import { FormattedMessage } from "react-intl";
import filterIcon from "../../../assets/filter-icon.svg";
import Modal from "react-bootstrap/Modal";
export default class Wallet extends Component {
  radioOptions = [
    {
      value: 1,
      viewValue: "Credit"
    },
    {
      value: 0,
      viewValue: "Debit"
    }
  ];
  constructor(props) {
    super(props);
    this.state = {
      balance: null,
      isFetchingWalletBalance: false,
      transactions: [],
      isFetchingTransaction: false,
      filterValue: "",
      isFilterModalOpen: false
    };
  }

  onFilterClick = () => {
    this.setState(prevState => ({
      isFilterModalOpen: !prevState.isFilterModalOpen
    }));
  };

  componentDidMount() {
    this.setState(
      {
        isFetchingWalletBalance: true
      },
      () => {
        clientX.get("v1/wallet").then(response => {
          this.setState({
            balance: response.data.data.balance,
            isFetchingWalletBalance: false
          });
        });
      }
    );

    this.setState(
      {
        isFetchingTransaction: true
      },
      () => {
        this.fetchTransaction();
      }
    );
  }
  fetchTransaction = () => {
    clientX
      .get(`/wallet/transactions`, {
        params: {
          type: this.state.filterValue
        }
      })
      .then(response => {
        this.setState({
          transactions: response.data.data,
          isFetchingTransaction: false
        });
      });
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="container-fluid font-proxima-nova"
          style={{ backgroundColor: "#F2F6FA" }}
        >
          <div className="row pt-3 mb-3">
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <small>
                    <Link to="/">
                      <FormattedMessage
                        id="breadcrumbs.home"
                        defaultMessage="Home"
                      />
                    </Link>
                  </small>{" "}
                  /{" "}
                  <small>
                    <strong>
                      <FormattedMessage
                        id="wallet.wallet"
                        defaultMessage="Wallet"
                      />
                    </strong>
                  </small>
                </div>
              </div>
            </div>
          </div>

          <this.WalletBalance />
        </div>

        <this.WalletTransactions />
      </React.Fragment>
    );
  }

  WalletBalance = props => {
    return (
      <div className="row mt-3 mb-3">
        <div className="col-12">
          <div className="row mt-md-4 mb-md-4">
            <div className="col-12 col-md-6">
              <p className="mb-0">
                <FormattedMessage
                  id="wallet.wallet-balance"
                  defaultMessage="Wallet Balance"
                />
              </p>
              {this.state.isFetchingWalletBalance ? (
                <ContentLoader
                  speed={2}
                  height={20}
                  primaryColor="#F6F7F8"
                  secondaryColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="3" ry="3" width="150" height="20" />
                </ContentLoader>
              ) : (
                <h3>
                  <strong>₹ {this.state.balance}</strong>
                </h3>
              )}
            </div>
            <div className="col-6"></div>
          </div>
        </div>
      </div>
    );
  };

  onChange = item => {
    const { value } = item;
    this.setState(
      {
        filterValue: value
      },
      () => {
        this.fetchTransaction();
      }
    );
  };

  reset = () => {
    this.setState(
      {
        filterValue: ""
      },
      () => {
        this.fetchTransaction();
      }
    );
  };

  FiltersBlock = props => (
    <div className="col-3 border-right d-none d-md-block">
      <div className="row border-bottom align-items-center status">
        <div className="col-md-6 pl-0">
          <h5 className="font-proxima-nova-bold">
            {" "}
            <FormattedMessage id="general.filters" defaultMessage="Filters" />
          </h5>
        </div>
        <div className="col-md-6 pr-0">
          <button
            className="btn btn-outline float-right winds-link-color"
            onClick={this.reset}
          >
            <span>
              {" "}
              <FormattedMessage id="general.reset" defaultMessage="Reset" />
            </span>
          </button>
        </div>
      </div>
      <div className="row align-items-center mt-4 pb-5 status">
        <this.Filters />
      </div>
    </div>
  );

  MobileFilters = props => {
    return (
      <div className="col-md-12 pr-0 pb-4">
        {this.radioOptions.map((item, index) => {
          return (
            <div className="row mt-3" key={index}>
              <div className="col-9 winds-grey-color">{item.viewValue}</div>
              <div className="col-3 text-center">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    id={item.value}
                    name="filter"
                    onChange={() => this.onChange(item)}
                    checked={this.state.filterValue === item.value}
                    value={item.viewValue}
                  />
                  <label
                    htmlFor={item.value}
                    className="custom-control-label"
                  ></label>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  Filters = props => {
    return (
      <div className="col-md-12 pr-0 pl-0">
        {this.radioOptions.map((item, index) => {
          return (
            <div className="row mt-3" key={index}>
              <div className="col-md-9 winds-grey-color">{item.viewValue}</div>
              <div className="col-md-3 text-center">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    id={item.viewValue}
                    name="wallet-filter"
                    onChange={() => this.onChange(item)}
                    checked={this.state.filterValue === item.value}
                    value={item.viewValue}
                  />
                  <label
                    htmlFor={item.viewValue}
                    className="custom-control-label"
                  ></label>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  WalletTransactions = props => {
    return (
      <div className="container-fluid mb-3 mt-md-4 mb-md-4 font-proxima-nova">
        {this.state.isFetchingTransaction ? (
          <React.Fragment>
            {lodash.range(4).map(index => {
              return (
                <div className="row" key={index}>
                  <div className="col-12">
                    <div
                      className="box shine mb-3 mb-md-4"
                      style={{ height: "100px" }}
                    ></div>
                  </div>
                </div>
              );
            })}
          </React.Fragment>
        ) : 0 === this.state.transactions.length ? (
          <div className="row">
            <div className="col-12 text-center">
              <img
                className="wallet-vector"
                src={walletVector}
                alt="transactions"
              />
              <p className="mt-2">
                <strong>You don't have any transactions.</strong>
              </p>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-12 text-right d-block d-md-none">
                  <button
                    className="btn text-primary"
                    onClick={this.onFilterClick}
                  >
                    <img src={filterIcon} alt="" className="filter-icon mr-2" />
                    <FormattedMessage
                      id="general.filters"
                      defaultMessage="Filters"
                    />
                  </button>

                  <React.Fragment>
                    <Modal
                      show={this.state.isFilterModalOpen}
                      onHide={this.onFilterClick}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>
                          <FormattedMessage
                            id="general.filters"
                            defaultMessage="Filters"
                          />
                        </Modal.Title>
                      </Modal.Header>

                      <Modal.Body>
                        <this.MobileFilters />
                      </Modal.Body>
                      <Modal.Footer>
                        <div className="row w-100">
                          <div className="col-6">
                            <button
                              className="w-100 btn btn-outline-primary"
                              onClick={this.onFilterClick}
                            >
                              <FormattedMessage
                                id="general.apply"
                                defaultMessage="Apply"
                              />
                            </button>
                          </div>
                          <div className="col-6">
                            <button
                              className="btn w-100 btn-outline-primary"
                              onClick={() => {
                                this.onFilterClick();
                                this.reset();
                              }}
                            >
                              <FormattedMessage
                                id="general.reset"
                                defaultMessage="Reset"
                              />
                            </button>
                          </div>
                        </div>
                      </Modal.Footer>
                    </Modal>
                  </React.Fragment>
                </div>
              </div>
              <div className="row">
                <this.FiltersBlock />
                <div className="col-12 col-md-9 main-content">
                  {this.state.transactions.map((item, index) => {
                    return (
                      <div
                        className="card mb-3"
                        style={{
                          boxShadow: "0 4px 14px 0 rgba(0,0,0,0.08)",
                          borderRadius: "8px",
                          border: "none"
                        }}
                        key={index}
                      >
                        <div className="card-body">
                          <div className="row">
                            <div className="col-12 col-md-5">
                              <p className="mb-0">
                                <small>
                                  <FormattedMessage
                                    id="wallet.description"
                                    defaultMessage="DESCRIPTION"
                                  />
                                </small>
                              </p>
                              <p className="mb-0">{item.description}</p>
                            </div>
                            <div className="col-12 col-md-5">
                              <p className="mb-0 mt-2 mt-md-0">
                                <small>
                                  <FormattedMessage
                                    id="wallet.date"
                                    defaultMessage="DATE"
                                  />
                                </small>
                              </p>
                              <p className="mb-0">
                                {1 === parseInt(item.type)
                                  ? "Debited at "
                                  : "Credited at "}
                                {item.date} {item.time}
                              </p>
                            </div>
                            <div className="col-12 col-md-2">
                              <p className="mb-0 mt-2 mt-md-0">
                                <small>
                                  <FormattedMessage
                                    id="general.amount"
                                    defaultMessage="Amount"
                                  />
                                </small>
                              </p>
                              <p className="mb-0">
                                <strong>₹ {item.amount}</strong>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
}
