import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
const WrpDetails = props => {
  return (
    <div className="container-fluid font-proxima-nova">
      <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
        <div className="col-12">
          <small>
            <Link to="/">
              <FormattedMessage id="breadcrumbs.home" defaultMessage="Home" />
            </Link>{" "}
            /{" "}
            <strong>
              <FormattedMessage id="wrp.details" defaultMessage="WRP Details" />
            </strong>
          </small>
        </div>
      </div>
      <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
        <div className="col-12">
          <h3 className="font-weight-bold"><FormattedMessage id="wrp.full-form" defaultMessage="Winds Reward Points"/> (WRP)</h3>
          <p>
          <FormattedMessage id="wrp.explaination" defaultMessage="WRP stands for WINDS Rewards Points. Every rupee spent on any kind
            of purchase either on Online Sites / Offline Retailers/ Utility Bill
            Payments/ Gift Vouchers entitles the Customer to collect WRPs. On
            collection of 10000 WRPs the Customer gets a Coupon which gets
            registered automatically for the monthly draw. The monthly draw can
            make a Customer win Crores of rupees every month without doing
            anything or spending anything extra." />
          </p>

          <h3 className="font-weight-bold"><FormattedMessage id="wrp.coupons-heading" defaultMessage="Coupons"/></h3>
          <p>
          <FormattedMessage id="wrp.coupons-body1" defaultMessage="On collection of 10000 WRPs the Customer gets a Coupon and this
            Coupon gets eligible for the next month’s lucky draw. So if you
            spent 20000 in a month you will get 2 coupons and twice the
            opportunity to win in the next month’s draw."/>
          </p>
          <p>
          <FormattedMessage id="wrp.coupons-body2" defaultMessage="The 10000 WRPs ( WINDS Reward Points) collected by the Customer on
            their regular shopping through the WINDS App are converted into a
            Coupon and this is an automated process."/>
          </p>
          <p>
          <FormattedMessage id="wrp.coupons-body3" defaultMessage="Once the Coupon is generated on the collection of 10000 WRPs it get
            entitled for the next month’s lucky draw and it will make the
            Customer eligible to participate in the Monthly Draw Of Luck and
            gives them the opportunity to Win Crores of Rupees as Cash Rewards
            and a lot of gifts every month."/>
          </p>
          <h3 className="font-weight-bold">The Lucky Draw</h3>
          <p>
            The Lucky Draw is a monthly event and any Customer who collects
            10000 WRPs gets a Coupon which makes him/her eligible for the next
            month’s lucky draw. Lacs of Customers get an opportunity to win lacs
            of cash rewards from an Initial Pool of Rs 100 crore which will
            gradually go to Rs 500 crore subject to the growth of the business
            and even more depending on certain market conditions. The first
            prize in the Lucky Draw is 20 crore and there are lacs of other
            prizes and gifts as well worth crores. This is a monthly draw.
          </p>

          <h3 className="font-weight-bold">
            <FormattedMessage id="wrp.how-to-earn" defaultMessage="Home" />
          </h3>
          <p>
            The more you buy the more WRPs you earn on your shopping and the
            closer you get to earn 10000 WRPs. Ex : if a customer buys a pair of
            shoes from Flipkart worth 5000 he gets entitled to get 5000 WRPs
            which are Provisional in nature when the transaction happens and get
            converted into final WRPs once we get the approval from Flipkart
            after the Refund period gets over and they pay the commission.
            </p>
            <p>
              So if you spend 50000 in a month you get 5 coupons and hence your
              opportunity to win the Cash Reward increases 5 times.
            </p>
            <p>
              If a Customer discovers a Nearby Partner on the App and does
              shopping at the Partner’s outlet - he has to just submit the bill
              of shopping. Once the partner approves it, WRPs will be credited
              to the user profile. Better Partner Ratings – gets you more WRPs
              per rupee spent.
            </p>
            <p>
              if a Customer purchases Gift Vouchers through WINDS app he /she is
              entitled to get WRPs worth the value of the Gift Vouchers. If
              somebody buys Shoppers Stop Vouchers worth Rs 2000 they will get
              2000 WRPs and a digital Gift Voucher in their mail.
            </p>
         
          <p>
            Buying through shopping sites is easy as 1-2-3. Customers can choose
            to shop from a wide range of famous e-commerce websites. 1. Once you
            click on a shopping site, read the terms &amp; conditions, which
            clearly explains which products are inclusive/exclusive of WRPs. It
            also tells how much WRPs you earn for every rupee spent along with
            Average Hold Time for WRP credits. 2. Once you understand the
            details – click on “Accept Terms &amp; Visit Site”. This will take
            you to respective Shopping Site website/app. Purchase your favourite
            items normally. 3. Once you are done shopping, come back to WINDS
            App and upload your Bill/Screenshot to claim WRPs against the Total
            Bill Amount.
          </p>
        </div>
      </div>
    </div>
  );
};
export default injectIntl(WrpDetails);
