import React, { Component } from "react";
import clientX from "../../../utils/clientX";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setQuantity } from "../actions";
import classNames from "classnames";
import update from "immutability-helper";
import { FormattedMessage, injectIntl } from "react-intl";

class Cart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cart: [],
      billing_name: {
        value: null,
        isValid: false,
        error: "",
        isPristine: true
      },
      billing_email: {
        value: null,
        isValid: false,
        error: "",
        isPristine: true
      },
      billing_mobile: {
        value: null,
        isValid: false,
        error: "",
        isPristine: true
      },
      shipping_name: {
        value: null,
        isValid: false,
        error: "",
        isPristine: true
      },
      shipping_email: {
        value: null,
        isValid: false,
        error: "",
        isPristine: true
      },
      shipping_mobile: {
        value: null,
        isValid: false,
        error: "",
        isPristine: true
      },
      shipping_same_as_billing: false,
      formValid: false
    };
  }

  componentDidMount() {
    clientX
      .post("/vouchers/cart", {
        denomination_id: this.props.denomination.id,
        denomination_value: this.props.denomination.value,
        quantity: this.props.quantity
      })
      .then(response => {
        this.setState({
          cart: response.data.data
        });
      });
  }

  onShippingCheckboxChange = e => {
    const isChecked = e.target.checked;

    this.setState(
      {
        shipping_same_as_billing: isChecked
      },
      () => {
        this.validateForm();
      }
    );
  };

  onInitOrder = () => {
    let that = this;

    clientX
      .post("/vouchers/initiate-order", {
        denomination_id: this.props.denomination.id,
        denomination_value: this.props.denomination.value,
        quantity: this.props.quantity,
        billing_name: this.state.billing_name.value,
        billing_email: this.state.billing_email.value,
        billing_mobile: this.state.billing_mobile.value,
        shipping_name: this.state.shipping_name.value,
        shipping_email: this.state.shipping_email.value,
        shipping_mobile: this.state.shipping_mobile.value,
        shipping_same_as_billing: this.state.shipping_same_as_billing ? 1 : 0
      })
      .then(response => {
        new window.Razorpay({
          key: "rzp_live_3oRYrd7ePeI7Ao",
          order_id: response.data.data.payment_gateway_order_id,
          handler: function(pgResponse) {
            clientX
              .post(`/vouchers/${response.data.data.order_id}/fulfil-order`, {
                payment_gateway_order_id:
                  response.data.data.payment_gateway_order_id,
                razorpay_order_id: pgResponse.razorpay_order_id,
                razorpay_payment_id: pgResponse.razorpay_payment_id,
                razorpay_signature: pgResponse.razorpay_signature
              })
              .then(response => {
                that.props.history.push(
                  `/vouchers/orders/${response.data.data.order_id}`
                );
              })
              .catch(error => {});
          }
        }).open();
      })
      .catch(error => {});
  };

  handleUserInput = e => {
    const name = e.target.name;
    const value = e.target.value;

    const newData = update(this.state, {
      [name]: {
        value: { $set: value },
        isPristine: { $set: false }
      }
    });

    this.setState(newData, () => {
      this.validateField(name, value);
    });
  };

  validateField(fieldName, value) {
    let state = this.state;

    switch (fieldName) {
      case "billing_name":
        state.billing_name.isValid = value.length >= 5;
        state.billing_name.error = state.billing_name.isValid
          ? ""
          : "Billing Name is invalid";
        break;
      case "billing_mobile":
        state.billing_mobile.isValid = null !== value.match(/^[6-9]\d{9}$/);
        state.billing_mobile.error = state.billing_mobile.isValid
          ? ""
          : "Billing Mobile is invalid";
        break;
      case "billing_email":
        state.billing_email.isValid =
          null !== value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        state.billing_email.error = state.billing_email.isValid
          ? ""
          : "Billing Email is invalid";
        break;
      case "shipping_name":
        state.shipping_name.isValid = value.length >= 5;
        state.shipping_name.error = state.shipping_name.isValid
          ? ""
          : "Shipping Name is invalid";
        break;
      case "shipping_mobile":
        state.shipping_mobile.isValid = null !== value.match(/^[6-9]\d{9}$/);
        state.shipping_mobile.error = state.shipping_mobile.isValid
          ? ""
          : "Shipping Mobile is invalid";
        break;
      case "shipping_email":
        state.shipping_email.isValid =
          null !== value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        state.shipping_email.error = state.shipping_email.isValid
          ? ""
          : "Shipping Email is invalid";
        break;
      default:
        break;
    }

    this.setState(state, this.validateForm);
  }

  validateForm() {
    if (this.state.shipping_same_as_billing) {
      this.setState({
        formValid:
          this.state.billing_name.isValid &&
          this.state.billing_email.isValid &&
          this.state.billing_mobile.isValid
      });

      return;
    }

    this.setState({
      formValid:
        this.state.billing_name.isValid &&
        this.state.billing_email.isValid &&
        this.state.billing_mobile.isValid &&
        this.state.shipping_name.isValid &&
        this.state.shipping_email.isValid &&
        this.state.shipping_mobile.isValid
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
          <div className="col-12">
            <div className="row align-items-center">
              <div className="col-12">
                <small>
                  <Link to="/">
                    <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    />
                  </Link>
                </small>{" "}
                /{" "}
                <small>
                  <Link to="/vouchers">
                    <FormattedMessage
                      id="home.vouchers"
                      defaultMessage="Vouchers"
                    />
                  </Link>
                </small>{" "}
                /{" "}
                <small>
                  <strong>
                    <FormattedMessage
                      id="vouchers.buy-vouchers"
                      defaultMesage="Buy Voucher"
                    />
                  </strong>
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-md-8">
                <this.BillingDetails />
                <this.ShippingDetails />
              </div>
              <div className="col-12 col-md-4">
                <this.Controls />
                {this.state.formValid ? (
                  <button
                    className="btn btn-primary btn-block mt-3 mt-md-4 mb-3 mb-md-4"
                    onClick={this.onInitOrder}
                  >
                    <FormattedMessage id="general.pay-now" />
                  </button>
                ) : (
                  <button
                    className="btn btn-primary btn-block mt-3 mt-md-4 mb-3 mb-md-4"
                    disabled
                  >
                    <FormattedMessage id="general.pay-now" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  BillingDetails = props => {
    return (
      <div className="card mb-3 mb-md-4">
        <div className="card-header">
          <h6 className="font-proxima-nova-bold mb-0">
            <FormattedMessage id="vouchers.billing-details" />
          </h6>
        </div>
        <div className="card-body">
          <div className="form-group">
            <label>
              <FormattedMessage id="vouchers.billing-name" />
            </label>
            <input
              type="text"
              name="billing_name"
              value={this.state.billing_name.value}
              className={classNames("form-control", {
                "is-valid": this.state.billing_name.isValid,
                "is-invalid":
                  !this.state.billing_name.isPristine &&
                  !this.state.billing_name.isValid
              })}
              onChange={event => {
                this.handleUserInput(event);
              }}
            />
          </div>
          <div className="form-group">
            <label>
              <FormattedMessage id="vouchers.billing-mobile" />
            </label>
            <input
              type="text"
              name="billing_mobile"
              value={this.state.billing_mobile.value}
              className={classNames("form-control", {
                "is-valid": this.state.billing_mobile.isValid,
                "is-invalid":
                  !this.state.billing_mobile.isPristine &&
                  !this.state.billing_mobile.isValid
              })}
              onChange={event => {
                this.handleUserInput(event);
              }}
            />
          </div>
          <div className="form-group">
            <label>
              <FormattedMessage id="vouchers.billing-email" />
            </label>
            <input
              type="text"
              name="billing_email"
              value={this.state.billing_email.value}
              className={classNames("form-control", {
                "is-valid": this.state.billing_email.isValid,
                "is-invalid":
                  !this.state.billing_email.isPristine &&
                  !this.state.billing_email.isValid
              })}
              onChange={event => {
                this.handleUserInput(event);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  ShippingDetails = props => {
    return (
      <div className="card mb-3 mb-md-4">
        <div className="card-header">
          <div className="row align-items-center">
            <div className="col-12 col-md-8">
              <h6 className="font-proxima-nova-bold mb-0">
                <FormattedMessage id="vouchers.shipping-details" />
              </h6>
            </div>
            <div className="col-12 text-md-right col-md-4">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  onChange={this.onShippingCheckboxChange}
                />
                <label class="form-check-label">
                  <FormattedMessage id="vouchers.same-as-billing-details" />
                </label>
              </div>
            </div>
          </div>
        </div>

        {!this.state.shipping_same_as_billing && (
          <div className="card-body">
            <div className="form-group">
              <label>
                <FormattedMessage id="vouchers.shipping-name" />
              </label>
              <input
                type="text"
                name="shipping_name"
                value={this.state.shipping_name.value}
                className={classNames("form-control", {
                  "is-valid": this.state.shipping_name.isValid,
                  "is-invalid":
                    !this.state.shipping_name.isPristine &&
                    !this.state.shipping_name.isValid
                })}
                onChange={event => {
                  this.handleUserInput(event);
                }}
              />
            </div>
            <div className="form-group">
              <label>
                <FormattedMessage id="vouchers.shipping-mobile" />
              </label>
              <input
                type="text"
                name="shipping_mobile"
                value={this.state.shipping_mobile.value}
                className={classNames("form-control", {
                  "is-valid": this.state.shipping_mobile.isValid,
                  "is-invalid":
                    !this.state.shipping_mobile.isPristine &&
                    !this.state.shipping_mobile.isValid
                })}
                onChange={event => {
                  this.handleUserInput(event);
                }}
              />
            </div>
            <div className="form-group">
              <label>
                <FormattedMessage id="vouchers.shipping-email" />
              </label>
              <input
                type="text"
                name="shipping_email"
                value={this.state.shipping_email.value}
                className={classNames("form-control", {
                  "is-valid": this.state.shipping_email.isValid,
                  "is-invalid":
                    !this.state.shipping_email.isPristine &&
                    !this.state.shipping_email.isValid
                })}
                onChange={event => {
                  this.handleUserInput(event);
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  Controls = props => {
    return (
      <div
        className="card mb-3 mb-md-4"
        style={{
          backgroundColor: "#F8F9FA",
          borderRadius: "8px",
          border: "none"
        }}
      >
        <div className="card-body" style={{ borderRadius: "8px 8px 0 0" }}>
          <div className="row align-items-center">
            <div className="col-8">
              <img
                style={{
                  height: "50px",
                  background: "#fff",
                  borderRadius: "8px"
                }}
                src={this.props.voucher.image_url}
                alt={this.props.voucher.name}
              />
              <p
                style={{ fontSize: "15px", display: "inline" }}
                className="ml-2"
              >
                <strong>{this.props.voucher.name}</strong>
              </p>
            </div>
            <div className="col-4">
              <div className="align-middle">
                <p className="mb-0">
                  <strong>{this.props.denomination.value}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="card-footer pt-4 pb-4"
          style={{
            border: "none",
            borderRadius: "0 0 8px 8px",
            borderTop: "solid 1px #E8E8E8"
          }}
        >
          {this.state.cart.map((item, index) => {
            return (
              <div className="row mb-2">
                <div className="col-6">
                  <p className="mb-0">{item.key}</p>
                </div>
                <div className="col-6 text-right">
                  <p className="mb-0">
                    <strong>{item.value}</strong>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
}

function mapStateToProps(state) {
  return {
    voucher: state.voucher.voucher,
    denomination: state.voucher.denomination,
    quantity: state.voucher.quantity
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setQuantity: quantity => {
      dispatch(setQuantity(quantity));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Cart));
