import React, { Component } from "react";
import { Link } from "react-router-dom";
import clientX from "../../../utils/clientX";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import vectorImg from "../../../assets/nearby-partner-bill-upload-vector.svg";
import InlineLoader from "../../../components/InlineLoader";
import { FormattedMessage } from "react-intl";

export default class BillUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      partnerCode: null,
      partner: null,
      isLoadingPartner: false,
      amount: null,
      bill: null,
      file_extension: null,
      file_type: null,
      isUploading: false
    };
  }

  componentDidMount() {
    const partnerCode = this.props.match.params.id;
    if (partnerCode) {
      this.setState({ partnerCode: partnerCode }, () => {
        this.getPartner(partnerCode);
      });
    }
  }

  onGetPartner = () => {
    if (null === this.state.partnerCode || 0 === this.state.partnerCode.length) {
      toast.error("Enter Partner Code to proceed.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });

      this.setState({
        isLoadingPartner: false
      });

      return;
    }

    this.setState(
      {
        isLoadingPartner: true
      },
      () => {
        this.getPartner(this.state.partnerCode);
      }
    );
  };

  getPartner = code => {
    clientX
      .post(`/nearby-partners/scan`, {
        partner_code: code
      })
      .then(response => {
        let data = response.data.data;

        this.setState({
          partner: data,
          isLoadingPartner: false
        });
      })
      .catch(error => {
        toast.error(error.response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });

        this.setState({
          isLoadingPartner: false
        });
      });
  };

  onClearPartner = () => {
    this.setState({
      partnerCode: null,
      partner: null,
      amount: null
    });
  };

  onSelectBill = event => {
    this.setState({
      bill: event.target.files[0]
    });
  };

  onUpload = () => {
    let amount = this.state.amount;

    if (null == this.state.bill) {
      clientX
        .request({
          url: `/nearby-partners/bills/${this.state.partner.id}`,
          method: "post",
          data: {
            bill_amount: amount
          }
        })
        .then(response => {
          toast.success("You bill was uploaded successfully.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });

          this.setState({
            amount: null,
            bill: null,
            isUploading: false
          });
        })
        .catch(error => {
          toast.error(error.response.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });

          this.setState({
            isUploading: false
          });
        });

      return;
    }

    let file = this.state.bill;
    let file_name = file.name;
    let extension = file.name.split(".").pop();
    let file_type = file.type;

    if (
      extension.toLowerCase() !== "jpg" &&
      extension.toLowerCase() !== "png" &&
      extension.toLowerCase() !== "jpeg"
    ) {
      toast.error("Only JPG, JPEG and PNG formats are supported", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return;
    }

    clientX
      .request({
        url: `/nearby-partners/bills/pre-signed-url`,
        method: "post",
        data: {
          file_name: file_name,
          file_extension: extension,
          mime_type: file_type
        }
      })
      .then(response => {
        let key = response.data.key;

        axios.put(response.data.url, file).then(response => {
          clientX
            .request({
              url: `/nearby-partners/bills/${this.state.partner.id}`,
              method: "post",
              data: {
                bill_amount: amount,
                file: key
              }
            })
            .then(response => {
              toast.success("You bill was uploaded successfully.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
              });

              this.setState({
                amount: null,
                bill: null,
                isUploading: false
              });
            })
            .catch(error => {
              toast.error(error.response.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
              });

              this.setState({
                isUploading: false
              });
            });
        });
      });
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
          <div className="col-12">
            <div className="row align-items-center">
              <div className="col-12">
                <small>
                  <Link to="/">
                    <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    />
                  </Link>{" "}
                  /{" "}
                  <strong>
                    <FormattedMessage
                      id="nearby-partners.upload-bill-for-nearby-partner"
                      defaultMessage="Upload Bill for Nearby Partner"
                    />
                  </strong>
                </small>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-3 mb-md-4">
          <div className="col-12">
            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <this.SelectPartner />

                    <div className="form-group">
                      <label>
                        <FormattedMessage
                          id="nearby-partners.enter-amount"
                          defaultMessage="Enter Amount"
                        />
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        value={
                          null == this.state.amount ? "" : this.state.amount
                        }
                        onChange={event => {
                          this.setState({ amount: event.target.value });
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <label><FormattedMessage
                            id="nearby-partner.bill-image"
                            defaultMessage="Bill Image"
                          /></label>
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          onChange={this.onSelectBill}
                          accept=".png, .jpg, .jpeg"
                        />
                        <label className="custom-file-label">
                        <FormattedMessage
                            id="shopping-sites.upload-bill-image"
                            defaultMessage="Upload the Bill Image"
                          />
                        </label>
                      </div>
                    </div>

                    {this.state.isUploading ? (
                      <div className="text-center">
                        <InlineLoader />
                      </div>
                    ) : (
                      <button
                        className="btn btn-primary btn-block mt-3"
                        disabled={
                          null == this.state.partner ||
                          null == this.state.amount
                        }
                        onClick={this.onUpload}
                      >
                        <FormattedMessage
                          id="general.upload"
                          defaultMessage="Upload"
                        />
                      </button>
                    )}

                    <ToastContainer
                      position="top-center"
                      autoClose={5000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnVisibilityChange
                      draggable
                      pauseOnHover
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 text-center">
                <img
                  className="mt-3 mt-md-0 nearby-partner-bill-upload-vector"
                  src={vectorImg}
                  alt="upload nearby partner bill"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  SelectPartner = props => {
    return (
      <React.Fragment>
        <div className="form-group">
          <label>
            <FormattedMessage
              id="nearby-partners.enter-partner-code"
              defaultMessage="Enter Partner Code"
            />
          </label>
          {null == this.state.partner ? (
            <div className="input-group mb-3">
              <input
                type="number"
                className="form-control"
                value={
                  null == this.state.partnerCode ? "" : this.state.partnerCode
                }
                onChange={event => {
                  this.setState({ partnerCode: event.target.value });
                }}
              />
              {this.state.isLoadingPartner ? (
                <div
                  className="input-group-append"
                  style={{ cursor: "pointer" }}
                >
                  <span className="input-group-text">
                    <InlineLoader />
                  </span>
                </div>
              ) : (
                <div
                  className="input-group-append"
                  onClick={this.onGetPartner}
                  style={{ cursor: "pointer" }}
                >
                  <span className="input-group-text">
                    <FormattedMessage
                      id="nearby-partners.get-partner"
                      defaultMessage="Get Partner"
                    />
                  </span>
                </div>
              )}
            </div>
          ) : (
            <div className="input-group mb-3">
              <input type="text" className="form-control" disabled  value={
                  null == this.state.partnerCode ? "" : this.state.partnerCode
                }/>
              <div
                className="input-group-append"
                onClick={this.onClearPartner}
                style={{ cursor: "pointer" }}
              >
                <span className="input-group-text">
                  <FormattedMessage id="general.clear" defaultMessage="Clear" />
                </span>
              </div>
            </div>
          )}
        </div>
        {this.state.partner && (
          <div>
            <p>
              <strong>{this.state.partner.name}</strong>
            </p>
          </div>
        )}
      </React.Fragment>
    );
  };
}
