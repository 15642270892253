import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import rewardCardImg from "../../../assets/rewards-card.svg";

class OfferDetail extends Component {
  render() {
    return (
      <div className="container-fluid font-proxima-nova">
        <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
          <div className="col-12">
            <div className="row align-items-center">
              <div className="col-12">
                <small>
                  <Link to="/">
                    <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    />
                  </Link>{" "}
                  /{" "}<Link to="/rewards">
                    <FormattedMessage
                      id="breadcrumbs.rewards"
                      defaultMessage="Your rewards"
                    />
                  </Link>{" "}
                  /{" "}
                  <strong>
                    <FormattedMessage
                      id="rewards.rewards.details"
                      defaultMessage="Offer Details"
                    />
                  </strong>
                </small>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-12">
            <h2 className="font-proxima-nova-bold d-inline-block">
              <FormattedMessage
                id="home.offerDetails"
                defaultMessage="Offer Details"
              />
            </h2>
          </div>
        </div>

        <div className="row mt-md-3 mb-5 offer-detail">
          <div className="col-md-8 col-12 mt-5 mt-md-0">
              <h5>Let's get started</h5>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
              <h5>Offer Validity</h5>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
              <h5>Terms &amp; Conditions</h5>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
          </div> 
          <div className="col-12 col-md-4">
            <div className="offer-card d-flex w-100">
                <div className="card-red rewards-card">
                  <div className="reward-icon">
                    <img
                      src={rewardCardImg}
                      alt="rewards icon."
                      className="d-block"
                    />
                  </div>
                </div>
                <div className="border mb-3"></div>
                <h4>Get a scratch card worth upto &#8377; 5000!</h4>
                <p className="text-secondary">
                  Pay your utility bills for 3 different services on WINDS &amp;
                  earn upto &#8377; 5000 worth vouchers!
                </p>
                <small className="text-secondary"><i class="far fa-calendar"></i> &nbsp;Expires on 31 March 2020, 11:30 PM</small>
              </div>
              <button className="btn btn-outline-primary home-btn">Copy Code: AZ2000WI12390</button>
          </div> 
        </div>

      </div>
    );
  }
}
export default OfferDetail;
