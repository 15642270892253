import React, { Component } from 'react';
import { Link } from "react-router-dom";
import starIcon from '../../../assets/wrp-icon-gold.svg';
import lodash from 'lodash';
import Slider from "react-slick";
import DefaultStoreImage from '../../../assets/default-store-image.svg';

export default class NearbyPartnerCarousel extends Component{

    render(){

        const settings = {
            arrows:true,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            responsive: [
                {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows:false
                }
                }
            ]
        };

       return(
        <Slider {...settings}>
        {
            this.props.partners.map((item,index)=>{
                return(
                <div key={"partner-"+index}>
                    <div style={{padding:'15px'}}>
                        <Link to={`/partner-profile/${item.id}`}>
                            <div className="card mb-4 card--partner">
                                {
                                    (null != item.store_image)
                                    ?
                                    (
                                        <div className="card-body" style={{backgroundColor:'#F8F9FA', backgroundImage:`url(${item.store_image})`,backgroundSize:'cover',height:'200px'}}>
                                
                                        </div>
                                    )
                                    :
                                    (
                                        <div className="card-body" style={{backgroundColor:'#F8F9FA', backgroundImage: `url(${DefaultStoreImage})`, backgroundSize:'contain', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', height:'200px'}}>
                                
                                        </div>
                                    )
                                }
                                <div className="card-footer">
                                    <div className="row">
                                        <div className="col-9">
                                            <p className="mb-0"><small>{item.category}</small></p>
                                        </div>
                                        <div className="col-3 text-right">
                                            <span style={{backgroundColor:'#009D51',borderRadius: '2px', color:'#fff',padding:'3px 6px',fontSize: '10px'}}>{item.average_rating}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <p className="mb-0"><strong>{item.name}</strong></p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <p className="mb-0">
                                                {
                                                    lodash.range(item.winds_rating).map((item,index)=>{

                                                        return (
                                                            <img className="star" src={starIcon} alt="star" key={"partner-rating-"+index}/>
                                                        )

                                                    })
                                                }
                                            </p>
                                        </div>
                                        <div className="col-6 text-right">
                                                <span style={{color:'#666666',fontSize:'12px'}}>{item.store_distance} KM</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                )
            })
        }
        </Slider>
       );
    }
    
}

function NextArrow(props){
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, zIndex:2, right:'15px', background:'#fff',display: "block",borderRadius: "6px",boxShadow: "0 0 15px 0 rgba(0,0,0,0.13)",padding:"5px",height:"48px",width:"38px"}}
        onClick={onClick}
      >
      </div>
    );
  }
  
  function PrevArrow(props){
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, zIndex:2, left:'15px', background:'#fff',display: "block",borderRadius: "6px",boxShadow: "0 0 15px 0 rgba(0,0,0,0.13)",padding:"5px",height:"48px",width:"38px"}}
        onClick={onClick}
      />
    );
  }