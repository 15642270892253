import React, { Component } from 'react';
import { connect } from 'react-redux';
import {setLocation,setFormattedAddress} from '../actions';
import {getGeoLocation, getAddress, getPlace, getPlaceById} from '../utils/GeoLocation';
import Modal from 'react-bootstrap/Modal';
import {FormattedMessage,injectIntl} from 'react-intl';
import downCaret from '../assets/down-caret-blue.svg';

class LocationAddOn extends Component{

    constructor(props, context) {
        super(props, context);
    
        this.state = {
          show: false,
          predictions:[]
        };
    
        this.handleShow = () => {
          this.setState({ show: true });
        };
    
        this.handleHide = () => {
          this.setState({ show: false });
        };
    }

    componentDidMount(){

        this.props.onInit();

    }

    onSearch = (event) => {

        if(event.target.value.length < 3){
            return;
        }

        getPlace(event.target.value)
        .then((response)=>{

            this.setState({
                predictions: response.data.predictions
            });

        });

    }

    onSelectResult = (placesId) => {

        getPlaceById(placesId)
        .then((response)=>{

            this.setState({
                predictions:[]
            },()=>{

                this.props.onPlaceSelect(response.data.result);
                this.setState({ show: false });

            })

        }); 

    }

    render(){


        return(
            <React.Fragment>
               
                <span onClick={this.handleShow} style={{...this.props.style}}>
                    <i className="fas fa-map-marker-alt mr-1 align-middle"></i>
                    <span style={{ display:'inline-block', width:'120px',whiteSpace:'nowrap',overflow:'hidden', textOverflow:'ellipsis', fontSize:'13px', verticalAlign:'middle'}}>
                        {
                            (null === this.props.formatted_address)?
                            (
                                <FormattedMessage 
                                    id="navbar.fetching"
                                    defaultMessage="Fetching..."
                                    description="Fetching..."
                                    values={{ what: 'react-intl' }}
                                />
                            )
                            :
                            this.props.formatted_address
                        }
                    </span>
                    <img src={downCaret} style={{display:'inline'}} alt="down caret" />
                </span>

                <Modal 
                    size="lg"
                    show={this.state.show} 
                    onHide={this.handleClose}
                    >
                    <Modal.Header>
                        <Modal.Title>
                            <FormattedMessage id="navbar.search-location" defaultMessage="Search Location" />
                        </Modal.Title>
                        <button type="button" className="close" onClick={this.handleHide}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" placeholder={this.props.intl.formatMessage({id: 'navbar.search', defaultMessage:"Search"})} onKeyUp={this.onSearch}/>
                            <div className="input-group-append">
                                <span className="input-group-text"><i className="fas fa-search"></i></span>
                            </div>
                        </div>
                        <div>
                            <p style={{color:'#0078D7',cursor:'pointer'}} onClick={()=>{this.props.onInit();this.setState({show:false})}}>
                                <i className="fas fa-crosshairs"></i> <FormattedMessage id="navbar.use-current-location" defaultMessage="Use Current Location" />
                            </p>
                        </div>
                        <div>
                            {this.state.predictions.length > 0 && <p className="font-proxima-nova-bold mb-0" style={{fontSize:'17px', lineHeight: '17px'}}>Search Results</p>}
                            <ul className="list-group list-group-flush">
                                {
                                    this.state.predictions.map((item,index)=>{

                                        if(0 === index){
                                            return(
                                                <li className="list-group-item border-top-0 pl-1 pr-1" key={"places-result-"+index} onClick={()=>{this.onSelectResult(item.place_id)}}>
                                                    <p className="mb-0"><i className="fas fa-map-marker-alt mr-1" style={{color:'#797979'}}></i> {item.terms[0].value}</p>
                                                    <p className="mb-0" style={{marginLeft:'18px',color:'#797979'}}>{item.description}</p>
                                                </li>
                                            )
                                        }

                                        return(
                                            <li className="list-group-item pl-1 pr-1" key={"places-result-"+index} onClick={()=>{this.onSelectResult(item.place_id)}}>
                                                <p className="mb-0"><i className="fas fa-map-marker-alt mr-1" style={{color:'#797979'}}></i> {item.terms[0].value}</p>
                                                <p className="mb-0" style={{marginLeft:'18px',color:'#797979'}}>{item.description}</p>
                                            </li>
                                        )

                                    })
                                }
                            </ul>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={this.handleHide}><FormattedMessage id="navbar.close" defaultMessage="Close" /></button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        );
    }

}

function mapStateToProps(state) {
    return {
        formatted_address: state.location.formatted_address
    };
}

function mapDispatchToProps(dispatch) {

    return {

        onInit: () => {

            getGeoLocation()
            .then((geoLocation)=>{
              
                dispatch(setLocation(geoLocation.latitude, geoLocation.longitude));

                return getAddress(geoLocation.latitude, geoLocation.longitude);

            }).then((response)=>{
                
              if (response.data.results && response.data.results.length>0) {
                dispatch(setFormattedAddress(response.data.results[0].formatted_address));   
              }

            });

        },

        onPlaceSelect: (place) => {

            dispatch(setLocation(place.geometry.location.lat, place.geometry.location.lng));
            dispatch(setFormattedAddress(place.formatted_address));

        }

    }

}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LocationAddOn));