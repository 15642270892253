const INITIAL_STATE = {
  isFetchingVouchers: false,
  isFetchingVoucherCategories: false,
  isFetchingNextPage: false,
  categories: [],
  selectedCategories: [],
  vouchers: [],
  nextPage: 2,
  voucher: null,
  denomination: null,
  quantity: 1,
  isVoucherFilterModelOpen: false
};

function voucherReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "FETCHING_VOUCHERS":
      return {
        ...state,
        isFetchingVouchers: true,
        vouchers: [],
        nextPage: 2,
        isFetchingNextPage: false
      };
    case "FETCHING_VOUCHER_CATEGORIES":
      return {
        ...state,
        isFetchingVoucherCategories: true
      };
    case "FETCHING_NEXT_VOUCHER_PAGE":
      return {
        ...state,
        isFetchingNextPage: true
      };
    case "SET_VOUCHERS":
      return {
        ...state,
        vouchers: action.vouchers,
        isFetchingVouchers: false,
        nextPage: action.nextPage
      };
    case "ADD_VOUCHERS":
      return {
        ...state,
        vouchers: state.vouchers.concat(action.vouchers),
        isFetchingNextPage: false,
        nextPage: action.nextPage
      };
    case "SET_VOUCHER_CATEGORIES":
      return {
        ...state,
        categories: action.categories,
        isFetchingVoucherCategories: false
      };
    case "SELECT_CATEGORY_FOR_VOUCHERS":
      return {
        ...state,
        selectedCategories: [...state.selectedCategories, action.id]
      };
    case "UNSELECT_CATEGORY_FOR_VOUCHERS":
      return {
        ...state,
        selectedCategories: state.selectedCategories.filter(item => {
          return item !== action.id;
        })
      };
    case "VOUCHER_FILTER_CLICK":
      return {
        ...state,
        isVoucherFilterModelOpen: !state.isVoucherFilterModelOpen
      };
    case "RESET_VOUCHER_FILTER":
      return {
        ...state,
        selectedCategories: []
      };
    case "SET_VOUCHER":
      return {
        ...state,
        voucher: action.voucher
      };
    case "SET_DENOMINATION":
      return {
        ...state,
        denomination: action.denomination
      };
    case "SET_QUANTITY":
      return {
        ...state,
        quantity: action.quantity
      };

    case "SET_VOUCHER_LIST_AFTER_TOGGLE_BOOKMARK":
      return {
        ...state,
        vouchers: state.vouchers.map(v =>
          v.id === action.id ? { ...v, bookmarked: !v.bookmarked } : v
        )
      };
    default:
      return state;
  }
}

export default voucherReducer;
