import React, { Component } from "react";
import clientX from "../../../utils/clientX";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

export default class OrderDetail extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      order: null,
      isLoading: false
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;

    const {
      match: { params }
    } = this.props;

    clientX.get(`v1/bill-pay/${params.id}`).then(response => {
      if (this._isMounted) {
        let data = response.data.data;

        this.setState({
          order: data
        });

        if (2 === parseInt(data.status)) {
          this.setState(
            {
              isLoading: true
            },
            () => {
              setTimeout(() => {
                clientX.get(`v1/bill-pay/${params.id}`).then(response => {
                  let data = response.data.data;

                  this.setState({
                    order: data,
                    isLoading: false
                  });
                });
              }, 60000);
            }
          );
        }
      }
    });
  }

  render() {
    return (
      null !== this.state.order && (
        <div className="container-fluid">
          <div className="row mt-3 mb-3 mt-md-4 mb-md-4 align-items-center">
            <div className="col-12 text-md-center">
              <small>
                <Link to="/">
                  <FormattedMessage
                    id="breadcrumbs.home"
                    defaultMessage="Home"
                  />
                </Link>
              </small>{" "}
              /{" "}
              <small>
                <Link to="/orders">
                  <FormattedMessage id="navbar.orders" />
                </Link>
              </small>{" "}
              /{" "}
              <small>
                <strong>
                  <FormattedMessage id="orders.order" />
                </strong>
              </small>
            </div>
          </div>

          <div className="row mb-3 mb-md-4">
            <div className="col-12 col-md-6 offset-md-3">
              {this.state.isLoading && (
                <div className="text-center">
                  <div className="lds-ripple">
                    <div></div>
                    <div></div>
                  </div>
                </div>
              )}
              <div className="list-group">
                <div className="list-group-item list-group-item-action">
                  <p className="d-inline mb-0">
                    <FormattedMessage id="order.number" />{" "}
                    <strong>{this.state.order.number}</strong>
                  </p>
                </div>
                <div className="list-group-item list-group-item-action">
                  <div className="row align-items-center">
                    <div className="col-12 col-md-6">
                      <OrderStatusLabel order={this.state.order} />
                    </div>
                    <div className="col-12 col-md-6 text-md-right">
                      <p className="mb-0">
                        <FormattedMessage id="orders.your-order-number-is" />{" "}
                        <strong>{this.state.order.order_number}</strong>
                      </p>
                      <p className="mb-0">{this.state.order.created_at}</p>
                    </div>
                  </div>
                </div>
                <div className="list-group-item list-group-item-action">
                  <p className="mb-0">
                    <FormattedMessage id="order.we-received-payment-of" /> Rs.{" "}
                    <strong>{this.state.order.amount}</strong>
                  </p>
                </div>
                <div className="list-group-item list-group-item-action">
                  <p className="mb-0">
                    <strong>{this.state.order.wrp}</strong> WRPs{" "}
                    {this.state.order.wrp_credited ? "credited" : "are pending"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
}

function OrderStatusLabel(props) {
  if (props.order.fulfilled) {
    return (
      <p className="badge badge-success text-white mb-0">
        {props.order.statusText}
      </p>
    );
  }

  if (!props.order.fulfilled && props.order.payment_failed) {
    return (
      <p className="badge badge-danger text-white mb-0">
        {props.order.statusText}
      </p>
    );
  }

  return (
    <p className="badge badge-warning text-white mb-0">
      {props.order.statusText}
    </p>
  );
}
