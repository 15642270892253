import React, { Component } from 'react';
import { getRoute } from '../../../utils/routeBuilder';
import clientX from '../../../utils/clientX';
import { Link, withRouter } from "react-router-dom";
import classNames from 'classnames';
import notificationVector from  '../../../assets/empty-state-notifications.svg';
import moment from 'moment';
import update from 'immutability-helper';
import ContentLoader from 'react-content-loader';
import lodash from 'lodash';
import {FormattedMessage} from 'react-intl';

class Notifications extends Component{

    constructor(props){
        super(props);

        this.state = {
            notifications:[],
            isFetchingNotifications: false
        }
    }

    componentDidMount(){

        this.setState({
            isFetchingNotifications: true
        }, ()=>{

            clientX.get('v1/notifications')
            .then((response)=>{
            
                this.setState({
                    notifications: response.data,
                    isFetchingNotifications: false
                });

            });

        })

    }

    onMarkAsRead = (id, index) => {

        clientX.put(`v1/notifications/${id}/mark-as-read`)
        .then((response)=>{
            
            this.setState({
                notifications: update(this.state.notifications, {[index]: {read_at: {$set: '--'}}})
              })

        }); 

    }

    onMarkAllAsRead = () => {

        clientX.put(`v1/notifications/mark-all-as-read`)
        .then((response)=>{
            this.setState({
                isFetchingNotifications: true
            }, ()=>{
    
                clientX.get('v1/notifications')
                .then((response)=>{
                
                    this.setState({
                        notifications: response.data,
                        isFetchingNotifications: false
                    });
    
                });
    
            })
        });

    }

    onNotificationClick = (notification, index) => {

        this.onMarkAsRead(notification.id, index)

        let {segment, item_type, value } = notification.payload;

        let route = getRoute(segment, item_type, value);

        this.props.history.push(route);

    }

    render(){

        return(
            <div className="container-fluid">
                <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
                    <div className="col-12">
                        <div className="row align-items-center">
                            <div className="col-6">
                                <small><Link to="/"><FormattedMessage id="breadcrumbs.home" defaultMessage="Home" /></Link> / <strong><FormattedMessage id="notifications.notifications" defaultMessage="Notifications" /></strong></small>
                            </div>
                            <div className="col-6 text-right">
                                <small style={{color:'#1A94E8', cursor:'pointer'}} onClick={this.onMarkAllAsRead}><strong><FormattedMessage id="notifications.mark-all-as-read" defaultMessage="Mark all as read" /></strong></small>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-3 mb-md-4">
                    <div className="col-12">
                        {
                            (this.state.isFetchingNotifications)

                            ?

                            (
                                <this.Shimmer />
                            )
                            :
                            (

                                (this.state.notifications.length === 0)

                                ?
                            
                                (
                                    <this.EmptyState />
                                )

                                :

                                (
                                    <this.Notifications />
                                )
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }

    Shimmer = (props) => {

        return(
            <ul className="list-group">
                {
                    lodash.range(5).map((index) => {
                        return(
                            <li className="list-group-item" key={index}>
                                <div style={{height:'60px', overflow:'hidden'}}>
                                    <ContentLoader 
                                        height="60px"
                                        width="300px"
                                        speed={2}
                                        primaryColor="#f3f3f3"
                                        secondaryColor="#ecebeb"
                                    >
                                        <rect x="0" y="0" rx="3" ry="3" width="207" height="15" /> 
                                        <rect x="0" y="25" rx="3" ry="3" width="300" height="10" /> 
                                        <rect x="0" y="45" rx="3" ry="3" width="114" height="10" />
                                    </ContentLoader>
                                </div>
                            </li>
                        )
                    })
                }
            </ul>
        )

    }

    EmptyState = (props) => {

        return(
            <div className="row">
                <div className="col-12 text-center">
                    <img className="notifications-vector" src={notificationVector} alt="notifications" />
                    <p className="mt-3"><strong>You don't have any notifications.</strong></p>
                </div>
            </div>
        )

    }

    Notifications = (props) => {

        return(
            <div className="row align-items-center">
                <div className="col-12">
                    <ul className="list-group">
                    {
                        this.state.notifications.map((item, index) => {

                            return(
                                <li key={index} className={classNames('list-group-item',{'list-group-item-light':null !== item.read_at})}>
                                    <p className="mb-0" style={{position:'relative'}}>
                                        {
                                            (null==item.read_at) &&
                                            <span style={{position:'absolute', top:'6px', left:'-15px',backgroundColor:'#0070DE', width:'10px', height:'10px', borderRadius:'50%', display:'inline-block'}}></span>
                                        }
                                        <strong>{item.title}</strong>
                                    </p>
                                    <p className="mb-0" style={{'cursor':'pointer'}} onClick={()=>{this.onNotificationClick(item, index)}}>{item.description}</p>
                                    <p className="mb-2 mt-1">{moment(item.created_at).format('MMMM Do YYYY, h:mm A')}</p>
                                    {
                                        (null == item.read_at) &&
                                        <span className="mb-0" style={{cursor:'pointer', borderBottom:'dotted 2px #1A94E8'}} onClick={()=>{this.onMarkAsRead(item.id, index)}}><FormattedMessage id="notifications.mark-as-read" defaultMessage="Mark as read" /></span>
                                    }
                                </li>           
                            )

                        })
                    }
                    </ul>
                </div>
            </div>
        )

    }

}

export default withRouter(Notifications);