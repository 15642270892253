import React, { Component } from "react";
import MultiItemCarousel from "../../../components/MultiItemCarousel";
import wrpIcon from "../../../assets/wrp-icon-gold.svg";
import lodash from "lodash";
import perforatedBorder from "../../../assets/perforated-border.svg";
import { Link } from "react-router-dom";
import bookmarkActive from "../../../assets/bookmark-active.svg";
import bookmarkInactive from "../../../assets/bookmark-inactive.svg";
import { FormattedMessage } from "react-intl";
import lightningIcon from "../../../assets/lightning-icon.svg";
import deliveryWithDelayIcon from "../../../assets/delivery-van-icon.svg";

export default class VoucherCarousel extends Component {
  toggleBookmark = (event, id, isBookmarked) => {
    event.preventDefault();
    this.props.onToggleBookmarkVouchers(
      id,
      isBookmarked,
      `vouchers/${id}/bookmark`,
      "voucher"
    );
  };
  render() {
    return (
      <MultiItemCarousel>
        {this.props.vouchers.map((item, index) => {
          return (
            <div key={"voucher-" + index}>
              <div style={{ padding: "15px" }}>
                <Link to={`/voucher/${item.id}`}>
                  <div className="card card--carousel-item card--carousel-item--vouchers">
                    {
                      <img
                        src={
                          item.bookmarked ? bookmarkActive : bookmarkInactive
                        }
                        className="bookmark"
                        alt=""
                        onClick={event =>
                          this.toggleBookmark(event, item.id, item.bookmarked)
                        }
                      />
                    }
                    <div className="card-body">
                      <img src={item.web_image_url} alt={item.name} />
                      <span className="delivery-type-label winds-blue-color">
                        {item.delivery_type}
                      </span>
                      {0 === parseInt(item.turn_around_time_in_hours) ? (
                        <span className="delivery-detail d-none d-md-block">
                          <img src={lightningIcon} alt="" />
                          Instant Delivery
                        </span>
                      ) : (
                        <span className="delivery-detail d-none d-md-block">
                          <img src={deliveryWithDelayIcon} alt="" />
                          <FormattedMessage
                            id="voucher.delivered-in"
                            defaultMessage="Delivered in"
                          />{" "}
                          {item.turn_around_time_in_hours}{" "}
                          {item.turn_around_time_in_hours > 1
                            ? "Hours"
                            : "Hour"}
                        </span>
                      )}
                    </div>
                    <div
                      className="card-footer"
                      style={{
                        backgroundImage: `url(${perforatedBorder})`,
                        backgroundRepeat: "repeat-x",
                        backgroundPositionX: "-2px"
                      }}
                    >
                      <div className="row align-items-center">
                        <div className="col-7">
                          <p className="brand-name-label mb-0">{item.name}</p>
                          <p className="mb-0">
                            {lodash
                              .range(item.winds_rating)
                              .map((item, index) => {
                                return (
                                  <img
                                    className="star"
                                    src={wrpIcon}
                                    alt="WRP Icons"
                                    key={"voucher-star-" + index}
                                  />
                                );
                              })}
                          </p>
                        </div>
                        <div className="col-5 font-proxima-nova text-right">
                          <button
                            className="btn btn-primary"
                            style={{
                              backgroundColor: "#203BCF",
                              padding: "0.3rem 1.3rem",
                              fontSize: ".8rem"
                            }}
                          >
                            <FormattedMessage
                              id="voucher.buy-now"
                              defaultMessage="BUY NOW"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          );
        })}
      </MultiItemCarousel>
    );
  }
}
