import Modal from "react-bootstrap/Modal";
import React, { Component } from "react";
import { shuffle } from "../../../utils/helper";

export default class Wheel extends Component {
  state = {
    isPlayedAlready: false
  };
  timerHandle = 0;
  timerDelay = 33;
  angleCurrent = 0;
  angleDelta = 0;
  size = 290;
  canvasContext = null;
  colors = [
    "#EE4040",
    "#F0CF50",
    "#815CD1",
    "#3DA5E0",
    "#34A24F",
    "#F9AA1F",
    "#EC3F3F",
    "#FF9000",
    "#815CD1",
    "#3DA5E0",
    "#3CBA5B",
    "#F9AA1F"
  ];

  segments = [];

  seg_color = [
    "#EE4040",
    "#F0CF50",
    "#815CD1",
    "#3DA5E0",
    "#34A24F",
    "#F9AA1F",
    "#EC3F3F",
    "#FF9000",
    "#815CD1",
    "#3DA5E0",
    "#3CBA5B",
    "#F9AA1F"
  ];
  // Cache of segments to colors
  maxSpeed = Math.PI / 16;
  upTime = 500;
  // How long to spin up for (in ms)
  downTime = 10000;
  // How long to slow down for (in ms)
  spinStart = 0;
  frames = 0;
  centerX = 300;
  centerY = 300;
  constructor(props) {
    super(props);
    this.segments = this.props.item.spin_wheel_options.map(i => i.name);
  }
  componentDidMount() {
    this.wheelInit();
    this.wheelUpdate();

    //Hide the address bar (for mobile devices)!
    setTimeout(() => {
      window.scrollTo(0, 1);
    }, 0);
  }

  wheelInit = () => {
    this.initWheel();
    this.initCanvas();
    this.wheelDraw();
  };

  initWheel = () => {
    shuffle(this.colors);
  };
  initCanvas = () => {
    var canvas = document.getElementById("canvas");
    if (navigator.appVersion.indexOf("MSIE") !== -1) {
      canvas = document.createElement("canvas");
      canvas.setAttribute("width", 1000);
      canvas.setAttribute("height", 600);
      canvas.setAttribute("id", "canvas");
      document.getElementById("wheel").appendChild(canvas);
      //   canvas = G_vmlCanvasManager.initElement(canvas);
    }
    canvas.addEventListener("click", this.spin, false);
    this.canvasContext = canvas.getContext("2d");
  };

  spin = () => {
    this.setState({
      isPlayedAlready: true
    });
    // Start the wheel only if it's not already spinning
    if (this.timerHandle === 0) {
      this.spinStart = new Date().getTime();
      this.maxSpeed = Math.PI / (16 + Math.random()); // Randomly vary how hard the spin is
      this.frames = 0;
      this.timerHandle = setInterval(this.onTimerTick, this.timerDelay);
    }
  };

  onTimerTick = () => {
    this.frames++;

    this.draw();

    var duration = new Date().getTime() - this.spinStart;
    var progress = 0;
    var finished = false;

    if (duration < this.upTime) {
      progress = duration / this.upTime;
      this.angleDelta = this.maxSpeed * Math.sin((progress * Math.PI) / 2);
    } else {
      progress = duration / this.downTime;
      this.angleDelta =
        this.maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);
      if (progress >= 1) finished = true;
    }

    this.angleCurrent += this.angleDelta;
    while (this.angleCurrent >= Math.PI * 2)
      // Keep the angle in a reasonable range
      this.angleCurrent -= Math.PI * 2;

    if (finished) {
      clearInterval(this.timerHandle);
      this.timerHandle = 0;
      this.angleDelta = 0;
      //  document.getElementById('counter').innerHTML = this.frames / duration * 1000 + " FPS";
      //   $("#counter").html((this.frames / duration * 1000) + " FPS");
    }

    /*
    // Display RPM
    var rpm = (wheel.angleDelta * (1000 / wheel.timerDelay) * 60) / (Math.PI * 2);
    $("#counter").html( Math.round(rpm) + " RPM" );
     */
  };

  wheelDraw = () => {
    this.clear();
    this.drawWheel();
    this.drawNeedle();
  };

  draw = () => {
    this.clear();
    this.drawWheel();
    this.drawNeedle();
  };

  drawSegment = (key, lastAngle, angle) => {
    var ctx = this.canvasContext;
    var centerX = this.centerX;
    var centerY = this.centerY;
    var size = this.size;

    var segments = this.segments;
 //   var len = this.segments.length;
    var colors = this.seg_color;

    var value = segments[key];
    ctx.save();
    ctx.beginPath();

    // Start in the centre
    ctx.moveTo(centerX, centerY);
    ctx.arc(centerX, centerY, size, lastAngle, angle, false); // Draw a arc around the edge
    ctx.lineTo(centerX, centerY); // Now draw a line back to the centre
    // Clip anything that follows to this area
    //ctx.clip(); // It would be best to clip, but we can double performance without it
    ctx.closePath();

    ctx.fillStyle = colors[key];
    ctx.fill();
    ctx.stroke();

    // Now draw the text
    ctx.save(); // The save ensures this works on Android devices
    ctx.translate(centerX, centerY);
    ctx.rotate((lastAngle + angle) / 2);

    ctx.fillStyle = "#ffffff";
    ctx.font = "bold 1em proxima-nova";
    ctx.fillText(value.substr(0, 20), size / 2 + 20, 0);
    ctx.restore();

    ctx.restore();
  };

  drawWheel = () => {
    var ctx = this.canvasContext;

    var angleCurrent = this.angleCurrent;
    var lastAngle = angleCurrent;

 //   var segments = this.segments;
    var len = this.segments.length;
 //   var colors = this.colors;
//    var colorsLen = this.colors.length;

    var centerX = this.centerX;
    var centerY = this.centerY;
    var size = this.size;

    var PI2 = Math.PI * 2;

    ctx.lineWidth = 1;
    ctx.strokeStyle = "#131848";
    ctx.textBaseline = "middle";
    ctx.textAlign = "center";
    ctx.font = "1em proxima-nova";

    for (var i = 1; i <= len; i++) {
      var angle = PI2 * (i / len) + angleCurrent;
      this.drawSegment(i - 1, lastAngle, angle);
      lastAngle = angle;
    }
    // Draw a center circle
    ctx.beginPath();
    ctx.arc(centerX, centerY, 50, 0, PI2, false);
    ctx.closePath();

    ctx.fillStyle = "#131848";
    ctx.lineWidth = 10;
    ctx.strokeStyle = "#ffffff";

    ctx.fill();
    ctx.stroke();

    // Draw outer circle
    ctx.beginPath();
    ctx.arc(centerX, centerY, size, 0, PI2, false);
    ctx.closePath();

    ctx.lineWidth = 10;
    ctx.strokeStyle = "#131848";
    ctx.stroke();
  };

  drawNeedle = () => {
    var ctx = this.canvasContext;
    var centerX = this.centerX;
    var centerY = this.centerY;
    var size = this.size;

    // ctx.lineWidth = 1;
    // ctx.strokeStyle = "#ffffff";
    ctx.fileStyle = "#fff";

    ctx.beginPath();

    ctx.moveTo(centerX + 20, centerY - 30);
    ctx.lineTo(centerX - 20, centerY - 30);
    ctx.lineTo(centerX, centerY - 70);
    ctx.closePath();

    // ctx.stroke();
    ctx.fill();

    // Which segment is being pointed to?
    var change = this.angleCurrent + Math.PI / 2;
    var i =
      this.segments.length -
      Math.floor((change / (Math.PI * 2)) * this.segments.length) -
      1;
      
    if (i < 0) i = i + this.segments.length;
    // Now draw the winning name
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillStyle = "#fff";
    ctx.font = "bold 1.5em proxima-nova";
    this.state.isPlayedAlready && ctx.fillText(this.segments[i], centerX + 10, centerY + size + 50);
  };

  clear = () => {
    var ctx = this.canvasContext;
    ctx.clearRect(0, 0, 1000, 800);
  };
  wheelUpdate = () => {};
  render() {
    return (
      <React.Fragment>
        <Modal
          show
          onHide={this.props.closeWheelModelHandler}
          className="font-proxima-nova rewards"
          onEntered={this.props.modelEntered}
          size="lg"
          backdrop="static"
        >
          <Modal.Header closeButton className="border-0"></Modal.Header>

          <Modal.Body>
            <div id="wheel">
              <canvas
                id="canvas"
                width="1000"
                height="800"
                style={{
                  pointerEvents: this.state.isPlayedAlready ? "none" : "auto"
                }}
              ></canvas>
            </div>
            {/* 
            <div id="stats">
              <div id="counter"></div>
            </div> */}
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
