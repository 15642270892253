import React, { Component } from "react";
import notFoundVector from "../assets/404-error-vector.svg";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
export default class NotFound extends Component {
  render() {
    return (
      <div className="container-fluid mt-3 mb-3 mt-md-5 mb-md-5">
        <div className="row">
          <div className="col-12 col--404 text-center">
            <img src={notFoundVector} alt="page not found" />
            <p className="mt-5 font-weight-bold">
              <FormattedMessage
                id="general.empty-page"
                defaultMessage="
                The page you are looking for does not exist."
              />
            </p>
            <Link to="/">
              <button className="btn btn-primary mt-3">
                <FormattedMessage
                  id="general.go-home"
                  defaultMessage="
                GO TO HOME"
                />
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
