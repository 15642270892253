import Modal from "react-bootstrap/Modal";
import React from "react";

const ConfirmationDialog = props => {
    const {isConfirmationDialogOpen,title,body,buttonTxt1,buttonTxt2} = props;
  return (
    <React.Fragment>
      <Modal show={isConfirmationDialogOpen} onHide={props.onDismiss}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>{body}</Modal.Body>

        <Modal.Footer>
          <button className="btn btn-primary" onClick={props.onSuccess}>
            {buttonTxt1}
          </button>
          <button className="btn btn-primary" onClick={props.onDismiss}>
            {buttonTxt2}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
export default ConfirmationDialog;
