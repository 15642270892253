import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import userIcon from '../assets/menu_icons/user.svg';
import lockIcon from '../assets/ic_lock.svg';
import { Link } from "react-router-dom";
import downCaret from '../assets/down-caret-blue.svg';
import classNames from 'classnames';
import client from '../utils/client';
import profileIcon from '../assets/menu_icons/dropdown_icons/profile.svg';
import walletIcon from '../assets/menu_icons/dropdown_icons/wallet.svg';
import referAndEarnIcon from '../assets/menu_icons/dropdown_icons/refer-&-earn.svg';
import logoutIcon from '../assets/menu_icons/dropdown_icons/logout.svg';
import bookmarkIcon from '../assets/menu_icons/dropdown_icons/bookmark.svg';
import winnerIcon from '../assets/menu_icons/dropdown_icons/winners.svg';

class Avatar extends Component {

    constructor(props) {

        super(props);

        this.state = {
            profile: null,
            showDropdown: false
        }

    }

    handleClick = () => {

        this.setState({
            showDropdown: !this.state.showDropdown
        });

    }

    componentDidMount() {

        client.get('v1/profile')
            .then((response) => {

                this.setState({
                    profile: response.data.data
                });

            })

    }

    render() {

        if (null != this.state.profile) {

           return(
            <li className="nav-item text-center" onClick={this.handleClick}>
                <img src={userIcon} alt="Login" />
                    <span className="nav-text d-none d-md-block"><span><FormattedMessage id="navbar.account" defaultMessage="Account" /> </span><img src={downCaret} style={{display:'inline', height: 'auto'}} alt="down caret" /></span>
                    <div className={classNames('dropdown-menu', 'dropdown-menu-right', {'show':this.state.showDropdown})} onMouseLeave={()=>{this.setState({showDropdown: false})}}>
                        <Link className="dropdown-item dropdown-icons" to="/profile">
                        <img src={profileIcon} alt="profile"/> <FormattedMessage id="navbar.profile" defaultMessage="Profile" /></Link>
                        <Link className="dropdown-item dropdown-icons" to="/wallet"><img src={walletIcon} alt="wallet"/> <FormattedMessage id="navbar.wallet" defaultMessage="Wallet" /></Link>
                        <Link className="dropdown-item dropdown-icons" to="/bookmarks"><img src={bookmarkIcon} alt="bookmark"/> <FormattedMessage id="navbar.bookmarks" defaultMessage="Bookmarks" /></Link>
                        <Link className="dropdown-item dropdown-icons" to="/refer-and-earn"><img src={referAndEarnIcon} alt="refer"/> <FormattedMessage id="navbar.refer-and-earn" defaultMessage="Refer & Earn" /></Link>
                        <Link className="dropdown-item dropdown-icons" to="/winners"><img src={winnerIcon} alt="winner"/> <FormattedMessage id="navbar.winners" defaultMessage="Winners" /></Link>
                        <span className="dropdown-item dropdown-icons">
                            <form action={`${process.env.REACT_APP_SSO_URL}/logout`} method="post">
                            <img src={logoutIcon} alt="logout"/> 
                                <input type="submit" value={this.props.intl.formatMessage({ id: 'navbar.logout', defaultMessage: "Logout" })} style={{ border: 'none', background: 'transparent', padding: '0' }} />
                            </form>
                        </span>
                    </div>
                </li>
            );

        }


        return (
            <li className="nav-item text-center">
                <a className="nav-link" href={`${process.env.REACT_APP_SSO_URL}/login`}>
                    <img src={lockIcon} alt="Login" />
                    <span className="d-none d-md-block">
                        <FormattedMessage
                            id="navbar.login"
                            defaultMessage="Login"
                            description="Login"
                            values={{ what: 'react-intl' }} />
                    </span>
                </a>
            </li>
        );
    }
}

export default injectIntl(Avatar);