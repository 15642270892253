export function fetchingVouchers() {
  return {
    type: "FETCHING_VOUCHERS"
  };
}

export function fetchingVoucherCategories() {
  return {
    type: "FETCHING_VOUCHER_CATEGORIES"
  };
}

export function fetchingNextPage() {
  return {
    type: "FETCHING_NEXT_VOUCHER_PAGE"
  };
}

export function setVouchers(vouchers, nextPage) {
  return {
    type: "SET_VOUCHERS",
    vouchers,
    nextPage
  };
}

export function addVouchers(vouchers, nextPage) {
  return {
    type: "ADD_VOUCHERS",
    vouchers,
    nextPage
  };
}

export function setVoucherCategories(categories) {
  return {
    type: "SET_VOUCHER_CATEGORIES",
    categories
  };
}

export function selectCategoryForVouchers(id) {
  return {
    type: "SELECT_CATEGORY_FOR_VOUCHERS",
    id
  };
}

export function onVouchersFilterClick() {
  return {
    type: "VOUCHER_FILTER_CLICK"
  }
}

export function unselectCategoryForVouchers(id) {
  return {
    type: "UNSELECT_CATEGORY_FOR_VOUCHERS",
    id
  };
}

export function resetVoucherFilter() {
  return {
    type:'RESET_VOUCHER_FILTER'
  }
}

export function setVoucher(voucher) {
  return {
    type: "SET_VOUCHER",
    voucher
  };
}

export function setDenomination(denomination) {
  return {
    type: "SET_DENOMINATION",
    denomination
  };
}

export function setQuantity(quantity) {
  return {
    type: "SET_QUANTITY",
    quantity
  };
}

export function setVoucherListAfterBookmarkToggle(id, vouchers) {
  return {
    type: "SET_VOUCHER_LIST_AFTER_TOGGLE_BOOKMARK",
    id,
    vouchers
  };
}
