export function setLocation(latitude, longitude){
    return {
        type: 'SET_LOCATION',
        latitude,
        longitude
    }
}

export function setFormattedAddress(formatted_address){
    return {
        type: 'SET_FORMATTED_ADDRESS',
        formatted_address
    }
}