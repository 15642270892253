import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import client from "../../../utils/client";
import clientX from "../../../utils/clientX";
import billPayCreative from "../../../assets/bill-pay-creative.svg";
import Modal from "react-bootstrap/Modal";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormattedMessage, injectIntl } from "react-intl";
import aboutBlue from "../../../assets/about-blue.svg";

class BillPayForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      service: null,
      services: [],
      providers: [],
      provider: null,
      number: null,
      amount: null,
      account: null,
      authenticator: null,
      payBtnState: true,
      circles: [],
      plans: [],
      isWrpBenefitModelOpen: false
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;

    client.get(`v1/bill-pay/services/${params.id}/providers`).then(response => {
      let data = response.data.data;

      this.setState({
        providers: data
      });
    });

    client.get(`v1/bill-pay/circles`).then(response => {
      let data = response.data.data;

      this.setState({
        circles: data
      });
    });

    client.get(`v1/bill-pay/services`).then(response => {
      let data = response.data.data;

      const service = data.filter(item => {
        return Number(params.id) === Number(item.id);
      });

      this.setState({
        service: service[0],
        services: data
      });
    });
  }

  UNSAFE_componentWillReceiveProps(props) {
    const {
      match: { params }
    } = props;

    client.get(`v1/bill-pay/services/${params.id}/providers`).then(response => {
      let data = response.data.data;

      this.setState({
        providers: data,
        provider: null,
        number: null,
        amount: null,
        payBtnState: true
      });
    });

    client.get(`v1/bill-pay/services`).then(response => {
      let data = response.data.data;

      const service = data.filter(item => {
        return Number(params.id) === Number(item.id);
      });

      this.setState({
        service: service[0],
        services: data
      });
    });
  }

  onSelectProvider = event => {
    const providorID = event.target.value;

    if (-1 === parseInt(providorID)) {
      this.setState({
        provider: null
      });

      return;
    }

    const provider = this.state.providers.filter(item => {
      return Number(item.id) === Number(providorID);
    });

    this.setState({
      provider: provider[0]
    });
  };

  onSelectCircle = event => {
    const circleID = event.target.value;

    const providerID = this.state.provider.id;

    clientX
      .get("/bill-pay/plans", {
        params: {
          circle_id: circleID,
          provider_id: providerID
        }
      })
      .then(response => {
        this.setState({
          plans: response.data.data
        });
      });
  };

  onChangeNumber = event => {
    const number = event.target.value;

    this.setState({
      number: number
    });
  };

  onAmountChange = event => {
    const amount = event.target.value;

    this.setState({
      amount: amount,
      payBtnState: false
    });
  };

  onChangeAccount = event => {
    const account = event.target.value;

    this.setState({
      account: account
    });
  };

  onChangeAuthenticator = event => {
    const authenticator = event.target.value;

    this.setState({
      authenticator: authenticator
    });
  };

  onSelectPlan = (planId, cost) => {
    this.setState({
      show: false,
      amount: cost
    });
  };

  onInitOrder = () => {
    let that = this;

    clientX
      .post("/bill-pay/initiate-order", {
        provider_id: this.state.provider.id,
        number: this.state.number,
        amount: this.state.amount,
        account: this.state.account,
        authenticator: this.state.authenticator
      })
      .then(response => {
        new window.Razorpay({
          key: "rzp_live_3oRYrd7ePeI7Ao",
          order_id: response.data.data.payment_gateway_order_id,
          handler: function(pgResponse) {
            clientX
              .post(`/bill-pay/${response.data.data.order_id}/fulfil-order`, {
                payment_gateway_order_id:
                  response.data.data.payment_gateway_order_id,
                razorpay_order_id: pgResponse.razorpay_order_id,
                razorpay_payment_id: pgResponse.razorpay_payment_id,
                razorpay_signature: pgResponse.razorpay_signature
              })
              .then(response => {
                that.props.history.push(
                  `/bill-pay/orders/${response.data.data.order_id}`
                );
              })
              .catch(error => {});
          }
        }).open();
      })
      .catch(error => {
        toast.error(error.response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      });
  };

  openWrpBenefitModel = () => {
    this.setState({ isWrpBenefitModelOpen: true });
  };
  closeWrpModelHandler = () => {
    this.setState({ isWrpBenefitModelOpen: false });
  };

  WrpBenefitModel = props => {
    return (
      <React.Fragment>
        <Modal
          show={props.isWrpBenefitModelOpen}
          onHide={props.closeWrpModelHandler}
          className="font-proxima-nova"
        >
          <Modal.Header closeButton className="border-0">
            <Modal.Title>
              <FormattedMessage
                id="general.wrp_benefits"
                defaultMessage="WRP Benefits"
              />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="row">
              <div className="col-12 text-left">
                <p>
                  Any bill payment or recharge done will earn you flat
                  WRP.&nbsp; WRP will be awarded immediately after the
                  completion of pyment or recharge.
                </p>
                <p className="font-proxima-nova-bold">WRP Earning:</p>

                <div className="mt-4">
                  <p>Amount less than Rs. 50 = No WRP</p>

                  <p>From Rs. 50 to Rs. 100 = Flat 100 WRP</p>

                  <p>Amount above Rs. 1000 = Flat 200 WRP</p>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer className="border-0">
            <Link to="/wrp-details">
              <button className="btn btn-primary">
                <FormattedMessage
                  id="home.know-more"
                  defaultMessage="Know More"
                />
              </button>
            </Link>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  };

  render() {
    const {
      match: { params }
    } = this.props;
    const { isWrpBenefitModelOpen } = this.state;

    return (
      null != this.state.service && (
        <div className="bill-pay-page">
          <div
            className="container-fluid container-fluid--bill-pay-icon"
            style={{ backgroundColor: "#F2F6FA" }}
          >
            <div className="row pt-3 mb-3">
              <div className="col-12">
                <small>
                  <Link to="/">
                    <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    />
                  </Link>
                </small>{" "}
                /{" "}
                <small>
                  <FormattedMessage id="home.bill-pay-utility" />
                </small>{" "}
                /{" "}
                <small>
                  <strong>{this.state.service.name}</strong>
                </small>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="d-none d-md-flex justify-content-around">
                  {this.state.services.map((item, index) => {
                    if (parseInt(item.id) === parseInt(params.id)) {
                      return (
                        <Link to={`/bill-pay/${item.id}`} key={index}>
                          <div
                            key={"service-" + index}
                            style={{ textAlign: "center" }}
                          >
                            <img
                              src={item.app_image_url}
                              className="mb-3"
                              style={{ height: "40px" }}
                              alt={item.name}
                            />
                            <p
                              className="mb-0"
                              style={{
                                fontSize: "20px",
                                textAlign: "center",
                                color: "#333333",
                                borderBottom: "solid 3px #0078D7"
                              }}
                            >
                              {item.name}
                            </p>
                          </div>
                        </Link>
                      );
                    }

                    return (
                      <Link to={`/bill-pay/${item.id}`} key={index}>
                        <div
                          key={"service-" + index}
                          style={{ textAlign: "center" }}
                        >
                          <img
                            src={item.app_image_url}
                            className="mb-3"
                            style={{ height: "40px" }}
                            alt={item.name}
                          />
                          <p
                            className="mb-0"
                            style={{
                              fontSize: "20px",
                              textAlign: "center",
                              color: "#666"
                            }}
                          >
                            {item.name}
                          </p>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div
            className="container-fluid"
            style={{ backgroundColor: "#F6F6F6" }}
          >
            <div className="row">
              <div className="col-12">
                <div className="card card--bill-pay-form">
                  <div className="card-body">
                    <div className="row font-proxima-nova mt-4">
                      <div className="col-md-6">
                        <h5 className="">
                          <strong>{this.state.service.name}</strong>
                        </h5>
                        <form>
                          <div className="form-group">
                            <label>{this.state.service.provider_label}</label>
                            <select
                              className="form-control"
                              onChange={this.onSelectProvider}
                            >
                              <option value="-1">{`Choose ${this.state.service.provider_label}`}</option>
                              {this.state.providers.map((item, index) => {
                                return (
                                  <option
                                    value={item.id}
                                    key={"providor-option-" + index}
                                  >
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          {null !== this.state.provider && (
                            <div className="form-group">
                              <label>{this.state.provider.number_label}</label>
                              <input
                                type="text"
                                className="form-control"
                                onChange={this.onChangeNumber}
                              />
                            </div>
                          )}
                          {null !== this.state.provider &&
                            null !== this.state.provider.account_label && (
                              <div className="form-group">
                                <label>
                                  {this.state.provider.account_label}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={this.onChangeAccount}
                                />
                              </div>
                            )}
                          {null !== this.state.provider &&
                            null !==
                              this.state.provider.authenticator_label && (
                              <div className="form-group">
                                <label>
                                  {this.state.provider.authenticator_label}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={this.onChangeAuthenticator}
                                />
                              </div>
                            )}
                          {null !== this.state.number && (
                            <div className="form-group">
                              <label>
                                <FormattedMessage id="general.amount" />
                              </label>
                              {1 === parseInt(this.state.service.id) ? (
                                <div className="input-group mb-3">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.amount || ""}
                                    onChange={this.onAmountChange}
                                  />
                                  <div className="input-group-append">
                                    <span
                                      className="input-group-text"
                                      onClick={this.handleShow}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <FormattedMessage id="billpay.view-plans" />
                                    </span>
                                    <Modal
                                      size="lg"
                                      show={this.state.show}
                                      onHide={this.handleClose}
                                    >
                                      <Modal.Header>
                                        <Modal.Title>
                                          <FormattedMessage id="billpay.choose-a-plan" />
                                        </Modal.Title>
                                      </Modal.Header>
                                      <Modal.Body>
                                        <div className="form-group">
                                          <label>
                                            <FormattedMessage id="billpay.choose-your-circle" />
                                          </label>
                                          <select
                                            className="form-control"
                                            onChange={this.onSelectCircle}
                                          >
                                            <option value="-1">
                                              {this.props.intl.formatMessage({
                                                id: "billpay.choose-your-circle"
                                              })}
                                            </option>
                                            {this.state.circles.map(
                                              (item, index) => {
                                                return (
                                                  <option
                                                    value={item.id}
                                                    key={"circle-" + index}
                                                  >
                                                    {item.name}
                                                  </option>
                                                );
                                              }
                                            )}
                                          </select>
                                        </div>
                                        <div className="nav--plans">
                                          <Tabs>
                                            {this.state.plans.map(
                                              (item, index) => {
                                                return (
                                                  <Tab
                                                    eventKey={item.category}
                                                    className="nav-tabs--plan"
                                                    title={item.category}
                                                    key={`category${index}`}
                                                  >
                                                    {item.plans.map(
                                                      (item, index) => {
                                                        return (
                                                          <div
                                                            className="card mt-3"
                                                            key={`plans${index}`}
                                                          >
                                                            <div className="card-header">
                                                              <h3>
                                                                <strong>
                                                                  {item.cost}
                                                                </strong>
                                                              </h3>
                                                            </div>
                                                            <div className="card-body">
                                                              <p className="mb-0">
                                                                <FormattedMessage id="billpay.talktime" />
                                                              </p>
                                                              <p>
                                                                <strong>
                                                                  {
                                                                    item.talk_time
                                                                  }
                                                                </strong>
                                                              </p>
                                                              <p className="mb-0">
                                                                <FormattedMessage id="billpay.validity" />
                                                              </p>
                                                              <p>
                                                                <strong>
                                                                  {
                                                                    item.validity
                                                                  }
                                                                </strong>
                                                              </p>
                                                              <p className="mb-0">
                                                                <FormattedMessage id="billpay.plan-detail" />
                                                              </p>
                                                              <p>
                                                                <strong>
                                                                  {
                                                                    item.description
                                                                  }
                                                                </strong>
                                                              </p>
                                                              <button
                                                                className="btn btn-primary"
                                                                onClick={() => {
                                                                  this.onSelectPlan(
                                                                    item.plan_id,
                                                                    item.cost
                                                                  );
                                                                }}
                                                              >
                                                                <FormattedMessage id="billpay.select-plan" />
                                                              </button>
                                                            </div>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </Tab>
                                                );
                                              }
                                            )}
                                          </Tabs>
                                        </div>
                                      </Modal.Body>
                                      <Modal.Footer>
                                        <button
                                          className="btn btn-primary"
                                          onClick={this.handleHide}
                                        >
                                          <FormattedMessage id="general.close" />
                                        </button>
                                      </Modal.Footer>
                                    </Modal>
                                  </div>
                                </div>
                              ) : (
                                <input
                                  type="text"
                                  className="form-control"
                                  value={this.state.amount || ""}
                                  onChange={this.onAmountChange}
                                />
                              )}
                            </div>
                          )}

                          <div className="row mt-3 align-items-center">
                            <div className="col-12">
                              <button
                                type="button"
                                className="btn btn-primary btn-block mb-3 mt-3 git home-btn"
                                onClick={this.onInitOrder}
                                disabled={this.state.payBtnState}
                              >
                                <FormattedMessage id="billpay.proceed-to-pay" />
                              </button>
                              <button
                                type="button"
                                className="btn btn-outline"
                                style={{
                                  color: "#087CD8",
                                  marginTop: "-0.5rem"
                                }}
                                onClick={this.openWrpBenefitModel}
                              >
                                <img src={aboutBlue} alt="" className="mr-1" />{" "}
                                <FormattedMessage
                                  id="general.wrp_benefits"
                                  defaultMessage="WRP Benefits"
                                />
                              </button>
                            </div>
                            {this.state.isWrpBenefitModelOpen && (
                              <this.WrpBenefitModel
                                isWrpBenefitModelOpen={isWrpBenefitModelOpen}
                                closeWrpModelHandler={this.closeWrpModelHandler}
                              />
                            )}
                          </div>
                        </form>
                        <ToastContainer
                          position="top-center"
                          autoClose={5000}
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnVisibilityChange
                          draggable
                          pauseOnHover
                        />
                      </div>
                      <div className="col-md-6 text-center">
                        <img
                          src={billPayCreative}
                          className="d-none d-md-inline mt-md-5"
                          alt="Bill Pay"
                          style={{ width: "400px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-md-none">
              <div className="col-12">
                <h5 className="mt-3 mb-5" style={{ fontWeight: "bold" }}>
                  Other Services
                </h5>
                <div className="bill-pay-page-icon-container">
                  {this.state.services.map((item, index) => {
                    return (
                      <div className="bill-pay-service-icon" key={item.id}>
                        <Link to={`/bill-pay/${item.id}`}>
                          <div
                            key={"service-" + index}
                            style={{ textAlign: "center" }}
                          >
                            <img
                              src={item.app_image_url}
                              className="mb-3"
                              style={{ height: "30px" }}
                              alt="appImage"
                            />
                            <p
                              className="mb-0"
                              style={{
                                fontSize: "14px",
                                textAlign: "center",
                                color: "#666"
                              }}
                            >
                              {item.name}
                            </p>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
}

export default withRouter(injectIntl(BillPayForm));
