import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import DefaultStoreImage from "../../../assets/default-store-image.svg";
import bookmarkActive from "../../../assets/bookmark-active.svg";
import bookmarkInactive from "../../../assets/bookmark-inactive.svg";

export default class OfffersCarousel extends Component {
  componentDidMount() {
    window.addEventListener("touchstart", this.touchStart);
    window.addEventListener("touchmove", this.preventTouch, { passive: false });
  }

  componentWillUnmount() {
    window.removeEventListener("touchstart", this.touchStart);
    window.removeEventListener("touchmove", this.preventTouch, {
      passive: false
    });
  }

  toggleBookmark = (event, id, isBookmarked) => {
    event.preventDefault();
    this.props.onToggleBookmarkOffer(
      id,
      isBookmarked,
      `/nearby-partners/offers/${id}/bookmark`,
      "offers"
    );
  };

  touchStart(e) {
    this.firstClientX = e.touches[0].clientX;
    this.firstClientY = e.touches[0].clientY;
  }

  preventTouch(e) {
    const minValue = 5; // threshold

    this.clientX = e.touches[0].clientX - this.firstClientX;
    this.clientY = e.touches[0].clientY - this.firstClientY;

    if (Math.abs(this.clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  }

  render() {
    const settings = {
      arrows: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false
          }
        }
      ]
    };

    return (
      <Slider {...settings}>
        {this.props.offers.map((item, index) => {
          return (
            <div key={"offer-" + index}>
              <div style={{ padding: "15px" }}>
                <Link to={`/offer/${item.id}`}>
                  <div className="card mb-4 card--carousel-item card--carousel-item--offers">
                    {
                      <img
                        src={
                          item.bookmarked ? bookmarkActive : bookmarkInactive
                        }
                        className="bookmark"
                        alt=""
                        onClick={event =>
                          this.toggleBookmark(event, item.id, item.bookmarked)
                        }
                      />
                    }
                    {null != item.store_image ? (
                      <div
                        className="card-body"
                        style={{
                          backgroundColor: "#F8F9FA",
                          backgroundImage: `url(${item.store_image})`,
                          backgroundSize: "cover",
                          height: "200px"
                        }}
                      ></div>
                    ) : (
                      <div
                        className="card-body"
                        style={{
                          backgroundColor: "#F8F9FA",
                          backgroundImage: `url(${DefaultStoreImage})`,
                          backgroundSize: "contain",
                          backgroundPosition: "center center",
                          backgroundRepeat: "no-repeat",
                          height: "200px"
                        }}
                      ></div>
                    )}
                    <div className="card-footer">
                      <p className="mb-0">
                        <small>{item.partner_name}</small>
                      </p>
                      <p className="mb-0">
                        <strong>{item.title.substring(0, 25)}</strong>
                      </p>
                      <p className="mb-0">
                        {item.description !== undefined &&
                          item.description.substring(0, 50) + "..."}
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          );
        })}
      </Slider>
    );
  }
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        zIndex: 2,
        right: "15px",
        background: "#fff",
        display: "block",
        borderRadius: "6px",
        boxShadow: "0 0 15px 0 rgba(0,0,0,0.13)",
        padding: "5px",
        height: "48px",
        width: "38px"
      }}
      onClick={onClick}
    ></div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        zIndex: 2,
        left: "15px",
        background: "#fff",
        display: "block",
        borderRadius: "6px",
        boxShadow: "0 0 15px 0 rgba(0,0,0,0.13)",
        padding: "5px",
        height: "48px",
        width: "38px"
      }}
      onClick={onClick}
    />
  );
}
