import React, { Component } from "react";
import clientX from "../../../utils/clientX";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { ToastContainer } from "react-toastify";
import { showToastMessage } from "../../../utils/helper";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

export default class OrderDetail extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      order: null,
      vouchers: [],
      voucherStatus: null,
      isFetchingVoucherCodes: false
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    const {
      match: { params }
    } = this.props;

    clientX.get(`v1/vouchers/orders/${params.id}`).then(response => {
      let data = response.data.data;
     if(this._isMounted){

      this.setState({
        order: data
      });

      if (data.fulfilled && !data.voucher_code_generated) {
        this.setState({ voucherStatus: "pending" });
        return;
      }

      if (data.voucher_code_generated) {
        this.setState({ isFetchingVoucherCodes: true }, () => {
          clientX.get(`v1/vouchers/orders/${params.id}/codes`).then(response => {
            let data = response.data.data;

            this.setState({
              vouchers: data,
              isFetchingVoucherCodes: false,
              voucherStatus: "success"
            });
          });
        });
      }
     }
    });
  }

  render() {
    return (
      null !== this.state.order && (
        <React.Fragment>
          <div className="container-fluid">
            <div className="row mt-3 mb-3 mt-md-4 mb-md-4 align-items-center">
              <div className="col-12 text-md-center">
                <small>
                  <Link to="/">
                    <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    />
                  </Link>
                </small>{" "}
                /{" "}
                <small>
                  <Link to="/orders">
                    <FormattedMessage id="navbar.orders" />
                  </Link>
                </small>{" "}
                /{" "}
                <small>
                  <strong>
                    <FormattedMessage id="orders.order" />
                  </strong>
                </small>
              </div>
            </div>
            <div className="row mb-3 mb-md-4">
              <div className="col-md-6 offset-md-3">
                <div className="list-group">
                  <div className="list-group-item list-group-item-action">
                    <img
                      className="mt-2 mt-md-0"
                      src={this.state.order.web_image_url}
                      style={{
                        background: "#fff",
                        height: "30px",
                        border: "1px solid rgba(0, 0, 0, 0.125)",
                        display: "inline",
                        boxSizing: "content-box",
                        padding: "15px 0",
                        borderRadius: "0.25rem",
                        marginRight: "15px"
                      }}
                      alt={this.state.voucher_name}
                    />
                    <p className="d-block d-md-inline mt-1 mt-md-0 mb-0">
                      <FormattedMessage id="orders.purchase-of-voucher" />{" "}
                      <strong>{this.state.order.voucher_name}</strong>
                    </p>
                  </div>
                  <div className="list-group-item list-group-item-action">
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        <OrderStatusLabel order={this.state.order} />
                      </div>
                      <div className="col-md-6 text-md-right">
                        <p className="mb-0">
                          <FormattedMessage id="orders.your-order-number-is" />{" "}
                          <strong>{this.state.order.order_number}</strong>
                        </p>
                        <p className="mb-0">{this.state.order.created_at}</p>
                      </div>
                    </div>
                  </div>
                  <div className="list-group-item list-group-item-action">
                    <p className="mb-0">
                      <FormattedMessage id="order.we-received-payment-of" />{" "}
                      <strong>{this.state.order.amount}</strong>
                    </p>
                  </div>
                  <div className="list-group-item list-group-item-action">
                    <p className="mb-0">
                      <strong>{this.state.order.wrp}</strong> WRPs{" "}
                      {this.state.order.wrp_credited
                        ? "credited"
                        : "are pending"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3 mb-md-4">
              <div className="col-md-6 offset-md-3">
                {this.state.isFetchingVoucherCodes ? (
                  <div className="box shine"></div>
                ) : "pending" === this.state.voucherStatus ? (
                  <div class="alert alert-primary" role="alert">
                    <p className="mb-0">
                      Voucher Code is being processed. Check back in sometime.
                    </p>
                  </div>
                ) : (
                  this.state.vouchers.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <h6 className="font-weight-bold mt-3">{`Voucher ${index +
                          1}`}</h6>
                        <Voucher item={item} />
                      </React.Fragment>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
      )
    );
  }
}

const Voucher = props => {
  function renderTooltip(properties) {
    return (
      <Tooltip {...properties} show="true">
        Click to Copy
      </Tooltip>
    );
  }

  return (
    <React.Fragment>
      <div className="list-group">
        <div className="list-group-item list-group-item-action">
          <p className="mb-0">
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <span
                style={{
                  cursor: "pointer",
                  color: "rgb(8, 124, 216)"
                }}
                onClick={() => {
                  navigator.clipboard.writeText(props.item.voucher_number);
                  showToastMessage(
                    "success",
                    "Voucher Code copied to the clipboard"
                  );
                }}
              >
                {props.item.voucher_number}
              </span>
            </OverlayTrigger>
          </p>
          <p className="mb-0 text-muted">
            <small>VOUCHER CODE</small>
          </p>
        </div>
        <div className="list-group-item list-group-item-action">
          <p className="mb-0">
            {props.item.voucher_pin ? (
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <span
                  style={{
                    cursor: "pointer",
                    color: "rgb(8, 124, 216)"
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(props.item.voucher_pin);
                    showToastMessage(
                      "success",
                      "Voucher PIN copied to the clipboard"
                    );
                  }}
                >
                  {props.item.voucher_pin}
                </span>
              </OverlayTrigger>
            ) : (
              <span>NA</span>
            )}
          </p>
          <p className="mb-0 text-muted">
            <small>VOUCHER PIN</small>
          </p>
        </div>
        <div className="list-group-item list-group-item-action">
          <p className="mb-0">{props.item.expiry_date}</p>
          <p className="mb-0 text-muted">
            <small>EXPIRY DATE</small>
          </p>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </React.Fragment>
  );
};

function OrderStatusLabel(props) {
  switch (props.order.status) {
    case 3:
      return (
        <p className="badge badge-success text-white mb-0">
          {props.order.statusText}
        </p>
      );
    case 4:
      return (
        <p className="badge badge-danger text-white mb-0">
          {props.order.statusText}
        </p>
      );
    default:
      return (
        <p className="badge badge-warning text-white mb-0">
          {props.order.statusText}
        </p>
      );
  }
}
