import React, { Component } from "react";
import clientX from "../../../utils/clientX";
import { Link } from "react-router-dom";
import DefaultStoreImage from "../../../assets/default-store-image.svg";
import { FormattedMessage } from "react-intl";
import { DownloadBill } from "../../shopping_sites/pages/OrderDetail";

export default class OrderDetail extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      order: null
    };
  }


  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    const {
      match: { params }
    } = this.props;

    clientX.get(`v1/nearby-partners/bills/${params.id}`).then(response => {
      let data = response.data.data;
      if (this._isMounted) {
        this.setState({
          order: data
        });
      }
    });
  }

  render() {
    return (
      null !== this.state.order && (
        <div className="container-fluid">
          <div className="row mt-3 mb-3 mt-md-4 mb-md-4 align-items-center">
            <div className="col-12 text-md-center">
              <small>
                <Link to="/">
                  <FormattedMessage
                    id="breadcrumbs.home"
                    defaultMessage="Home"
                  />
                </Link>
              </small>{" "}
              /{" "}
              <small>
                <Link to="/orders">Orders</Link>
              </small>{" "}
              /{" "}
              <small>
                <strong>Order</strong>
              </small>
            </div>
          </div>
          <div className="row mb-3 mb-md-4">
            <div className="col-md-6 offset-md-3">
              <div className="list-group">
                <div className="list-group-item list-group-item-action">
                  {null !== this.state.order.partner_image ? (
                    <img
                      className="mt-2 mt-md-0"
                      src={this.state.order.partner_image}
                      style={{
                        background: "#fff",
                        height: "60px",
                        border: "1px solid rgba(0, 0, 0, 0.125)",
                        display: "inline",
                        boxSizing: "content-box",
                        borderRadius: "0.25rem",
                        marginRight: "15px"
                      }}
                      alt={this.state.order.name}
                    />
                  ) : (
                    <img
                      className="mt-2 mt-md-0"
                      src={DefaultStoreImage}
                      style={{
                        background: "#fff",
                        height: "60px",
                        border: "1px solid rgba(0, 0, 0, 0.125)",
                        display: "inline",
                        boxSizing: "content-box",
                        borderRadius: "0.25rem",
                        marginRight: "15px"
                      }}
                      alt={this.state.order.name}
                    />
                  )}
                  <p className="d-block d-md-inline mt-1 mt-md-0 mb-0">
                    Purchase from {this.state.order.partner_name}{" "}
                    <strong>{this.state.order.voucher_name}</strong>
                  </p>
                </div>
                <div className="list-group-item list-group-item-action">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <OrderStatusLabel order={this.state.order} />
                    </div>
                    <div className="col-md-6 text-md-right">
                      <p className="mb-0">
                        Your order number is{" "}
                        <strong>{this.state.order.order_number}</strong>
                      </p>
                      <p className="mb-0">{this.state.order.created_at}</p>
                    </div>
                  </div>
                </div>
                <div className="list-group-item list-group-item-action">
                  <p className="mb-0">
                    Order Amount Rs. <strong>{this.state.order.amount}</strong>
                  </p>
                </div>
                <div className="list-group-item list-group-item-action">
                  <p className="mb-0">
                    <strong>{this.state.order.wrp}</strong> WRPs{" "}
                    {this.state.order.wrp_credited ? "credited" : "are pending"}
                  </p>
                </div>
              </div>
              {this.state.order.transaction_image && (
                <DownloadBill {...this.state} />
              )}
            </div>
          </div>
        </div>
      )
    );
  }
}

function OrderStatusLabel(props) {
  if (props.order.fulfilled) {
    return (
      <p className="badge badge-success text-white mb-0">
        {props.order.status_text}
      </p>
    );
  }

  if (!props.order.fulfilled && props.order.payment_failed) {
    return (
      <p className="badge badge-danger text-white mb-0">
        {props.order.status_text}
      </p>
    );
  }

  return (
    <p className="badge badge-warning text-white mb-0">
      {props.order.status_text}
    </p>
  );
}
