import React, { Component } from "react";
import client from "../../../utils/client";
import { Link } from "react-router-dom";
import ContentLoader from "react-content-loader";
import { connect } from "react-redux";
import lodash from "lodash";
import InlineLoader from "../../../components/InlineLoader";
import Modal from "react-bootstrap/Modal";
import filterIcon from "../../../assets/filter-icon.svg";
import {
  fetchingShoppingSites,
  setShoppingSites,
  fetchingShoppingSiteCategories,
  setShoppingSiteCategories,
  selectCategory,
  unselectCategory,
  fetchingNextPage,
  addShoppingSites,
  setShoppingListAfterBookmarkToggle,
  filterClicked,
  resetFilter
} from "../actions";
import shoppingSitesVector from "../../../assets/empty-state-shopping-sites.svg";
import { FormattedMessage } from "react-intl";

import bookmarkActive from "../../../assets/bookmark-active.svg";
import bookmarkInactive from "../../../assets/bookmark-inactive.svg";
import clientX from "../../../utils/clientX";

class ShoppingSitesList extends Component {
  componentDidMount() {
    this.props.initCategory();
    this.props.initShoppingSites(this.props.selectedCategories);

    window.addEventListener("scroll", this.onScroll, false);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll, false);
  }

  onScroll = () => {
    if (this.props.isFetchingNextPage) {
      return;
    }

    if (!this.props.nextPage) {
      return;
    }

    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 500 &&
      this.props.shoppingSites
    ) {
      this.props.onPaginatedSearch(
        this.props.selectedCategories,
        this.props.nextPage
      );
    }
  };

  onChange = (event, id) => {
    if (event.target.checked) {
      this.props.onSelectCategory(id);
      return;
    }

    this.props.onUnselectCategory(id);
  };

  componentDidUpdate(prevProps) {
    if (
      !lodash.isEqual(
        this.props.selectedCategories,
        prevProps.selectedCategories
      )
    ) {
      this.props.initShoppingSites(this.props.selectedCategories);
    }
  }

  render() {
    return (
      <div className="container-fluid font-proxima-nova">
        <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
          <div className="col-12">
            <div className="row align-items-center">
              <div className="col-8 col-md-12">
                <small>
                  <Link to="/">
                    <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    />
                  </Link>{" "}
                  /{" "}
                  <strong>
                    <FormattedMessage
                      id="home.shopping-sites"
                      defaultMessage="Shopping Sites"
                    />
                  </strong>
                </small>
              </div>
              <div className="col-4 text-right d-block d-md-none pr-0">
                <img src={filterIcon} alt="" className="filter-icon" />
                <button
                  className="btn text-primary"
                  onClick={this.props.onFilterClick}
                >
                  <FormattedMessage
                    id="general.filters"
                    defaultMessage="Filters"
                  />
                </button>

                <React.Fragment>
                  <Modal
                    show={this.props.isFilterModelOpen}
                    onHide={this.props.onFilterClick}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Filters</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                      <this.Filters />
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="row w-100">
                        <div className="col-6">
                          <button
                            onClick={this.props.onFilterClick}
                            className="w-100 btn btn-outline-primary"
                          >
                            <FormattedMessage
                              id="general.apply"
                              defaultMessage="Apply"
                            />
                          </button>
                        </div>
                        <div className="col-6">
                          <button
                            className="btn w-100 btn-outline-primary"
                            onClick={() => {
                              this.props.onFilterClick();
                              this.props.resetFilter();
                            }}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </Modal.Footer>
                  </Modal>
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-3 d-none d-md-block border-right w-100">
            <this.FiltersBlock />
          </div>
          <div className="col-12 col-md-9 main-content">
            {this.props.isFetchingShoppingSites ? (
              <this.ShoppingSiteShimmer />
            ) : this.props.shoppingSites.length === 0 ? (
              <this.ShoppingSiteEmptyState />
            ) : (
              <this.ShoppingSites />
            )}
          </div>
        </div>
      </div>
    );
  }

  FiltersBlock = props => {
    return (
      <React.Fragment>
        <div className="row mb-3 status border-bottom">
          <div className="col-6 pl-0">
            <h5 className="font-proxima-nova-bold">
              <FormattedMessage id="general.filters" defaultMessage="Filters" />
            </h5>
          </div>
          <div className="col-6 pr-0">
            <button
              className="btn btn-outline float-right"
              onClick={this.props.resetFilter}
              style={{ color: "#087CD8", marginTop: "-0.5rem" }}
            >
              <FormattedMessage id="general.reset" defaultMessage="Reset" />
            </button>
          </div>
        </div>
        <this.Filters />
      </React.Fragment>
    );
  };

  Filters = props => (
    <React.Fragment>
      {this.props.isFetchingShoppingSitesCategories ? (
        <div className="row">
          <div className="col-md-12">
            <ContentLoader
              height={200}
              width={200}
              speed={2}
              primaryColor="#F6F7F8"
              secondaryColor="#ecebeb"
            >
              <rect x="0" y="0" rx="2" ry="2" width="132" height="16" />
              <rect x="180" y="0" rx="2" ry="2" width="16" height="16" />
              <rect x="0" y="24" rx="2" ry="2" width="132" height="16" />
              <rect x="0" y="48" rx="2" ry="2" width="132" height="16" />
              <rect x="0" y="72" rx="2" ry="2" width="132" height="16" />
              <rect x="0" y="96" rx="2" ry="2" width="132" height="16" />
              <rect x="0" y="120" rx="2" ry="2" width="132" height="16" />
              <rect x="0" y="144" rx="2" ry="2" width="132" height="16" />
              <rect x="0" y="168" rx="2" ry="2" width="132" height="16" />
              <rect x="180" y="24" rx="2" ry="2" width="16" height="16" />
              <rect x="180" y="48" rx="2" ry="2" width="16" height="16" />
              <rect x="180" y="72" rx="2" ry="2" width="16" height="16" />
              <rect x="180" y="96" rx="2" ry="2" width="16" height="16" />
              <rect x="180" y="120" rx="2" ry="2" width="16" height="16" />
              <rect x="180" y="144" rx="2" ry="2" width="16" height="16" />
              <rect x="180" y="168" rx="2" ry="2" width="16" height="16" />
            </ContentLoader>
          </div>
        </div>
      ) : (
        <div className="row mb-3 status">
          <p className="text-uppercase font-proxima-nova-bold">
            <FormattedMessage
              id="general.categories"
              defaultMessage="CATEGORY"
            />
          </p>
          <div className="col-12 filter-categories pr-0">
            <div
              style={{
                height: "450px",
                overflowY: "scroll",
                overflowX: "hidden"
                // borderBottom: "1px solid #dee2e6"
              }}
            >
              {this.props.categories.map((item, index) => {
                return (
                  <div className="row mt-3" key={"category-box-" + index}>
                    <div className="col-9">{item.name}</div>
                    <div className="col-3 mb-1 text-center">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          checked={
                            lodash.indexOf(
                              this.props.selectedCategories,
                              item.id
                            ) > -1
                          }
                          onChange={event => {
                            this.onChange(event, item.id);
                          }}
                          id={"shopping-sites-category-" + item.id}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={"shopping-sites-category-" + item.id}
                        ></label>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );

  ShoppingSiteShimmer = props => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-6 col-md-4">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-6 col-md-4">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-6 col-md-4 d-none d-md-block">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 col-md-4">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-6 col-md-4">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-6 col-md-4 d-none d-md-block">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 col-md-4">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-6 col-md-4">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-6 col-md-4 d-none d-md-block">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  ShoppingSiteEmptyState = props => {
    return (
      <div className="row">
        <div className="col-12 text-center">
          <img
            className="shopping-sites-vecotr"
            src={shoppingSitesVector}
            alt="shopping sites"
          />
          <p className="mt-2">
            <strong>No Shopping Sites found.</strong>
          </p>
        </div>
      </div>
    );
  };

  toggleBookmark = (event, id, isBookmarked) => {
    event.preventDefault();
    event.stopPropagation();
    this.onToggleBookmark(
      id,
      isBookmarked,
      `/shopping-sites/${id}/bookmark`,
      "shoppingSites"
    );
  };

  onToggleBookmark = (id, isBookmarked, url, type) =>
    !isBookmarked
      ? this.props.addBookmark(id, url, this.props.shoppingSites)
      : this.props.deleteBookmark(id, url, this.props.shoppingSites);

  ShoppingSites = props => {
    return (
      <React.Fragment>
        <div className="row">
          {this.props.shoppingSites.map((item, index) => {
            return (
              <div
                className="col-6 col-md-4"
                key={"shopping-site-card-" + index}
              >
                <Link to={`/shopping-sites/${item.id}`}>
                  <div
                    className="card card--shopping-site-logo mb-3 mb-md-4"
                    style={{ backgroundImage: `url(${item.cover_image_url})` }}
                  >
                    {
                      <img
                        src={
                          item.bookmarked ? bookmarkActive : bookmarkInactive
                        }
                        className="bookmark"
                        alt=""
                        onClick={event =>
                          this.toggleBookmark(event, item.id, item.bookmarked)
                        }
                      />
                    }
                    <div className="card-body">
                      <div className="logo">
                        <img
                          src={item.web_image_url}
                          alt={item.name}
                        />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
        <div className="row">
          <div className="col-12 text-center">
            {this.props.isFetchingNextPage && <InlineLoader />}
            {!this.props.nextPage &&
              0 !== this.props.shoppingSites.length && (
                <p className="font-weight-bold">No More Sites</p>
              )}
          </div>
        </div>
      </React.Fragment>
    );
  };
}

function mapStateToProps(state) {
  return {
    isFetchingShoppingSitesCategories:
      state.shoppingSite.isFetchingShoppingSitesCategories,
    categories: state.shoppingSite.categories,
    selectedCategories: state.shoppingSite.selectedCategories,
    isFetchingShoppingSites: state.shoppingSite.isFetchingShoppingSites,
    shoppingSites: state.shoppingSite.shoppingSites,
    isFetchingNextPage: state.shoppingSite.isFetchingNextPage,
    nextPage: state.shoppingSite.nextPage,
    isFilterModelOpen: state.shoppingSite.isFilterModelOpen
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initCategory: () => {
      dispatch(fetchingShoppingSiteCategories());

      client.get("v1/shopping-sites/categories").then(response => {
        let data = response.data.data;

        dispatch(setShoppingSiteCategories(data));
      });
    },
    initShoppingSites: categories => {
      dispatch(fetchingShoppingSites());

      client
        .get("/shopping-sites", {
          params: {
            categories: categories.join(",")
          }
        })
        .then(response => {
          let data = response.data.data;

          let nextPageURL =
            null !== response.data.links.next &&
            new URL(response.data.links.next);

          let nextPage =
            null !== response.data.links.next &&
            nextPageURL.searchParams.get("page");

          dispatch(setShoppingSites(data, nextPage));
        });
    },
    onFilterClick: () => {
      dispatch(filterClicked());
    },
    onSelectCategory: id => {
      dispatch(selectCategory(id));
    },
    onUnselectCategory: id => {
      dispatch(unselectCategory(id));
    },
    resetFilter: () => {
      dispatch(resetFilter());
    },
    onPaginatedSearch: (categories, nextPage) => {
      dispatch(fetchingNextPage());

      client
        .get("/shopping-sites", {
          params: {
            categories: categories.join(","),
            page: nextPage
          }
        })
        .then(response => {
          let data = response.data.data;

          let nextPageURL =
            null !== response.data.links.next &&
            new URL(response.data.links.next);

          let nextPage =
            null !== response.data.links.next &&
            nextPageURL.searchParams.get("page");

          dispatch(addShoppingSites(data, nextPage));
        });
    },
    addBookmark: (id, url, ShoppingSitesList) => {
      clientX
        .post(url, {})
        .then(response => {
          dispatch(setShoppingListAfterBookmarkToggle(id, ShoppingSitesList));
        })
        .catch(error => {});
    },

    deleteBookmark: (id, url, ShoppingSitesList) => {
      clientX
        .delete(url)
        .then(response => {
          dispatch(setShoppingListAfterBookmarkToggle(id, ShoppingSitesList));
        })
        .catch(error => {});
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingSitesList);
