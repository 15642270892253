import React, { Component } from "react";
import BannerCarousel from "../components/BannerCarousel";
import ShoppingSitesCarousel from "../components/ShoppingSitesCarousel";
import NearbyPartnerCarousel from "../components/NearbyPartnerCarousel";
import VoucherCarousel from "../components/VoucherCarousel";
import BillPayCarousel from "../components/BillPayCarousel";
import OffersCarousel from "../components/OffersCarousel";
import client from "../../../utils/client";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setHome, setHomeAfterBookmarkToggle, setLoading } from "../actions";
import { FormattedMessage } from "react-intl";
import referralImg from "../../../assets/refer-illustration-inner.svg";
import rewardsImg from "../../../assets/rewards-illustration.svg";
import AllBrands from "../components/AllBrands";
import lodash from "lodash";
import aboutImage from "../../../assets/about-section-image.jpg";

class Home extends Component {
  componentDidMount() {
    this.props.onInit(this.props.latitude, this.props.longitude);
  }

  componentDidUpdate(prevProps) {
    if (this.props.latitude !== prevProps.latitude) {
      this.props.onInit(this.props.latitude, this.props.longitude);
    }
  }

  render() {
    return this.props.isLoading ? <this.Shimmer /> : <this.Home />;
  }

  Shimmer = props => {
    return (
      <React.Fragment>
        <div className="shine slider-shimmer"></div>
        {lodash.range(4).map(index => {
          return (
            <div
              className="container-fluid container-fluid--home-section"
              key={index}
            >
              <div className="row row--home-section-title">
                <div className="col-md-12">
                  <div className="heading shine"></div>
                </div>
              </div>
              <div className="row">
                <div className="col-4 col-md-3">
                  <div className="box shine"></div>
                </div>
                <div className="col-4 col-md-3">
                  <div className="box shine"></div>
                </div>
                <div className="col-4 col-md-3">
                  <div className="box shine"></div>
                </div>
                <div className="col-4 col-md-3 d-none d-md-block">
                  <div className="box shine"></div>
                </div>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  Home = props => {
    return (
      <React.Fragment>
        <BannerCarousel banners={this.props.banner} />

        <this.ShoppingSites />

        <this.ShopNow />

        <this.NearbyPartners />

        <this.Vouchers />

        <this.BillPayments />

        <this.ReferNow />

        <this.Offers />

        <AllBrands tags={this.props.tags} />
        <this.AboutUs />
      </React.Fragment>
    );
  };

  onToggleBookmark = (id, isBookmarked, url, type) =>
    !isBookmarked
      ? this.props.addBookmark(
          id,
          type,
          url,
          this.props.banner,
          this.props.affiliate,
          this.props.partner,
          this.props.voucher,
          this.props.billpay,
          this.props.offers,
          this.props.tags
        )
      : this.props.deleteBookmark(
          id,
          type,
          url,
          this.props.banner,
          this.props.affiliate,
          this.props.partner,
          this.props.voucher,
          this.props.billpay,
          this.props.offers,
          this.props.tags
        );

  ShoppingSites = props => {
    return (
      <div className="container-fluid container-fluid--home-section">
        <div className="row row--home-section-title align-items-center">
          <div className="col-8">
            <h2 className="font-proxima-nova-bold">
              <FormattedMessage
                id="home.shopping-sites"
                defaultMessage="Shopping Sites"
                description="Shopping Sites"
                values={{ what: "react-intl" }}
              />
            </h2>
          </div>
          <div className="col-4 text-right">
            <span
              className="font-proxima-nova-bold more"
              style={{ color: "#0078D7", fontWeight: 600 }}
            >
              <Link to="/shopping-sites">
                <FormattedMessage
                  id="home.more"
                  defaultMessage="More"
                  description="More"
                  values={{ what: "react-intl" }}
                />
              </Link>
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {this.props.affiliate.length > 0 && (
              <ShoppingSitesCarousel
                affiliate={this.props.affiliate}
                onToggleBookmarkShoppingSites={(
                  id,
                  isBookmarked,
                  url,
                  type
                ) => {
                  this.onToggleBookmark(id, isBookmarked, url, type);
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  NearbyPartners = props => {
    return (
      <div
        className="container-fluid container-fluid--home-section"
        style={{ backgroundColor: "#F6F3FB" }}
      >
        <div className="row row--home-section-title align-items-center">
          <div className="col-9">
            <h2 className="font-proxima-nova-bold">
              <FormattedMessage
                id="home.nearby-partners"
                defaultMessage="Nearby Partners"
                description="Nearby Partners"
                values={{ what: "react-intl" }}
              />
            </h2>
          </div>
          <div className="col-3 text-right">
            <span
              className="font-proxima-nova-bold more"
              style={{ color: "#0078D7", fontWeight: 600 }}
            >
              <Link to="/nearby-partners/categories">
                <FormattedMessage
                  id="home.more"
                  defaultMessage="More"
                  description="More"
                  values={{ what: "react-intl" }}
                />
              </Link>
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {this.props.partner.length > 0 && (
              <NearbyPartnerCarousel partners={this.props.partner} />
            )}
          </div>
        </div>
      </div>
    );
  };

  Vouchers = props => {
    return (
      <div className="container-fluid container-fluid--home-section">
        <div className="row row--home-section-title align-items-center">
          <div className="col-8">
            <h2 className="font-proxima-nova-bold">
              <FormattedMessage
                id="home.vouchers"
                defaultMessage="Vouchers"
                description="Vouchers"
                values={{ what: "react-intl" }}
              />
            </h2>
          </div>
          <div className="col-4 text-right">
            <span
              className="font-proxima-nova-bold more"
              style={{ color: "#0078D7", fontWeight: 600 }}
            >
              <Link to="/vouchers">
                <FormattedMessage
                  id="home.more"
                  defaultMessage="More"
                  description="More"
                  values={{ what: "react-intl" }}
                />
              </Link>
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {this.props.voucher.length > 0 && (
              <VoucherCarousel
                vouchers={this.props.voucher}
                onToggleBookmarkVouchers={(id, isBookmarked, url, type) => {
                  this.onToggleBookmark(id, isBookmarked, url, type);
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  AboutUs = props => {
    return (
      <div
        className="container-fluid container--refferal about-bg"
        style={{ backgroundImage: `url(${aboutImage})` }}
      >
        <div className="about-overlay"></div>
        <div className="row band-copy font-proxima-nova">
          <div className="col-12 col-md-6 text-white">
            <h2 className="font-proxima-nova-bold">
              <FormattedMessage id="home.about" defaultMessage="About Us" />
            </h2>
            <p>
              <FormattedMessage
                id="home.about-description"
                defaultMessage="WINDS will reward its consumers for regular shopping done through the WINDS App. Get ready to stand a chance for winning in the monthly draw, just by using the WINDS App for your everyday shopping."
                values={{ what: "react-intl" }}
              />
            </p>
            <a href="https://www.windseworld.com"  target="_blank" rel="noopener noreferrer">
              <button
                className="btn btn-primary home-btn"
                style={{ backgroundColor: "#203BCF" }}
              >
                <FormattedMessage
                  id="home.know-more"
                  defaultMessage="KNOW MORE"
                />
              </button>
            </a>
          </div>
        </div>
      </div>
    );
  };

  ShopNow = props => {
    return (
      <div
        className="container-fluid container--refferal"
        style={{ backgroundImage: `url(${rewardsImg})` }}
      >
        <div className="row band-copy font-proxima-nova">
          <div className="col-12 col-md-6 text-white">
            <h2 className="font-proxima-nova-bold">
              <FormattedMessage
                id="home.shop-now"
                defaultMessage="Shop Now &amp; Get Rewarded by WINDS!"
              />
            </h2>
            <p>
              <FormattedMessage
                id="home.shop-now_description"
                defaultMessage="Shopping was never so much fun or rewarding. Now for the first time ever, get more than you shop. Want to find out How?"
                description="Refer your friend and get 100 Winds Reward Points."
                values={{ what: "react-intl" }}
              />
            </p>
            <Link to="/wrp-details">
              <button
                className="btn btn-primary home-btn"
                style={{ backgroundColor: "#203BCF" }}
              >
                <FormattedMessage
                  id="home.know-more"
                  defaultMessage="KNOW MORE"
                />
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  };

  ReferNow = props => {
    return (
      <div
        className="container-fluid container--refferal"
        style={{ backgroundImage: `url(${referralImg})` }}
      >
        <div className="row band-copy font-proxima-nova">
          <div className="col-12 col-md-6 text-white">
            <h2 className="font-proxima-nova-bold">
              <FormattedMessage
                id="home.we-value-your-friendship"
                defaultMessage="We value your friendship!"
              />
            </h2>
            <p>
              <FormattedMessage
                id="home.refer-description"
                defaultMessage="Friendship grows stronger with Time and WINDS. Invite your friends, family &amp; loved ones and we will reward you both!"
                values={{ what: "react-intl" }}
              />
            </p>
            <Link to="/refer-and-earn">
              <button
                className="btn btn-primary home-btn"
                style={{ backgroundColor: "#203BCF" }}
              >
                <FormattedMessage
                  id="general.refer-now"
                  defaultMessage="REFER NOW"
                />
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  };

  BillPayments = props => {
    return (
      <div
        className="container-fluid container-fluid--home-section"
        style={{ backgroundColor: "#F2F6FA" }}
      >
        <div className="row row--home-section-title">
          <div className="col-12">
            <h2 className="font-proxima-nova-bold mb-4">
              <FormattedMessage
                id="home.bill-pay-utility"
                defaultMessage="Bill Pay Utility"
                description="Bill Pay Utility"
                values={{ what: "react-intl" }}
              />
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="bill-pay-icon-container">
            {this.props.billpay.length > 0 && (
              <BillPayCarousel services={this.props.billpay} />
            )}
          </div>
        </div>
      </div>
    );
  };

  Offers = props => {
    return (
      this.props.offers.length > 0 && (
        <div className="container-fluid container-fluid--home-section">
          <div className="row row--home-section-title align-items-center">
            <div className="col-9">
              <h2 className="font-proxima-nova-bold">
                <FormattedMessage
                  id="home.offers-near-you"
                  defaultMessage="Offers Near You"
                  description="Offers Near You"
                  values={{ what: "react-intl" }}
                />
              </h2>
            </div>
            <div className="col-3 text-right">
              <span
                className="font-proxima-nova-bold more"
                style={{ color: "#0078D7", fontWeight: 600 }}
              >
                <Link to="/nearby-offers">
                  <FormattedMessage
                    id="home.more"
                    defaultMessage="More"
                    description="More"
                    values={{ what: "react-intl" }}
                  />
                </Link>
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <OffersCarousel
                offers={this.props.offers}
                onToggleBookmarkOffer={(id, isBookmarked, url, type) => {
                  this.onToggleBookmark(id, isBookmarked, url, type);
                }}
              />
            </div>
          </div>
        </div>
      )
    );
  };
}

function mapStateToProps(state) {
  return {
    latitude: state.location.latitude,
    longitude: state.location.longitude,
    banner: state.home.banner,
    affiliate: state.home.affiliate,
    partner: state.home.partner,
    voucher: state.home.voucher,
    billpay: state.home.billpay,
    offers: state.home.offers,
    tags: state.home.tags,
    isLoading: state.home.isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onInit: (latitude, longitude) => {
      dispatch(setLoading(true));

      client
        .get("/?latitude=" + latitude + "&longitude=" + longitude + "&radius=5")
        .then(response => {
          var data = response.data.data;

          dispatch(
            setHome(
              data.banner,
              data.affiliate,
              data.partner.categories,
              data.voucher,
              data.billpay.services,
              data.partner.offers,
              data.tags
            )
          );

          dispatch(setLoading(false));
        });
    },
    addBookmark: (
      id,
      listTypeToUpdate,
      url,
      banner,
      affiliate,
      partner,
      voucher,
      billpay,
      offers,
      tags
    ) => {
      client
        .post(url, {})
        .then(response => {
          dispatch(
            setHomeAfterBookmarkToggle(
              id,
              listTypeToUpdate,
              banner,
              affiliate,
              partner,
              voucher,
              billpay,
              offers,
              tags
            )
          );
        })
        .catch(error => {});
    },

    deleteBookmark: (
      id,
      listTypeToUpdate,
      url,
      banner,
      affiliate,
      partner,
      voucher,
      billpay,
      offers,
      tags
    ) => {
      client
        .delete(url)
        .then(response => {
          dispatch(
            setHomeAfterBookmarkToggle(
              id,
              listTypeToUpdate,
              banner,
              affiliate,
              partner,
              voucher,
              billpay,
              offers,
              tags
            )
          );
        })
        .catch(error => {});
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
