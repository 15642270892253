import React, { Component } from "react";
import client from "../../../utils/client";
import { Link, withRouter } from "react-router-dom";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import perforatedBorderBlue from "../../../assets/perforated-border-blue.svg";
import wrpIcon from "../../../assets/wrp-icon-gold.svg";
import lightningIcon from "../../../assets/lightning-icon.svg";
import deliverWithDelayIcon from "../../../assets/delivery-van-icon.svg";
import { connect } from "react-redux";
import lodash from "lodash";
import { setVoucher, setDenomination, setQuantity } from "../actions";
import ContentLoader from "react-content-loader";
import { FormattedMessage, injectIntl } from "react-intl";
import bookmarkActive from "../../../assets/bookmark-active.svg";
import bookmarkInactive from "../../../assets/bookmark-inactive.svg";
import aboutBlue from "../../../assets/about-blue.svg";
import WrpBenefitModel from "../../../components/WrpBenefitModel";
import ReferNowSection from "../../../components/ReferNowSection";
import clientX from "../../../utils/clientX";
import classNames from "classnames";

class VoucherDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      voucher: null,
      amount: 0,
      isLoading: true,
      isWrpBenefitModelOpen: false
    };
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;

    this.setState(
      {
        isLoading: true
      },
      () => {
        client.get(`v1/vouchers/${params.id}`).then(response => {
          let data = response.data.data;

          this.setState({
            voucher: data,
            key: "details",
            isLoading: false
          });

          this.props.setVoucher(data);
        });
      }
    );
  }

  openWrpBenefitModel = () => {
    this.setState({ isWrpBenefitModelOpen: true });
  };

  closeWrpModelHandler = () => {
    this.setState({ isWrpBenefitModelOpen: false });
  };

  toggleBookmark = (event, id, isBookmarked) => {
    event.preventDefault();
    event.stopPropagation();
    this.onToggleBookmark(id, isBookmarked, `/vouchers/${id}/bookmark`);
  };

  onToggleBookmark = (id, isBookmarked, url) =>
    !isBookmarked
      ? this.addBookmark(id, url, this.props.vouchers)
      : this.deleteBookmark(id, url, this.props.vouchers);

  addBookmark = (id, url, vouchers) => {
    clientX
      .post(url, {})
      .then(response => {
        this.setState(prevState => ({
          voucher: {
            ...prevState.voucher,
            bookmarked: !prevState.voucher.bookmarked
          }
        }));
      })
      .catch(error => {});
  };

  deleteBookmark = (id, url, vouchers) => {
    clientX
      .delete(url)
      .then(response => {
        this.setState(prevState => ({
          voucher: {
            ...prevState.voucher,
            bookmarked: !prevState.voucher.bookmarked
          }
        }));
      })
      .catch(error => {});
  };

  onSelectDenomination = event => {
    let denomination = this.state.voucher.denominations.filter(
      (item, index) => {
        return parseInt(item.id) === parseInt(event.target.value);
      }
    );
    this.setState({ amount: denomination.value });

    this.props.setDenomination(denomination[0]);
  };

  onEnterAmount = event => {
    this.setState({ amount: event.target.value });

    this.props.setDenomination({
      id: this.state.voucher.denominations[0].id,
      value: event.target.value
    });
  };

  onIncreaseQuantity = () => {
    if (this.props.quantity < 11) {
      this.props.setQuantity(this.props.quantity + 1);
    }
  };

  onDecreaseQuantity = () => {
    if (this.props.quantity > 0) {
      this.props.setQuantity(this.props.quantity - 1);
    }
  };

  onProceed = () => {
    if ("range" === this.state.voucher.voucher_type) {
      if (
        this.state.amount >
          parseInt(this.state.voucher.denominations[0].max_value) ||
        this.state.amount <
          parseInt(this.state.voucher.denominations[0].min_value)
      ) {
        toast.error(
          `Pick an amount between ${this.state.voucher.denominations[0].min_value} and ${this.state.voucher.denominations[0].max_value}`,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          }
        );

        return;
      }
    }

    this.props.history.push("/vouchers/cart");
  };

  render() {
    return this.state.isLoading ? <this.Shimmer /> : <this.Voucher />;
  }

  Shimmer = props => {
    return (
      <div className="container-fluid py-3 py-md-5">
        <div className="row">
          <div className="col-md-6">
            <div className="box shine box-voucher-detail-logo"></div>
          </div>
          <div className="col-md-6 pt-4 pt-md-0">
            <ContentLoader
              height={80}
              width={400}
              speed={2}
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            >
              <rect x="0" y="0" rx="2" ry="2" width="99" height="6" />
              <rect x="0" y="16" rx="2" ry="2" width="300" height="24" />
            </ContentLoader>
            <div className="box shine w-100" style={{ height: "38px" }}></div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <ContentLoader
              height={160}
              width={400}
              speed={2}
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            >
              <rect x="0" y="9" rx="0" ry="0" width="120" height="6" />
              <rect x="0" y="21" rx="0" ry="0" width="320" height="12" />
              <rect x="0" y="39" rx="0" ry="0" width="92" height="6" />
              <rect x="0" y="60" rx="0" ry="0" width="506" height="6" />
              <rect x="0" y="75" rx="0" ry="0" width="136" height="6" />
              <rect x="0" y="90" rx="0" ry="0" width="153" height="6" />
            </ContentLoader>
          </div>
          <div className="col-md-6"></div>
        </div>
      </div>
    );
  };

  Voucher = props => {
    return (
      <div className="container-fluid font-proxima-nova">
        <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
          <div className="col-12">
            <div className="row align-items-center">
              <div className="col-12">
                <small>
                  <Link to="/">
                    <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    />
                  </Link>
                </small>{" "}
                /{" "}
                <small>
                  <Link to="/vouchers">
                    <FormattedMessage
                      id="home.vouchers"
                      defaultMessage="Vouchers"
                    />
                  </Link>
                </small>{" "}
                /{" "}
                <small>
                  <strong>{this.state.voucher.name}</strong>
                </small>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-md-6">
                <this.VoucherImage />
              </div>
              <div className="col-md-6">
                <this.Controls />
                <ToastContainer
                  position="top-center"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnVisibilityChange
                  draggable
                  pauseOnHover
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2 mt-md-5">
          <div className="col-12">
            <hr />
          </div>
        </div>
        <div className="row mb-md-4 mb-5">
          <div className="col-12 col-md-7 mt-2 mt-md-5">
            <this.Details />
          </div>
          <div className="col-md-5 d-none d-md-block mt-5">
            <ReferNowSection />
          </div>
        </div>
      </div>
    );
  };

  VoucherImage = props => {
    return (
      <div
        className="card"
        style={{
          boxShadow: "0 4px 30px 0 rgba(0,0,0,0.08)",
          borderRadius: "8px",
          border: "none"
        }}
      >
        <div
          className="card-body"
          style={{ backgroundColor: "#EFF6FF", borderRadius: "8px 8px 0 0" }}
        >
          <div className="row align-items-center">
            {
              <img
                src={
                  this.state.voucher.bookmarked
                    ? bookmarkActive
                    : bookmarkInactive
                }
                className="bookmark"
                alt=""
                onClick={event =>
                  this.toggleBookmark(
                    event,
                    this.state.voucher.id,
                    this.state.voucher.bookmarked
                  )
                }
              />
            }
            <div className="col-4 col-md-3">
              <div
                className="voucher-detail-logo-wrapper"
                style={{
                  backgroundImage: `url(${this.state.voucher.image_url})`
                }}
              ></div>
            </div>
            <div className="col-8 col-md-9">
              <div className="row">
                <div className="col-12">
                  <span className="voucher-deliveryType">
                    {this.state.voucher.delivery_type}
                  </span>
                  <h4 className="voucher-title">
                    <strong>{this.state.voucher.name}</strong>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="card-footer pt-4 pb-4"
          style={{
            background: "#fff",
            border: "none",
            borderRadius: "0 0 8px 8px",
            backgroundImage: `url(${perforatedBorderBlue})`,
            backgroundRepeat: "repeat-x",
            backgroundPositionX: "-2px"
          }}
        >
          <div className="row font-proxima-nova align-items-center">
            <div className="col-7">
              {0 === parseInt(this.state.voucher.turn_around_time_in_hours) ? (
                <p className="mb-0 voucher-delivery-detail">
                  <img src={lightningIcon} alt="" />
                  Instant Delivery
                </p>
              ) : (
                <p className="mb-0 voucher-delivery-detail">
                  <img src={deliverWithDelayIcon} alt="" />
                  <FormattedMessage
                    id="voucher.delivered-in"
                    defaultMessage="Delivered in"
                  />{" "}
                  {this.state.voucher.turn_around_time_in_hours}{" "}
                  {this.state.voucher.turn_around_time_in_hours > 1
                    ? "Hours"
                    : "Hour"}
                </p>
              )}
            </div>
            <div className="col-5 text-right">
              <p className="mb-0">
                {lodash
                  .range(this.state.voucher.winds_rating)
                  .map((item, index) => {
                    return (
                      <img
                        className="star"
                        src={wrpIcon}
                        key={"voucher-rating-" + index}
                        alt="star"
                      />
                    );
                  })}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  Controls = props => {
    const { wrp_ratio } = this.state.voucher;
    const { isWrpBenefitModelOpen } = this.state;
    return (
      <React.Fragment>
        <div className="row align-items-center mt-5 mt-md-0">
          <div className="col-8 col-md-6">
            <div className="form-group">
              <label htmlFor="denomination">
                <FormattedMessage id="general.amount" defaultMessage="Amount" />
              </label>
              {"fixed" === this.state.voucher.voucher_type ? (
                <select
                  className="form-control"
                  id="denomination"
                  onChange={this.onSelectDenomination}
                >
                  <option value="-1">
                    {this.props.intl.formatMessage({
                      id: "vouchers.choose-denomination"
                    })}
                  </option>
                  {this.state.voucher.denominations.map((item, index) => {
                    return (
                      <option
                        key={"voucher-denomination-" + index}
                        value={item.id}
                      >
                        {item.value}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <React.Fragment>
                  <input
                    type="number"
                    className="form-control"
                    placeholder={this.props.intl.formatMessage({
                      id: "vouchers.enter-amount"
                    })}
                    onChange={this.onEnterAmount}
                  />
                  <small className="form-text text-muted">{`Pick an amount between ${this.state.voucher.denominations[0].min_value} and ${this.state.voucher.denominations[0].max_value}`}</small>
                </React.Fragment>
              )}
            </div>
          </div>
          <div className="col-4 col-md-6 text-right">
            <div
              className={classNames(
                "align-middle",
                { "mb-3": "range" === this.state.voucher.voucher_type },
                { "mt-3": "fixed" === this.state.voucher.voucher_type }
              )}
            >
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm"
                style={{
                  height: "25px",
                  width: "25px",
                  lineHeight: "16px",
                  borderRadius: "50%"
                }}
                onClick={this.onDecreaseQuantity}
              >
                -
              </button>
              <span className="mr-2 ml-2">{this.props.quantity}</span>
              <button
                className="btn btn-primary btn-sm"
                type="submit"
                style={{
                  height: "25px",
                  width: "25px",
                  lineHeight: "16px",
                  borderRadius: "50%"
                }}
                onClick={this.onIncreaseQuantity}
              >
                +
              </button>
            </div>
          </div>
        </div>

        <div className="row mt-3 align-items-center">
          <div className="col-12">
            {this.props.denomination && this.props.quantity > 0 ? (
              <button
                className="btn btn-primary btn-block mb-3"
                style={{
                  padding: ".8rem 0",
                  fontSize: ".8rem",
                  letterSpacing: ".1rem"
                }}
                onClick={this.onProceed}
              >
                <FormattedMessage
                  id="general.proceed"
                  defaultMessage="PROCEED"
                />
              </button>
            ) : (
              <button
                className="btn btn-primary btn-block mb-3"
                style={{
                  padding: ".8rem 0",
                  fontSize: ".8rem",
                  letterSpacing: ".1rem"
                }}
                disabled={true}
              >
                <FormattedMessage
                  id="general.proceed"
                  defaultMessage="PROCEED"
                />
              </button>
            )}
            <button
              className="btn btn-outline mt-1 mt-md-3"
              style={{ color: "#087CD8" }}
              onClick={this.openWrpBenefitModel}
            >
              <img src={aboutBlue} alt="" className="mr-1" />{" "}
              <FormattedMessage
                id="general.wrp_benefits"
                defaultMessage="WRP Benefits"
              />
            </button>
          </div>
          {this.state.isWrpBenefitModelOpen && (
            <WrpBenefitModel
              isWrpBenefitModelOpen={isWrpBenefitModelOpen}
              wrp_ratio={wrp_ratio}
              closeWrpModelHandler={this.closeWrpModelHandler}
            />
          )}
        </div>
      </React.Fragment>
    );
  };

  Details = props => {
    return (
      <Tabs
        className="wrp-tabs"
        id="controlled-tab-example"
        activeKey={this.state.key}
        onSelect={key => this.setState({ key })}
      >
        <Tab
          eventKey="details"
          title={this.props.intl.formatMessage({ id: "general.details" })}
        >
          <div
            style={{
              color: "#646464",
              fontSize: "16px",
              linHeight: "22px",
              paddingTop: "30px"
            }}
            dangerouslySetInnerHTML={{ __html: this.state.voucher.description }}
          />
        </Tab>
        <Tab
          eventKey="terms"
          title={this.props.intl.formatMessage({ id: "general.terms" })}
        >
          <div
            style={{
              color: "#646464 !important",
              fontSize: "16px",
              linHeight: "1.5em",
              marginTop: "2rem"
            }}
            dangerouslySetInnerHTML={{ __html: this.state.voucher.terms }}
          />
        </Tab>
      </Tabs>
    );
  };
}

function mapStateToProps(state) {
  return {
    voucher: state.voucher.voucher,
    denomination: state.voucher.denomination,
    quantity: state.voucher.quantity
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setVoucher: id => {
      dispatch(setVoucher(id));
    },
    setDenomination: denomination => {
      dispatch(setDenomination(denomination));
    },
    setQuantity: quantity => {
      dispatch(setQuantity(quantity));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withRouter(VoucherDetail)));
