import React, { Component } from "react";
import client from "../../../utils/client";
import { Link } from "react-router-dom";
import wrpIcon from "../../../assets/wrp-icon-gold.svg";
import lightningIcon from "../../../assets/lightning-icon.svg";
import deliveryWithDelayIcon from "../../../assets/delivery-van-icon.svg";
import ContentLoader from "react-content-loader";
import lodash from "lodash";
import perforatedBorder from "../../../assets/perforated-border.svg";
import { connect } from "react-redux";
import InlineLoader from "../../../components/InlineLoader";
import Modal from "react-bootstrap/Modal";
import bookmarkActive from "../../../assets/bookmark-active.svg";
import bookmarkInactive from "../../../assets/bookmark-inactive.svg";
import filterIcon from "../../../assets/filter-icon.svg";
import {
  fetchingVouchers,
  setVouchers,
  fetchingVoucherCategories,
  setVoucherCategories,
  selectCategoryForVouchers,
  unselectCategoryForVouchers,
  fetchingNextPage,
  addVouchers,
  setVoucherListAfterBookmarkToggle,
  onVouchersFilterClick,
  resetVoucherFilter
} from "../actions";
import { FormattedMessage } from "react-intl";

class VouchersList extends Component {
  componentDidMount() {
    this.props.initCategory();
    this.props.initVouchers(this.props.selectedCategories);

    window.addEventListener("scroll", this.onScroll, false);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll, false);
  }

  onScroll = () => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 500 &&
      this.props.vouchers &&
      !this.props.isFetchingNextPage &&
      this.props.nextPage
    ) {
      this.props.onPaginatedSearch(
        this.props.selectedCategories,
        this.props.nextPage
      );
    }
  };

  onChange = (event, id) => {
    if (event.target.checked) {
      this.props.onSelectCategory(id);
      return;
    }

    this.props.onUnselectCategory(id);
  };

  componentDidUpdate(prevProps) {
    if (
      !lodash.isEqual(
        this.props.selectedCategories,
        prevProps.selectedCategories
      )
    ) {
      this.props.initVouchers(this.props.selectedCategories);
    }
  }

  render() {
    return (
      <div className="container-fluid font-proxima-nova">
        <div className="row mt-3 mb-3 mt-md-4 mb-md-4 align-items-center">
          <div className="col-8 col-md-12">
            <small>
              <Link to="/">
                <FormattedMessage id="breadcrumbs.home" defaultMessage="Home" />
              </Link>{" "}
              /{" "}
              <strong>
                <FormattedMessage
                  id="home.vouchers"
                  defaultMessage="Vouchers"
                />
              </strong>
            </small>
          </div>
          <div className="col-4 text-right d-block d-md-none">
            <img src={filterIcon} alt="" className="filter-icon" />
            <button className="btn" onClick={this.props.onVouchersFilterClick}>
              <FormattedMessage id="general.filters" defaultMessage="Filters" />
            </button>

            <React.Fragment>
              <Modal
                show={this.props.isVoucherFilterModelOpen}
                onHide={this.props.onVouchersFilterClick}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    <strong>Filters</strong>
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <div className="row">
                    <div className="col-12">
                      <p className="mb-3 text-uppercase font-proxima-nova-bold">
                        <FormattedMessage
                          id="general.categories"
                          defaultMessage="CATEGORY"
                        />
                      </p>
                    </div>
                  </div>
                  <this.Filters />
                </Modal.Body>
                <Modal.Footer>
                  <div className="row w-100">
                    <div className="col-6">
                      <button
                        className="w-100 btn btn-outline-primary"
                        onClick={this.props.onVouchersFilterClick}
                      >
                        <FormattedMessage
                        id="general.apply"
                        defaultMessage="Apply"
                      />
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        className="btn w-100 btn-outline-primary"
                        onClick={() => {
                          this.props.onVouchersFilterClick();
                          this.props.resetVoucherFilter();
                        }}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </Modal.Footer>
              </Modal>
            </React.Fragment>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-3 d-none d-md-block border-right w-100">
            <this.FiltersBlock />
          </div>
          <div className="col-12 col-md-9 main-content">
            {this.props.isFetchingVouchers ? (
              <this.VoucherShimmer />
            ) : (
              <this.Vouchers />
            )}
            <div className="row">
              <div className="col-12 text-center">
                {this.props.isFetchingNextPage && <InlineLoader />}
                {!this.props.nextPage && (
                  <p className="font-weight-bold">No More Vouchers</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  FiltersBlock = props => {
    return (
      <React.Fragment>
        <div className="row mb-3 status border-bottom">
          <div className="col-6 pl-0">
            <h5 className="font-proxima-nova-bold">
              <FormattedMessage id="general.filters" defaultMessage="Filters" />
            </h5>
          </div>
          <div className="col-6 pr-0">
            <button
              className="btn btn-outline float-right"
              onClick={this.props.resetVoucherFilter}
              style={{ color: "#087CD8", marginTop: "-0.5rem" }}
            >
              <FormattedMessage id="general.reset" defaultMessage="Reset" />
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className="mb-3 text-uppercase font-proxima-nova-bold">
              <FormattedMessage
                id="general.categories"
                defaultMessage="CATEGORY"
              />
            </p>
          </div>
        </div>
        <this.Filters />
      </React.Fragment>
    );
  };

  Filters = props => {
    return (
      <React.Fragment>
        {this.props.isFetchingVoucherCategories ? (
          <div className="row">
            <div className="col-md-12">
              <ContentLoader
                height={200}
                width={200}
                speed={2}
                primaryColor="#F6F7F8"
                secondaryColor="#ecebeb"
              >
                <rect x="0" y="0" rx="2" ry="2" width="132" height="16" />
                <rect x="180" y="0" rx="2" ry="2" width="16" height="16" />
                <rect x="0" y="24" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="48" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="72" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="96" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="120" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="144" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="168" rx="2" ry="2" width="132" height="16" />
                <rect x="180" y="24" rx="2" ry="2" width="16" height="16" />
                <rect x="180" y="48" rx="2" ry="2" width="16" height="16" />
                <rect x="180" y="72" rx="2" ry="2" width="16" height="16" />
                <rect x="180" y="96" rx="2" ry="2" width="16" height="16" />
                <rect x="180" y="120" rx="2" ry="2" width="16" height="16" />
                <rect x="180" y="144" rx="2" ry="2" width="16" height="16" />
                <rect x="180" y="168" rx="2" ry="2" width="16" height="16" />
              </ContentLoader>
            </div>
          </div>
        ) : (
          <div
            style={{
              height: "450px",
              overflowY: "scroll",
              overflowX: "hidden"
            }}
          >
            {this.props.categories.map((item, index) => {
              return (
                <div className="row mt-3" key={"voucher-category-box-" + index}>
                  <div className="col-9">{item.value}</div>
                  <div className="col-3 mb-1 text-center">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={"voucher-" + item.key}
                        checked={
                          lodash.indexOf(
                            this.props.selectedCategories,
                            item.key
                          ) > -1
                        }
                        onChange={event => {
                          this.onChange(event, item.key);
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={"voucher-" + item.key}
                      ></label>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </React.Fragment>
    );
  };

  VoucherShimmer = props => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-6 col-md-4">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-6 col-md-4">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-6 col-md-4 d-none d-md-block">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 col-md-4">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-6 col-md-4">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-6 col-md-4 d-none d-md-block">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 col-md-4">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-6 col-md-4">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-6 col-md-4 d-none d-md-block">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  toggleBookmark = (event, id, isBookmarked) => {
    event.preventDefault();
    event.stopPropagation();
    this.onToggleBookmark(id, isBookmarked, `/vouchers/${id}/bookmark`);
  };

  onToggleBookmark = (id, isBookmarked, url) =>
    !isBookmarked
      ? this.props.addBookmark(id, url, this.props.vouchers)
      : this.props.deleteBookmark(id, url, this.props.vouchers);

  Vouchers = props => {
    return (
      <div className="row">
        {this.props.vouchers.map((item, index) => {
          return (
            <div className="col-6 col-md-4" key={"voucher-card-" + index}>
              <Link to={`/voucher/${item.id}`}>
                <div className="card mb-4 card--carousel-item card--carousel-item--vouchers">
                  {
                    <img
                      src={item.bookmarked ? bookmarkActive : bookmarkInactive}
                      className="bookmark"
                      alt=""
                      onClick={event =>
                        this.toggleBookmark(event, item.id, item.bookmarked)
                      }
                    />
                  }

                  <div className="card-body">
                    <img src={item.web_image_url} alt={item.name} />
                    <span className="delivery-type-label">
                      {item.delivery_type}
                    </span>
                    {0 === parseInt(item.turn_around_time_in_hours) ? (
                      <span className="delivery-detail d-none d-md-block">
                        <img src={lightningIcon} alt="" />
                        Instant Delivery
                      </span>
                    ) : (
                      <span className="delivery-detail d-none d-md-block">
                        <img src={deliveryWithDelayIcon} alt="" />
                        <FormattedMessage
                          id="voucher.delivered-in"
                          defaultMessage="Delivered in"
                        />{" "}
                        {item.turn_around_time_in_hours}{" "}
                        {item.turn_around_time_in_hours > 1 ? "Hours" : "Hour"}
                      </span>
                    )}
                  </div>
                  <div
                    className="card-footer"
                    style={{
                      backgroundImage: `url(${perforatedBorder})`,
                      backgroundRepeat: "repeat-x",
                      backgroundPositionX: "-2px"
                    }}
                  >
                    <div className="row align-items-center">
                      <div className="col-7">
                        <p className="brand-name-label mb-0">{item.name}</p>
                        <p className="mb-0">
                          {lodash
                            .range(item.winds_rating)
                            .map((item, index) => {
                              return (
                                <img
                                  className="star"
                                  src={wrpIcon}
                                  alt="star"
                                  key={"voucher-rating-" + index}
                                />
                              );
                            })}
                        </p>
                      </div>

                      <div className="col-5 font-proxima-nova text-right">
                        <button
                          className="btn btn-primary"
                          style={{
                            backgroundColor: "#203BCF",
                            padding: "0.3rem 1.2rem",
                            fontSize: ".8rem"
                          }}
                        >
                          <FormattedMessage
                            id="buy-now"
                            defaultMessage="BUY NOW"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    );
  };
}

function mapStateToProps(state) {
  return {
    isFetchingVoucherCategories: state.voucher.isFetchingVoucherCategories,
    categories: state.voucher.categories,
    selectedCategories: state.voucher.selectedCategories,
    isFetchingVouchers: state.voucher.isFetchingVouchers,
    vouchers: state.voucher.vouchers,
    isFetchingNextPage: state.voucher.isFetchingNextPage,
    nextPage: state.voucher.nextPage,
    isVoucherFilterModelOpen: state.voucher.isVoucherFilterModelOpen
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initCategory: () => {
      dispatch(fetchingVoucherCategories());

      client.get("v1/vouchers/filters").then(response => {
        let data = response.data.data.segment;

        dispatch(setVoucherCategories(data));
      });
    },
    initVouchers: categories => {
      dispatch(fetchingVouchers());

      client
        .get("/vouchers", {
          params: {
            segment: categories.join(",")
          }
        })
        .then(response => {
          let data = response.data.data;

          let nextPageURL =
            null !== response.data.links.next &&
            new URL(response.data.links.next);

          let nextPage =
            null !== response.data.links.next &&
            nextPageURL.searchParams.get("page");

          dispatch(setVouchers(data, nextPage));
        });
    },
    onSelectCategory: id => {
      dispatch(selectCategoryForVouchers(id));
    },
    onUnselectCategory: id => {
      dispatch(unselectCategoryForVouchers(id));
    },
    onVouchersFilterClick: () => {
      dispatch(onVouchersFilterClick());
    },
    resetVoucherFilter: () => {
      dispatch(resetVoucherFilter());
    },
    onPaginatedSearch: (categories, nextPage) => {
      dispatch(fetchingNextPage());

      client
        .get("/vouchers", {
          params: {
            segment: categories.join(","),
            page: nextPage
          }
        })
        .then(response => {
          let data = response.data.data;

          let nextPageURL =
            null !== response.data.links.next &&
            new URL(response.data.links.next);

          let nextPage =
            null !== response.data.links.next &&
            nextPageURL.searchParams.get("page");

          dispatch(addVouchers(data, nextPage));
        });
    },
    addBookmark: (id, url, vouchers) => {
      client
        .post(url, {})
        .then(response => {
          dispatch(setVoucherListAfterBookmarkToggle(id, vouchers));
        })
        .catch(error => {});
    },

    deleteBookmark: (id, url, vouchers) => {
      client
        .delete(url)
        .then(response => {
          dispatch(setVoucherListAfterBookmarkToggle(id, vouchers));
        })
        .catch(error => {});
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VouchersList);
