import React, { Component } from "react";
import { Link } from "react-router-dom";
import clientX from "../../../utils/clientX";
import SideBar from "../components/SideBar";
import Profile from "../components/Profile";
import { FormattedMessage } from "react-intl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import update from "immutability-helper";
import mobileIcon from "../../../assets/mobile-icon-grey.svg";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton'

export default class Devices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      devices: []
    };
  }

  componentDidMount() {
    this.getDevices();
  }

  getDevices() {
    clientX.get("v1/devices").then(response => {
      this.setState({
        devices: response.data.data
      });
    });
  }

  updateDeviceList = currentIndex => {
    const deviceList = this.state.devices;
    deviceList.splice(currentIndex, 1);
    let newState = update(this.state, {
      devices: { $set: deviceList }
    });
    this.setState(newState);
  };

  logoutSelectedSession = (sessionId, currentIndex) => {
    clientX
      .post(`/logout/selected-session/${sessionId}`, {})
      .then(response => {
        this.state.devices.length === 1
          ? (window.location.href = `${process.env.REACT_APP_SSO_URL}/login`)
          : this.updateDeviceList(currentIndex);
      })
      .catch(error => {
        toast.error(error.response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      });
  };

  logoutAllSession = () => {
    clientX
      .post(`/logout/all`, {})
      .then(response => {
        window.location.href = `${process.env.REACT_APP_SSO_URL}/login`;
      })
      .catch(error => {
        toast.error(error.response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      });
  };

  logoutExceptSelf = () => {
    clientX
      .post(`/logout/all-except-self`, {})
      .then(response => {
        this.getDevices();
      })
      .catch(error => {
        toast.error(error.response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      });
  };

  logoutBlock = props => {
    return (
        <DropdownButton
          alignRight
          title="Logout Devices"
        >
          <Dropdown.Item as="button" onClick={this.logoutAllSession}>
            <FormattedMessage
                  id="profile.log-out-all"
                  defaultMessage="Logout"
                  description="Logout"
                  values={{ what: "react-intl" }}
                />
          </Dropdown.Item>
          <Dropdown.Item as="button" onClick={this.logoutExceptSelf}>
          Logout All Except Self
          </Dropdown.Item>
        </DropdownButton>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid pt-3 pb-3 pt-md-4 pb-md-4 breadCrumbs">
          <div className="row mb-md-3">
            <div className="col-12 m-auto font-proxima-nova">
              <small>
                <Link to="/">
                  <FormattedMessage
                    id="breadcrumbs.home"
                    defaultMessage="Home"
                  />
                </Link>
              </small>{" "}
              /{" "}
              <small>
                <Link to="/profile">
                  <FormattedMessage
                    id="profile.profile"
                    defaultMessage="Profile"
                  />
                </Link>
              </small>{" "}
              /{" "}
              <small>
                <strong>
                  <FormattedMessage
                    id="profile.logged-in-devices"
                    defaultMessage="Logged in Devices"
                  />
                </strong>
              </small>
            </div>
          </div>

          <div className="d-none d-md-block">
            <Profile />
          </div>
        </div>

        <div className="container-fluid mt-md-5 mb-3 mb-md-5">
          <div className="row mb-3 mt-md-4 mb-md-4">
            <div className="d-none d-md-block col-md-2 font-proxima-nova border-right">
              <SideBar />
            </div>
            <div className="col-12 col-md-9">
              <div className="row d-none d-md-flex">
                <div className="col-8 font-proxima-nova-bold ml-5">
                  <h5>
                    <strong>
                      <FormattedMessage
                        id="profile.logged-in-devices"
                        defaultMessage="Logged in Devices"
                      />
                    </strong>
                  </h5>
                </div>
                <div className="col-2 text-right">
                  {this.state.devices.length > 0 && <this.logoutBlock />}
                </div>
              </div>
              <div className="row">
                <div className="col-md-10 col-12 marginDevice">
                  {this.state.devices.map((item, index) => {
                    return (
                      <div className="card mb-3 mt-2 mt-md-4 bg-light font-proxima-nova rounded-border border-0" key={index}>
                        <div className="card-body d-flex">
                          <div>
                            <img src={mobileIcon} alt="" />
                          </div>
                          <div className="ml-3 d-flex flex-column w-100">
                            <p className="mb-0">
                              <strong>Last active</strong>&nbsp;
                              {item.last_active}
                            </p>
                            <div className="d-flex flex-column justify-content-between">
                              <p className="mb-0">
                                {item.location}, {item.ip_address}
                              </p>
                              <p className="mb-0">{item.device}</p>
                            </div>
                          </div>
                          <div
                            className="float-right"
                            style={{ width: "18%", textAlign: "right", cursor: "pointer", color: "#087CD8" }}
                            onClick={() => {
                              this.logoutSelectedSession(item.id, index);
                            }}
                          >
                            <FormattedMessage
                              id="general.remove"
                              defaultMessage="Remove"
                              description="Remove"
                              values={{ what: "react-intl" }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </React.Fragment>
    );
  }
}
