import { Link } from "react-router-dom";
import React from "react";
import ReferralIcon from "../assets/refer-illustration-inner.svg";
import { FormattedMessage } from "react-intl";
const ReferNowSection = props => (
  <div className="referral-block winds-blue-bg text-white">
    <h3 className="font-proxima-nova-bold">
      <FormattedMessage
        id="home.we-value-your-friendship"
        defaultMessage="We value your friendship!"
      />
    </h3>
    <p>
      <FormattedMessage
        id="general.text_reward_point"
        defaultMessage="Refer your friend and get 100 WINDS Reward Points."
      />
    </p>

    <Link to="/refer-and-earn">
      <p
        style={{ cursor: "pointer" }}
        className="winds-accent-color text-uppercase "
      >
        <FormattedMessage id="general.refer-now" defaultMessage="REFER NOW" />
      </p>
    </Link>

    <img
      src={ReferralIcon}
      alt="referral illustration"
      className="referral-image"
    />
  </div>
);
export default ReferNowSection;
